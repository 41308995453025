//  right  Social New/Engaged Lead Marking , when Lead access Social Post short chain containing ， mark Tag for Social Studio。
import axios from 'axios';
import util from '@/common/util';
export const loginOrRegister = {
    login: 1,
    register: 0
};
export function setLeadTag(tagType, leadUserId, teamId) {
    const utm_source = util.getCookie('utm_source'),
        cpclid = util.getCookie('cpclid');
    if (!utm_source || !cpclid) return;
    let params = {
        leadUserId: leadUserId,
        teamId: teamId,
        cpclid: cpclid,
        tagType: tagType,
        source: utm_source
    };
    const adsSource = util.getQueryField('x_ads_source');
    if (adsSource) {
        params.adsSource = adsSource;
    }
    axios.post('/social-media/statistics/lead/leadPickTag', params);
}
