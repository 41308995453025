import Queue from './queue';

export default {
    /**
     *  bind event 
     * @param {HtmlElement} elem  The element being monitored 
     * @param {String} event  listened event 
     * @param {Object} handler option 
     * eg { args: option,
        handler: onKeyup}
     */
    on(elem, event, handler) {
        let map = getEventMap(elem),
            eventInfo = map[event];
        if (eventInfo) {
            eventInfo.queue.add(handler);
        } else {
            addEvent(elem, event, handler, map);
        }
    },
    off(elem, event, handler) {
        let map = getEventMap(elem),
            eventInfo = map[event];
        if (eventInfo) {
            if (handler) {
                eventInfo.queue.remove(handler);
            } else {
                eventInfo.queue.clear();
            }
        }
    }
};

const EventMap = [];
let EVENT_HANDLER_ADD = 'addEventListener',
    EVENT_HANDLER_REMOVE = 'removeEventListener',
    EVENT_PREFIX = '';
// if (!document.addEventListener) {
//     EVENT_HANDLER_ADD = 'attachEvent';
//     EVENT_HANDLER_REMOVE = 'detachEvent';
//     EVENT_PREFIX = 'on';
// }

export { EVENT_HANDLER_ADD, EVENT_HANDLER_REMOVE };

function getEventMap(elem) {
    for (let i = 0, len = EventMap.length; i < len; i++) {
        let map = EventMap[i];
        if (map.elem === elem) return map;
    }
    let map = { elem };
    EventMap.push(map);
    return map;
}

function addEvent(elem, event, fn, map) {
    let queue,
        handler = evt => {
            queue.fire.call(elem, formatEvent(evt || window.event));
        };
    if (fn.fire) {
        queue = fn;
    } else {
        queue = Queue({
            afterClear() {
                removeEvent(elem, event, handler);
            }
        });
        queue.add(fn);
    }
    elem[EVENT_HANDLER_ADD](EVENT_PREFIX + event, handler, false);
    return (map[event] = { queue, handler });
}

function removeEvent(elem, event, fn) {
    elem[EVENT_HANDLER_REMOVE](EVENT_PREFIX + event, fn);
}

function formatEvent(evt) {
    let target = evt.target;
    return {
        originalEvent: evt,
        target: target.nodeType === 3 ? target.parentNode : target,
        preventDefault() {
            evt.preventDefault
                ? evt.preventDefault()
                : (evt.returnValue = false);
        },
        stopPropagation() {
            evt.stopPropagation
                ? evt.stopPropagation()
                : (evt.cancelBubble = true);
        }
    };
}
