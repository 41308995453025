import global from '@/common/global';

/**
 * @class {Lazy}
 * @desc { for lazy collection v-lazy element data ， centralized management }
 */
class Lazy {
    constructor() {
        this.els = [];
    }
    checkInViews() {
        return this.els.filter((el) => {
            if (el._loaded) return false;
            return isInView(el._host);
        });
    }
    addEl(el) {
        this.els.push(el);
    }

    removeEl(el) {
        for (let i = 0; i < this.els.length; i++) {
            if (el === this.els[i]._host) {
                this.els.splice(i, 1);
                break;
            }
        }
    }

    addEvent(el, event, callback = null) {
        el.addEventListener(event, callback, false);
    }

    removeEvent(el, event, callback = null) {
        el.removeEventListener(event, callback, false);
    }

    shouldLoad() {
        let needLoadEls = this.checkInViews();
        let invoked = false;
        needLoadEls.map((child) => {
            if (child.callback && !child._loaded) {
                // externally determine whether the current item has been loaded
                child._loaded = child.callback(child.context);
                invoked = true;
            }
        });
        return invoked;
    }

    shouldLoadItem(elItem) {
        //  not loaded and inside the view 
        if (!elItem._loaded && isInView(elItem._host)) {
            elItem.callback &&
                (elItem._loaded = elItem.callback(elItem.context));
        }
    }
}
Lazy.eventMap = {};
let lazyClass = new Lazy();

export default (Vue) => {
    /**
     * use:
     *  <ul v-lazy-container="{trigger:['scroll','transitionend']}">
     *      <li v-lazy="{context:item,callback:func}" v-for="(item,index) in data"></li>
     *  </ul>
     *
     *  methods:{
     *      //  make sure item type [object Object]
     *      async func(item){
     *          // request here
     *          let res = await axios.post('/api',{params})
     *          if(res){
     *              Object.assign(item,res)
     *              return true
     *          }
     *          return false;
     *          //  return a true、false Indicates the current lazy Is the item loaded （ success ）, Modified is item._loaded
     *      }
     *  }
     *
     *  tips: Can add multiple v-lazy-container instruction ， used in different containers ， triggered in different ways lazyload,
     * 		  must be bound uniquely key， Data is updated asynchronously each time key keep unique （ not for previously used ）
     */

    /**
     * v-lazy="{contenxt:item,callback:func,}"
     * @params {object} {context:data,callback(arg:context),_loaded,_host}
     * @params context  Context that needs to be applied ，
     *      @description  if context exists ， default in v-lazy-container trigger When the event in the ， call callback ， and return the context ， Confirm whether the asynchronous request is successful according to the return value of the callback function ， If successful, the callback will not be triggered twice ， Callback if unsuccessful attempt multiple times 
     *      @description  Without this parameter ， By default listens for scrolling and triggers a callback when the element is in view ， multiple triggers 
     */
    Vue.directive('lazy', {
        beforeMount(el, binding) {
            if (
                Object.prototype.toString.call(binding.value) !==
                '[object Object]'
            ) {
                throw Error('args type error: must be object');
            } else {
                if (binding.value && binding.value['context']) {
                    binding.instance.$nextTick(() => {
                        const elItem = Object.assign(binding.value, {
                            _host: el,
                            _loaded: false
                        });
                        lazyClass.addEl(elItem);
                        lazyClass.shouldLoadItem(elItem);
                    });
                }
            }
        },
        mounted(el, binding) {
            //  No context default scroll trigger callback ， Not compatible with data collection v-scrollvisible instruction 
            if (binding.value && !binding.value['context']) {
                const { callback, options } = binding.value;
                let ticking = false;
                el.__vueScrollVisible__ = function () {
                    if (!ticking) {
                        //  prevent jitter 
                        window.requestAnimationFrame(function () {
                            let res = isOutoffViewPoint(el, options);
                            callback && callback(res);
                            ticking = false;
                        });
                    }
                    ticking = true;
                };
                global.scroll(el.__vueScrollVisible__);
            }
        },
        unmounted(el) {
            if (el.__vueScrollVisible__) {
                global.scroll(el.__vueScrollVisible__, false);
                delete el.__vueScrollVisible__;
            } else {
                lazyClass.removeEl(el);
            }
        }
    });

    /**
     * v-lazy-container
     * @params {object} {trigger:['scroll']}  The name of the event that triggered the callback ， The default is scroll
     */
    Vue.directive('lazy-container', {
        beforeMount(el, binding) {
            binding.instance.$nextTick(() => {
                // when there is no trigger mode, the default is scroll
                const trigger = binding.value['trigger'] || ['scroll'];
                trigger &&
                    trigger.map((eventName) => {
                        const fn = debounce(() => {
                            lazyClass.shouldLoad();
                        });
                        if (!Lazy.eventMap[eventName])
                            Lazy.eventMap[eventName] = [];
                        Lazy.eventMap[eventName].unshift({ el, fn });
                        lazyClass.addEvent(el, eventName, fn);
                    });
            });
        },
        mounted(el) {
            let frameId;
            function checkContainerInView() {
                if (isInView(el)) {
                    //  first load ， Detect that the element is in the view and call the callback function to cancel the listener 
                    let invoked = lazyClass.shouldLoad();
                    if (invoked) {
                        cancelAnimationFrame(frameId);
                    } else {
                        frameId = requestAnimationFrame(checkContainerInView);
                    }
                } else {
                    frameId = requestAnimationFrame(checkContainerInView);
                }
            }
            frameId = requestAnimationFrame(checkContainerInView);
        },
        unmounted(el, binding) {
            // unbind event
            Object.keys(Lazy.eventMap).map((name) => {
                const currentLazyEventMaps = Lazy.eventMap[name];
                for (let i = 0; i < currentLazyEventMaps.length; i++) {
                    if (el === currentLazyEventMaps[i].el) {
                        lazyClass.removeEvent(
                            el,
                            name,
                            currentLazyEventMaps[i].fn
                        );
                        currentLazyEventMaps.splice(i--, 1);
                    }
                }
            });
        }
    });
};

function isInView(el) {
    var { top, right, bottom, left } = el.getBoundingClientRect();
    var width = window.innerWidth;
    var height = window.innerHeight;
    if (bottom < 0 || top > height) {
        return false;
    }
    if (right < 0 || left > width) {
        return false;
    }
    return true;
}

function debounce(fn, delay = 300) {
    let timer = null;
    return function () {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, [...arguments]);
        }, delay);
    };
}

function isOutoffViewPoint(el, options) {
    //  Get the relative view position of the element 
    let res = el.getBoundingClientRect();
    let isOutOffView = {
        //  The element height plus the element's relative top height is less than  0  The description is that the top is not visible 
        invisibleOnTop: res.top + res.height < 0,
        //  The distance from the top of the element is greater than the height of the view, indicating that the bottom is not visible 
        invisibleOnBottom: res.top > window.innerHeight
    };

    return Object.assign(res, {
        isOutOffView
    });
}
