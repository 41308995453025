export default {
    state: () => ({
        sort: null,
        page: 1
    }),
    actions: {
        // TRACK_UPDATE_SORT
        trackUpdateSort(sort) {
            this.sort = sort;
        },
        // TRACK_UPDATE_PAGE
        trackUpdatePage(page) {
            this.page = page;
        }
    }
};
