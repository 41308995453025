const NUMBER_UNITS = ['', 'K', 'M', 'B'],
    regFormatFloat = /\d{1,3}(?=(\d{3})+\.\d+[^\d]?$)/g,
    regFormatInteger = /\d{1,3}(?=(\d{3})+[^\d]?$)/g,
    regFormatZero = /\.0+(?=([^\d]*$))/;
/**
 *  format number data 
 * @param {number} num  formatted data 
 * @param {boolean} units  Do you need a unit (K,M)
 * @param {number} fixed  decimal places reserved 
 * @param {string} prefix  prefix 
 * eg: fn(10000) -> 10,000
 * eg: fn(10000,true) -> 10K, fn(10000000,true) -> 10M
 * eg: numberFormat(1510,true,2) -> 1.51K
 * eg: fn(1000.213,false,2) -> 1000.21, fn(1000.123,true,2) —> 1K
 * eg: fn(10000,true,2,'$') -> $10K
 */

export default function (num, units, fixed, prefix = '') {
    if (isNaN(num)) return '';
    num = parseFloat(num);
    fixed = parseInt(fixed, 10);
    if (units) {
        if (isNaN(fixed)) fixed = 1;
        let idx = parseInt((parseInt(num).toFixed(0).length - 1) / 3),
            maxIdx = NUMBER_UNITS.length - 1;
        if (idx >= maxIdx) {
            idx = maxIdx;
            num = (num / Math.pow(1000, idx)).toFixed(fixed);
        } else {
            num = (num / Math.pow(1000, idx)).toFixed(fixed);
            if (num >= 1000) {
                num = (num / 1000).toFixed(fixed);
                idx += 1;
            }
        }
        num += NUMBER_UNITS[idx];
    } else if (!isNaN(fixed)) {
        num = Number(num.toFixed(fixed));
    }
    num = num.toString().replace(regFormatZero, '');
    return (
        prefix +
        num.replace(
            num.indexOf('.') === -1 ? regFormatInteger : regFormatFloat,
            '$&,'
        )
    );
}
//CHIME-19520 handle showing of multi house cards of map
export function formatNumberForFixed(num, units, fixed, prefix = '') {
    if (isNaN(num)) return '';
    num = parseFloat(num);
    fixed = parseInt(fixed, 10);
    if (units) {
        if (isNaN(fixed)) fixed = 1;
        let idx = parseInt((parseInt(num).toFixed(0).length - 1) / 3),
            maxIdx = NUMBER_UNITS.length - 1;
        idx = Math.min(maxIdx, idx);
        num = num / Math.pow(1000, idx);
        num = (
            Math.floor(num * Math.pow(10, fixed)) / Math.pow(10, fixed)
        ).toFixed(fixed);
        num += NUMBER_UNITS[idx];
    } else if (!isNaN(fixed)) {
        num = Number(num.toFixed(fixed));
    }
    num = num.toString().replace(regFormatZero, '');
    return (
        prefix +
        num.replace(
            num.indexOf('.') === -1 ? regFormatInteger : regFormatFloat,
            '$&,'
        )
    );
}
