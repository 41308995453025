
export const ROLE_MAP = {
    Other: -1,
    Seller: 1,
    Buyer: 2,
    BandS: 3,
    Renter: 5,
    Investor: 6,
    Agent: 7,
    Homeowner:8,
    Landlord:9
};

export const getBuyerRequestionList = isOwnerHouse => {
    return [
        {
            key: 'question1',
            show: isOwnerHouse,
            behavior: 'questionWantChangeOneHouse',
            item: [
                {
                    icon: 'icon-house_15',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question1'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/leadType',
                        type: 'post',
                        data: {
                            leadTypeList: ['Seller', 'Buyer'],
                            behavior: 'questionWantChangeOneHouse',
                        }
                    }
                },
                {
                    icon: 'icon-house_17',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question2'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/leadType',
                        type: 'post',
                        data: {
                            leadTypeList: ['Buyer'],
                            behavior: 'questionWantChangeOneHouse',
                        }
                    }
                }
            ]
        },
        {
            key: 'question2',
            show: true,
            behavior: 'questionWantBuyingTime',
            item: [
                {
                    icon: 'icon-calendar_01',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question3'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/phoneAddon/questionnaire',
                        type: 'post',
                        data: {
                            timeFrame: '1-3'
                        }
                    }
                },
                {
                    icon: 'icon-view',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question4'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/phoneAddon/questionnaire',
                        type: 'post',
                        data: {
                            timeFrame: 'Just Looking'
                        }
                    }
                }
            ]
        },
        {
            key: 'question3',
            show: true,
            behavior: 'questionPreQualified',
            item: [
                {
                    icon: 'icon-pre-approval',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question5'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/phoneAddon/questionnaire',
                        type: 'post',
                        data: {
                            preQualified: 'YES'
                        }
                    }
                },
                {
                    icon: 'icon-pre-approval_02',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question6'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/phoneAddon/questionnaire',
                        type: 'post',
                        data: {
                            preQualified: 'NO'
                        }
                    }
                }
            ]
        }
    ].filter(item => item.show);
};

export const getSellerRequestionList = isBuyerHouse => {
    return [
        {
            key: 'question1',
            show: true,
            behavior: 'questionSellingBuyNew',
            item: [
                {
                    icon: 'icon-like',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question9'),
                    note: {
                        desc: 'Lead has improved the personal info: lead also wants to buy a house'
                    },
                    type: 'buyer',
                    ajax: {
                        url: '/api-site/questionnaire/sync/leadType',
                        type: 'post',
                        data: {
                            leadTypeList: ['Seller', 'Buyer'],
                            behavior: 'questionSellingBuyNew',
                            extraData: 'buyer',
                        }
                    }
                },
                {
                    icon: 'icon-dislike',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question10'),
                    note: {
                        desc: 'Lead has improved the personal info: lead currently renting a house.'
                    },
                    type: 'renter',
                    ajax: {
                        url: '/api-site/questionnaire/sync/leadType',
                        type: 'post',
                        data: {
                            leadTypeList: ['Seller', 'Renter'],
                            behavior: 'questionSellingBuyNew',
                            extraData: 'renter',
                        }
                    }
                }
            ]
        },
        {
            key: 'question2',
            show: isBuyerHouse,
            behavior: 'questionWantBuyingTime',
            item: [
                {
                    icon: 'icon-calendar_01',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question3'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/phoneAddon/questionnaire',
                        type: 'post',
                        data: {
                            timeFrame: '1-3'
                        }
                    }
                },
                {
                    icon: 'icon-view',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question4'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/phoneAddon/questionnaire',
                        type: 'post',
                        data: {
                            timeFrame: 'Just Looking'
                        }
                    }
                }
            ]
        },
        {
            key: 'question3',
            show: true,
            behavior: 'questionPreQualified',
            item: [
                {
                    icon: 'icon-like',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question7'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/phoneAddon/questionnaire',
                        type: 'post',
                        data: {
                            preQualified: 'YES'
                        }
                    }
                },
                {
                    icon: 'icon-dislike',
                    label: window.siteGlobalization.$t('common:adNewQuestion.question8'),
                    ajax: {
                        url: '/api-site/questionnaire/sync/phoneAddon/questionnaire',
                        type: 'post',
                        data: {
                            preQualified: 'NO'
                        }
                    }
                }
            ]
        }
    ].filter(item => item.show);
};
