function getValue(styles, key) {
    let indexBegin = styles.indexOf(key);
    if (indexBegin == -1) {
        return '';
    }
    indexBegin += key.length;
    let indexEnd = styles.indexOf(';', indexBegin);
    return styles
        .substring(indexBegin + 1, indexEnd)
        .trim()
        .replace(/'|"/g, '');
}

function getSrc(styles, key) {
    let reg = new RegExp(`['"]${key}[^}]*src[^url]*(url[^;}]*);?}`);
    let matchList = styles.match(reg);
    return matchList && matchList.length >= 2 && matchList[1];
}

async function loadFont(name, src) {
    if (document.fonts.check(`12px ${name}`, 'a')) {
        //loaded
        return;
    }
    const font = new FontFace(name, src);
    await font.load();
    document.fonts.add(font);
}

function loadSiteFont(styles) {
    if (!document.fonts) {
        return;
    }
    //font appear times in site
    [
        'font-title', //229
        'font-normal', //1194
        'font-bold', //1340
        'font-medium', //660
        'font-light' //29
    ].forEach(name => {
        let fontName = getValue(styles, `--${name}`);
        let src = getSrc(styles, name);
        if (!src) {
            return;
        }
        loadFont(name, src);
        loadFont(fontName, src);
    });
}
try {
    // console.log('run load font');
    var elThemeStyle = document.getElementById('theme-style');
    elThemeStyle && loadSiteFont(elThemeStyle.textContent);
} catch (e) {
    console.error('Load Font Error', e);
}
