function colorToRgba(color) {
    try {
        let hex = color.trim();
        let r,
            g,
            b,
            a = 1;
        if (hex.startsWith('#')) {
            hex = hex.replace('#', '');
            if (hex.length === 3) {
                hex = hex
                    .split('')
                    .map(char => char + char)
                    .join('');
            }
            r = parseInt(hex.substring(0, 2), 16);
            g = parseInt(hex.substring(2, 4), 16);
            b = parseInt(hex.substring(4, 6), 16);
        } else if (hex.startsWith('rgba')) {
            const parts = hex.match(
                /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+\.?\d*)\)/
            );
            r = parseInt(parts[1], 10);
            g = parseInt(parts[2], 10);
            b = parseInt(parts[3], 10);
            a = parseFloat(parts[4]);
        } else if (hex.startsWith('rgb')) {
            const parts = hex.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
            r = parseInt(parts[1], 10);
            g = parseInt(parts[2], 10);
            b = parseInt(parts[3], 10);
        } else {
            return color;
        }
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    } catch (error) {
        return color;
    }
}
const parseRgba = function (rgba) {
    let rgbaValues = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
    return {
        r: parseInt(rgbaValues[1]),
        g: parseInt(rgbaValues[2]),
        b: parseInt(rgbaValues[3]),
        a: parseFloat(rgbaValues[4])
    };
};
export const blendRgba = function (rgba1, rgba2) {
    if (!rgba1 || !rgba2) return '';
    let c1 = parseRgba(colorToRgba(rgba1));
    let c2 = parseRgba(colorToRgba(rgba2));
    if (c1.a === 1 && c2.a !== 1) {
        return blendRgba(rgba2, rgba1);
    }
    let newAlpha = c1.a + c2.a * (1 - c1.a);
    let r = Math.round((c1.r * c1.a + c2.r * c2.a * (1 - c1.a)) / newAlpha);
    let g = Math.round((c1.g * c1.a + c2.g * c2.a * (1 - c1.a)) / newAlpha);
    let b = Math.round((c1.b * c1.a + c2.b * c2.a * (1 - c1.a)) / newAlpha);
    return `rgba(${r}, ${g}, ${b}, ${newAlpha.toFixed(2)})`;
};
