const tmpl = text =>
    `<div class="loading-anim" style="margin: 0 auto;"></div><div class="loading">${text}</div>`;

export default Vue => {
    Vue.directive('loading', {
        beforeMount(el, binding) {
            let defaultText = window.siteGlobalization.$t('common:loading');
            const text = el.getAttribute('loading-text') || defaultText;
            const style = el.getAttribute('loading-style') || '';
            const mask = el.getAttribute('loading-mask') || '';
            const loadingEle = document.createElement('div');
            loadingEle.classList.add('loading-wrap');
            loadingEle.style = style;
            loadingEle.innerHTML = tmpl(text);

            var ele = loadingEle;
            if (mask) {
                ele = document.createElement('div');
                ele.classList.add('loading-mask');
                ele.appendChild(loadingEle);
            }

            // //  Initialize to determine whether to hide 
            ele.style.display = binding.value ? '' : 'none';
            // if (!!binding.value) {
            //     el.classList.add('is-loading');
            // }

            el.appendChild(ele);
            el.loading_ele = ele;
        },
        updated(el, binding) {
            if (!el.loading_ele || !el.loading_ele.style) {
                return;
            }
            el.loading_ele.style.display = binding.value ? '' : 'none';
            // if (!!binding.value) {
            //     el.classList.add('is-loading');
            // } else {
            //     el.classList.remove('is-loading');
            // }
        }
    });
};
