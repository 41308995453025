// module-box spacing  default allocation 
const defaultSpacing = {
    //  Module white background ， with title the module 
    normal: {
        topSpacing: 10,
        btmSpacing: 50,
        mobileTopSpacing: 5,
        mobileBtmSpacing: 15
    },
    //  The module is a picture or a color block 
    colorBlock: {
        topSpacing: null,
        btmSpacing: 60,
        mobileTopSpacing: null,
        mobileBtmSpacing: 20
    },
    //  special treatment md-team(multi-column-info-under-img,multi-column-info-over-img)
    restColorBlock: {
        topSpacing: 10,
        btmSpacing: 40,
        mobileTopSpacing: 5,
        mobileBtmSpacing: 15
    },
    restNormal: {
        topSpacing: 10,
        btmSpacing: 30,
        mobileTopSpacing: 5,
        mobileBtmSpacing: 15
    },
    brokerListSpacing: {
        topSpacing: 10,
        btmSpacing: 50,
        mobileTopSpacing: 5,
        mobileBtmSpacing: 25
    }
};

export const normal = defaultSpacing.normal;
export const colorBlock = defaultSpacing.colorBlock;
export const restColorBlock = defaultSpacing.restColorBlock;
export const restNormal = defaultSpacing.restNormal;
export const brokerListSpacing = defaultSpacing.brokerListSpacing;
