import getClientPlatform from './getClientPlatform';
import clientAxios from './clientAxios';
import './formatFontSize';

export default function(globalData) {
    return Object.freeze({
        axiosIns: clientAxios,
        ...getClientPlatform(globalData)
    });
}
