import util from "@/common/util"
export const GLOBAL_REGISTRATION_POP_UP = 'global-registration-pop-up';
export const registrationEnum = {
    styleType: {
        singleColumn: 'single column',
        twoColumn: 'two column',
        oneStepColumn: 'one step column',
        chatForm: 'chat form',
        ppForm: 'pp form',
        sellForm: 'sell form'
    },
    registrationType: {
        chromeApi: 'chrome-api',
        fb: 'fb register',
        gmail: 'gmail register',
        direct: 'direct'
    },
    triggeringEvent: {
        auto: 'auto',
        click: 'click'
    }
};

function mountToWindow(key, value) {
    if (!util.isSSRServer) {
        if (window.siteDataLayer) {
            window.siteDataLayer.push({
                event: 'mount-to-window',
                keyToWindow: key,
                valueToWindow: value
            });
        }
    }
}

const regAction = {
    appear: 'Pop-up appearing',
    email: 'Input email - Pop',
    name: 'Input name - Pop',
    phone: 'Input phone - Pop',
    continue: 'Click Continue - Pop',
    facebook: 'Click FB register - Pop',
    google: 'Click Google register - Pop',
    register: 'Click register button - Pop',
    close: 'click close register'
};
const regLabel = {
    appear: getRegisEventLabel,
    email: getRegisEventLabel,
    name: getRegisEventLabel,
    phone: getRegisEventLabel,
    continue(flag) {
        return `${getRegisEventLabel()}+${state[flag]}`;
    },
    facebook(flag) {
        return `${getRegisEventLabel()}+${state[flag]}`;
    },
    google(flag) {
        return `${getRegisEventLabel()}+${state[flag]}`;
    },
    register(flag) {
        return `${getRegisEventLabel()}+${state[flag]}`;
    },
    close: getRegisEventLabel
};
export const regType = {
    appear: 'appear',
    email: 'email',
    name: 'name',
    phone: 'phone',
    continue: 'continue',
    facebook: 'facebook',
    google: 'google',
    register: 'register',
    close: 'close'
};
export const state = {
    success: 'success',
    fail: 'fail'
};
const initRegistrationStatus = {
    registrationType: registrationEnum.registrationType.direct,
    triggeringEvent: '',
    styleType: ''
};
let registrationStatus = initRegistrationStatus;
mountToWindow('gtm_register_key', registrationStatus);

export function getRegisEventLabel() {
    return Object.keys(registrationStatus).reduce((l, cur) => {
        if (registrationStatus[cur]) {
            l += `+${registrationStatus[cur]}`;
        }
        return l;
    }, '');
}

export function setRegisStatus(obj = {}) {
    if (typeof obj === 'object') {
        registrationStatus = { ...registrationStatus, ...obj };
        mountToWindow('gtm_register_key', registrationStatus);
    }
}
export function resetRegisStatus() {
    registrationStatus = initRegistrationStatus;
}

export function emitTrack(type, state, error) {
    window.siteAppVm.$EventBus.$emit('siteTrack', {
        trackType: GLOBAL_REGISTRATION_POP_UP,
        eventCategory: 'Global Registration Pop-up',
        eventAction: regAction[type],
        eventLabel: `${regLabel[type](state)}${error ? '+' + error : ''}`
    });
}
