import util from '@/common/util';
import usePageStore from '../page';

function checkUserInOtherIframe() {
    if (!util.isSSRServer){
        //  If the pop-up box on the details page ,  Notify the login status of the parent window after the login status changes 
        if (util.inPopDetailPage) {
            let userStore = window.parent.getStore('user');
            userStore.getUser({ checkOtherIframe: false });
        } else if (window.__curDetailPop) {
            window.__curDetailPop.refreshIframUserState();
        }
    }
}

export default {
    state: () => ({
        user: 0,
        loginStatus: 0, // 0  is the initial state ，1  yes   Log in ，-1  is log out 
        useCloselyApp: false,
        userColorMap: {
            //  The color of the user avatar ， Randomly choose one 
            0: '#A88668',
            1: '#6C7780',
            2: '#6B886A',
            3: '#876D6D',
            4: '#7D5E7C',
            5: '#A88668',
            6: '#6C7780',
            7: '#6B886A',
            8: '#876D6D',
            9: '#7D5E7C'
        },
    }),
    getters: {
        hasLoggedIn: function(state) {
            return !!state.user;
        },
        // vow email verify
        userEmailVerified: function(state) {
            return (
                this.pageLocation.query.isPopup === '0' ||
                (state.user?.vowListingsStatus ?? false)
            );
        },
        userBackgroundColor(state) {
            //  according to userId random color 
            const curUserId = String(state.user?.id || 0);
            const len = curUserId.length;
            const index = curUserId.charAt(len - 1);
            return state.userColorMap[index];
        }
    },
    actions: {
        // LOGIN
        login({ user, checkOtherIframe }) {
            if(!user){
                this.$patch(state => {
                    state.loginStatus = -1;
                    state.user = null;
                });
                return;
            } 
            this.$patch(state => {
                state.loginStatus = 1;
                state.user = user;
            });
            if (user) {
                this.setCookie('visitor_email', user.account);
                this.setCookie('visitor_name', user.fullName);
                this.setCookie('user_login_status', '1');
                if (checkOtherIframe !== false) {
                    checkUserInOtherIframe();
                }
                if (!util.isSSRServer){
                    window.siteAppVm?.$EventBus?.$emit('userLogin', user);
                }
            }
        },
        // LOGOUT
        logout(checkOtherIframe) {
            this.$patch(state => {
                state.loginStatus = -1;
                state.user = null;
            });
            this.setCookie('visitor_email', '');
            this.setCookie('visitor_name', '');
            this.setCookie('user_login_status', '');

            if (checkOtherIframe !== false) {
                checkUserInOtherIframe();
            }
            //  user information cache 
            // history.replaceState(null,null,`#r${Date.now()}`);
            if (!util.isSSRServer){
                window.siteAppVm?.$EventBus?.$emit('userLogout');
            }
        },
        // UPDATE_USER
        updateUser(userData) {
            this.$patch(state => {
                Object.assign(state.user, userData);
            });
        },
        getUser(options) {
            let pageStore = usePageStore(this.$pinia);
            let loginInfo = pageStore.page.loginInfo;
            var checkOtherIframe =
                options.checkOtherIframe === undefined
                    ? true
                    : options.checkOtherIframe,
                fromGlobal =
                    options.fromGlobal === undefined
                        ? false
                        : options.fromGlobal;
            if (
                fromGlobal &&
                !loginInfo &&
                this.getCookie('user_login_status') === '1'
            ) {
                //  if logged in ， But because of caching ， There is no login information in the global data ， Then directly call the interface to obtain 
                console.log('loginInfo', loginInfo);
                fromGlobal = false;
            }
            var ps = fromGlobal
                ? Promise.resolve(loginInfo)
                : this.axiosIns
                    .post(
                        '/api-graphql',
                        {
                            query: `{loginInfo{id,account,firstName,fullName,lastName,phoneNumber,teamId,inactive,createTimeMills,firstLogin,vowListingsStatus,agreeProtocol,baseId,officeId,enterpriseRole,userState,tou}}`
                        },
                        {
                            noCache: true
                        }
                    )
                    .then(({ data }) => {
                        return data && data.loginInfo;
                    });
            return ps
                .then(loginInfo => {
                    if (loginInfo) {
                        loginInfo.createTime = loginInfo.createTimeMills;
                        this.login({
                            user: loginInfo,
                            checkOtherIframe,
                            fromGetUser: true,
                        })
                        return loginInfo;
                    } else {
                        this.logout(checkOtherIframe);
                        return 0;
                    }
                })
                .catch(e => {
                    this.loginStatus = -1;
                    this.setCookie('visitor_email', '');
                    this.setCookie('visitor_name', '');
                    console.error('loginInfo', e);
                });
        },
        // FETCH_USE_CLOSELY_APP
        async fetchUseCloselyApp() {
            let pageStore = usePageStore(this.$pinia);
            let account = this.user?.account;
            let useCloselyApp = false;
            if(account && ! util.isBot){
                let { data = {} } = await this.axiosIns.get(
                    `/api-site/credential/closely-app/check-use?siteId=${pageStore.page.siteId}&account=${account}`
                )
                useCloselyApp = data.useApp || false
            }
            this.useCloselyApp = useCloselyApp;
        },
    }
};
