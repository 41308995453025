import googleTranslate from './googleTranslatePlugin/doGTranslate';
import { getStore } from '@/store';

export default {
    setLang: function (lang, { needRefresh } = { needRefresh: true }) {
        var translatorType = getStore('page').page.translatorType;
        switch (translatorType) {
            case 'chime':
                window.siteAppVm.changeLanguage(lang, { needRefresh });
                break;
            case 'googlePlugin':
                googleTranslate({ value: lang });
                break;
        }
    },
    googleTranslate: googleTranslate
};
