<script>
    import { h, defineAsyncComponent, markRaw, withMemo } from "vue";
    import getModule from '../../modules/index';
    import { mapState } from 'pinia';
    import { usePageStore } from '@/store';
    import util from '@/common/util';

    export default{
        props:{
            mdData:{
                type:Object
            }
        },
        data: function(){
            return {
                isMounted: false,
                cache: markRaw([])
           }
        },
        computed:{
            ...mapState(usePageStore, {
                globalData: store => store.page,
            })
        },
        beforeUnmount(){
            this.isMounted = false;
        },
        mounted(){
            this.isMounted = true;
        },
        render(){
            const item = this.mdData;
            if(!item){
                return null; 
            }
            if(item.data?._csr && util.isSSRServer && !this.isMounted){
                return null;
            }
            if(!this.isMounted){
               return h(
                    defineAsyncComponent(() => {
                        return getModule(
                            item.name,
                            item.theme,
                            this.globalData,
                            this.platform.isMobile
                        );
                    }),
                    {   
                        mdData: item,
                        key: item.id,
                        from:'module-layout',
                        ...this.$attrs
                    },
                    item.content || ''
                )
            }
            // https://github.com/vuejs/core/issues/4573
            return withMemo([item.id,item.name,item.theme],()=>h(
                defineAsyncComponent(() => {
                    return getModule(
                        item.name,
                        item.theme,
                        this.globalData,
                        this.platform.isMobile
                    );
                }),
                {   
                    mdData: item,
                    key: item.id,
                    from:'module-layout',
                    ...this.$attrs
                },
                item.content || ''
            ),this.cache,0);
            // 防止反复的挂载，本来可以用 v-memo 
           
        }
    }
</script>