import { getQueryField } from './utils/url-params';
import util from './utils/util-tool';
const { getCookie, setCookie, removeCookie } = util;



const isDebug = (key)=>{
    return getQueryField(key) || getCookie(key);
}
const getDebug = (key)=>{
    return ()=>{
        let CmsUrl = '';
        try {
            CmsUrl = window.top.cmsAppVm && window.top.location.href;
        } catch (e) {}
        let debugReg = new RegExp(key + '=1');
        return debugReg.test(window.location.href) || debugReg.test(CmsUrl);
    }
}
const toggleDebug = (key)=>{
   return ()=>{
        if (isDebug(key)) {
            setCookie(key, '1');
        } else if (getQueryField(key) === '0') {
            removeCookie(key);
        }
   }
}


const CHIME_DEBUG = 'chime-debug';

const FEDERATED_DEBUG = 'federation-debug';

export const IS_DEBUG = isDebug(CHIME_DEBUG)

export const getTopDebug = getDebug(CHIME_DEBUG)

export const toggleDebugCache = toggleDebug(CHIME_DEBUG)


export const getFederatedDebug = getDebug(FEDERATED_DEBUG)
