<template>
    <div
        :class="{
            'mg-container': !fullColumn,
            'mobile-no-margin': mobileNoMargin
        }"
        :style="mergeStyle"
    >
        <slot></slot>
    </div>
</template>

<script>
/**
 *  This component handles site How the modules are laid out on the page ,  spacing and such
 */
import { normal } from '../../modules/defaultSpacing';
const { topSpacing, btmSpacing, mobileTopSpacing, mobileBtmSpacing } = normal;
export default {
    computed: {
        isMobileSize() {
            return this.platform.isMobile
        },
        paddingTop() {
            return (
                (this.isMobileSize ? this.mobileTopSpacing : this.topSpacing) +
                'px'
            );
        },
        marginBottom() {
            return (
                (this.isMobileSize ? this.mobileBtmSpacing : this.btmSpacing) +
                'px'
            );
        },
        mergeStyle({containerStyle,noPadding,noMargin,paddingTop,marginBottom}){
            let style = {};
            if(containerStyle){
                style = {...containerStyle};
            }
            if(!noPadding){
                style['padding-top'] = paddingTop;
            }
            if(!noMargin){
                style['margin-bottom'] = marginBottom;
            }
            return style;
        }
    },
    props: {
        fullColumn: {
            type: Boolean,
            default: false
        },
        topSpacing: {
            default: topSpacing
        },
        btmSpacing: {
            default: btmSpacing
        },
        mobileTopSpacing: {
            default: mobileTopSpacing
        },
        mobileBtmSpacing: {
            default: mobileBtmSpacing
        },
        //  Do not apply top and bottom margins
        noPadding: {
            type: Boolean,
            default: false
        },
        noMargin: {
            type: Boolean,
            default: false
        },
        mobileNoMargin: {
            type: Boolean,
            default: false
        },
        containerStyle: {
            type: Object,
            default: undefined
        }
    }
};
</script>
