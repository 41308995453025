<template>
    <Transition name="fade">
        <div class="im-tag-container">
            <div class="im-tag" :class="tagClass">
                <div class="content" @click.stop="check">
                    <div class="ellipsis"><slot></slot></div>
                </div>
                <div class="close" v-if="closable" @click.stop="close">
                    <span class="iconfont icon-close-light"></span>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import classNames from 'classnames';
export default {
    name: 'im-tag',
    emits: ['check', 'close'],
    props: {
        closable: {
            type: Boolean,
            default: true
        },
        theme: {
            validator: val =>
                ['standard', 'template1', 'template4', 'default'].includes(val),
            default: 'default'
        }
    },
    computed: {
        tagClass() {
            return classNames(this.theme);
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        check() {
            this.$emit('check');
        }
    }
};
</script>

<style lang="scss">
.im-tag-container {
    display: inline-block;
    .im-tag {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .content {
            max-width: 150px;
    
            display: flex;
            align-items: center;
            justify-content: center;
            .ellipsis {
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        .close {
            cursor: pointer;
        }
        &.default {
            background-color: #f4f4f5;
            border: 1px solid #e9e9eb;
            color: #909399;
            height: 24px;
            margin-left: 6px;
            font-size: 12px;
            border-radius: 4px;
            .content {
                padding: 0 8px;
                max-width: 120px;
            }
            .close {
                padding-right: 10px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #dadce2;
                .iconfont {
                    font-size: 10px;
                }
            }
        }
        &.standard {
            border: solid 1px #ebecf1;
            /* padding: 10px 0; */
            height: 30px;
            color: #202437;
            font-size: 14px;
            .content {
                padding: 0 10px 0 10px;
            }
            .close {
                padding: 10px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #dadce2;
                .iconfont {
                    font-size: 12px;
                }
            }
        }
        &.template1 {
            border: solid 1px #c8c8c8;
            /* padding: 10px 0; */
            color: #505050;
            font-size: 14px;
            height: 30px;
            .content {
                padding: 0 8px 0 10px;
            }
            .close {
                padding: 10px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #c8c8c8;
                .iconfont {
                    font-size: 12px;
                }
            }
        }
        &.template4 {
            /* padding: 5px 0; */
            color: #4a4a60;
            font-size: 14px;
            height: 26px;
            .content {
                padding: 0 8px 0 8px;
                border: solid 2px #d6dae8;
                border-right: none;
                height: 100%;
            }
            .close {
                height: 100%;
                width: 26px;
                background-color: #bfc9e4;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                .iconfont {
                    font-size: 12px;
                }
            }
        }
    }
}

/*  animation  */
$--im-fade-transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
    opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) !default;
.fade-enter {
    opacity: 0;
    transform: scale(0, 0);
}
.fade-enter-active,
.fade-leave-active {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: center center;
    transition: $--im-fade-transition;
}
.fade-leave-active {
    opacity: 0;
    transform: scale(0, 0);
}
</style>
