let defaultMsg = 'No Results';
let tpl = `<div style="height:100%;min-height:140px;padding:30px 0;text-align:center;font:14px var(--font-normal);box-sizing:content-box;" class="empty-comp empty-full">
        <div class="iconfont icon-sorry" style="margin-top:15px;font-size:50px;"></div>
        <p style="margin-top:15px;">{{msg}}</p>
    </div>`;

const toggleEmpty = (el, show) => {
    let showEmpty = !!show;
    if (showEmpty) {
        let emptyEl = el.querySelector('.empty-comp');
        if (!emptyEl) {
            let msg = el.getAttribute('empty-msg') || defaultMsg;

            emptyEl = document.createElement('div');
            emptyEl.innerHTML = tpl.replace(/\{\{[\w\W]+\}\}/gi, msg);
            emptyEl = emptyEl.querySelector('.empty-comp');
        }

        emptyEl.style.display = 'block';
        el.appendChild(emptyEl);
    } else {
        let emptyEl = el.querySelector('.empty-comp');
        emptyEl && (emptyEl.style.display = 'none');
    }
};
export default (Vue) => {
    Vue.directive('empty', {
        beforeMount(el, binding) {
            toggleEmpty(el, !!binding.value);
        },
        updated(el, binding) {
            toggleEmpty(el, !!binding.value);
        }
    });
};
