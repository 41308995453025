import util from '@/common/util';
import { IS_DEBUG } from '@/common/debug';
import {
    localCurrency,
    defaultCurrencyCode,
    defaultCurrencySymbol
} from './localCurrency';
import { defaultLngCode } from '@/globalization/constant.js';
import { getLanguageMenus, getCurrentLanguage } from '@/globalization/tool.js';
import languageGetters from './language';
import findSearchBarActions from './findSearchBarActions';
import { sortAgentSocialNetworks } from '@/common/agents';

const isArray_Object = function (value) {
    return (
        Object.prototype.toString.call(value) == '[object Object]' ||
        Array.isArray(value)
    );
};
const isEmptyObject = function (value) {
    Object.keys(value).length <= 0;
};
const customLangKey = 'lang';
const languageCookieNameMap = Object.freeze({
    chime: 'chimetrans',
    googlePlugin: 'chimetrans_gt'
});

function mergeData(to, from, getSysLanguageCb) {
    let keys = Object.keys(from);
    let key,
        len = keys.length;
    for (let i = 0; i < len; i++) {
        key = keys[i];
        let toVal = to[key];
        let fromVal = getSysLanguageCb(toVal, from[key]);
        let fromValIsObj = isArray_Object(fromVal);
        let toValIsObj = isArray_Object(toVal);
        if (fromValIsObj && toValIsObj) {
            mergeData(toVal, fromVal, getSysLanguageCb);
            continue;
        }
        if (fromVal == undefined) {
            continue;
        }
        if (toValIsObj && !fromValIsObj) {
            console.info('Language mixing will affect the data structure');
        }
        to[key] = from[key];
    }
}

const fetcherSysLanguageModules = {
    'md-footer': true,
    'md-header': true
};

const CURRENCY_BIZ_TYPE = 'SITE';
const DEFAULT_BIZ_TYPE = 'DEFAULT';
const localCurrencyKey = 'chime_currency';

function mergeLanguageToData(name, data, language, lng) {
    // if (!lng) {
    //     lng = globalization.lng();
    // }
    if (fetcherSysLanguageModules[name]) {
        mergeLanguageToData_fetcher(data, language, lng);
    } else {
        mergeLanguageToData_data(data, language, lng);
    }
    return data;
}
// Internationalized data is all in the same data equal sysLanguage middle
function mergeLanguageToData_data(data, language, lng) {
    if (!language || !Object.keys(language).length) {
        return;
    }
    let languageObj = language[lng];
    if (!languageObj) {
        // if there is not language Attributes
        languageObj = language[defaultLngCode];
    }
    if (!languageObj) {
        return;
    }
    mergeData(data, languageObj, (_, fromVal) => fromVal);
}
// The internationalization data section is stored in the data middle

function mergeLanguageToData_fetcher(data, language, lng) {
    const mergeLanguage = fullSysLanguage => {
        let languageObj =
            fullSysLanguage[lng] ?? fullSysLanguage[defaultLngCode];
        if (languageObj && !isEmptyObject(languageObj)) {
            mergeData(data, languageObj, (_, fromVal) => fromVal);
        }
    };
    if (language && !isEmptyObject(language)) {
        mergeLanguage(language);
    }
    if (data.sysLanguage && !isEmptyObject(data.sysLanguage)) {
        mergeLanguage(data.sysLanguage);
    }
}

function findBaseSymbol(currencylist, currency) {
    const targetCurrency = currencylist.find(item => item.abbr === currency);
    return targetCurrency?.symbol || '$';
}

function getTargetInfo(currencyConfigList, type) {
    const targetInfo =
        currencyConfigList.find(item => item.currencyBizType === type) || {};
    return targetInfo.configInfoList?.[0] || {};
}

export default {
    state: () => ({
        currentLanguage: defaultLngCode,
        // currentLink: '',
        currency: {
            symbol: defaultCurrencySymbol,
            abbr: defaultCurrencyCode
        },
        baseCurrency: 'USD', //  base currency
        baseSymbol: '$', //  base currency symbol ， save search tag need to use
        currencyRate: 1, //  exchange rate
        usdCurrencyRate: 1, //  US dollar to current currency exchange rate
        page: {},
        moduleData: [],
        agents: {},
        companyInfo: {},
        defaultCompanyName: '',
        mlsForClosely: false,
        socialTagType: 1,
        closelyDownloadLink: '',
        adminPermission: {}, // site-cms admin permission cache
        customRequestParams: {},
        hasLender: null,
        subdomainAgents:[]
    }),
    getters: {
        siteId(state) {
            return state.page.siteId ?? '';
        },
        forCMS(state) {
            let { pageKey } = state.page;
            return (
                pageKey === 'listing-for-cms' ||
                pageKey === 'listing-for-cms-snapshot'
            );
        },
        isSearchCenter(state) {
            return state.page.searchCenter;
        },
        soldListingFlag() {
            return this.isSearchCenter ? '4' : '1';
        },
        hasEmailLead(state) {
            var globalData = state.page;
            return (
                globalData &&
                globalData.site &&
                (globalData.site.emailLead ||
                    typeof globalData.site.emailLead === 'undefined')
            );
        },
        // chime | googlePlugin
        translatorType(state) {
            return state.page.translatorType;
        },
        languageMenus(state) {
            return getLanguageMenus(state.page);
        },
        showCurrency(state) {
            let { openMultilingual, siteCurrencyList } = state.page || {};
            return (
                openMultilingual &&
                siteCurrencyList &&
                siteCurrencyList.length > 1
            );
        },
        currencyList(state) {
            return state.page.siteCurrencyList || [];
        },
        processedGlobalData(state) {
            return {
                ...(state.page || {}),
                currencySymbol: state.currency.symbol,
                baseCurrency: state.baseCurrency,
                currencyRate: state.currencyRate,
                baseSymbol: state.baseSymbol,
                currencyAbbr: state.currency.abbr
            };
        },
        ...languageGetters
    },
    actions: {
        ...findSearchBarActions,
        // UPDATE_PAGE
        updatePage(page) {
            this.$patch(state => {
                Object.assign(state.page, JSON.parse(JSON.stringify(page)));
            });
        },
        // SET_PAGE
        setPage(page = {}) {
            this.$patch(state => {
                //  Global data should not be modified
                state.page = page;
                //  from page It parses out the base currency ， Exchange rate and other information
                const currencyConfigList = page.currencyConfigList || [];
                const configInfo = getTargetInfo(
                    currencyConfigList,
                    CURRENCY_BIZ_TYPE
                );
                const defaultConfigInfo = getTargetInfo(
                    currencyConfigList,
                    DEFAULT_BIZ_TYPE
                );
                state.baseCurrency = configInfo.currency || 'USD';
                state.baseSymbol = findBaseSymbol(
                    page.siteCurrencyList || [],
                    state.baseCurrency
                );
                state.currencyRate = configInfo.rate || 1;
                state.usdCurrencyRate = defaultConfigInfo.rate || 1;
            });
        },
        // // SET_CURRENT_LINK
        // setCurrentLink(currentLink) {
        //     this.currentLink = currentLink;
        // },
        // SET_MODULEDATA
        setModuleData(moduleData) {
            this.moduleData = moduleData || [];
        },
        // HANDLE_GLOBAL_LNG_DATA
        handleGlobalLngData() {
            this.$patch(state => {
                let lng = state.currentLanguage;
                for (let k in state.page) {
                    const v = state.page[k];
                    if (!v || !v.sysLanguage) {
                        continue;
                    }
                    mergeLanguageToData(null, v, v.sysLanguage, lng);
                }
            });
        },
        // HANDLE_MODULE_LNG_DATA
        handleModuleLngData() {
            this.$patch(state => {
                let lng = state.currentLanguage;
                state.moduleData.forEach(module => {
                    if (!module.data) {
                        return;
                    }
                    mergeLanguageToData(
                        module.name,
                        module.data,
                        module.sysLanguage,
                        lng
                    );
                });
            });
        },
        // SIMPLIFY_MODULEDATA
        simplifyModuleData() {
            this.$patch(state => {
                state.moduleData.forEach(module => {
                    delete module.commonData;
                    delete module.asyncModule;
                    delete module.asyncFields;
                    if (!util.inCms) {
                        delete module.sysLanguage;
                    }
                });
            });
        },
        // SET_LANGUAGE
        setCurrentLanguage(lang) {
            this.currentLanguage = lang;
        },
        // switch language ,  Will return whether it is actually applied
        setLanguage(lang) {
            if (
                !this.translatorType ||
                this.currentLanguage === lang ||
                !this.languageMenus.find(item => item.name == lang)
            ) {
                return Promise.resolve({ changed: false });
            }
            this.setCurrentLanguage(lang);
            this.setCookie(languageCookieNameMap[this.translatorType], lang);
            if (this.translatorType === 'chime') {
                if (!util.isSSRServer) {
                    util.setQueryField(
                        { lang: lang == 'en' ? '' : lang },
                        true
                    );
                }
                this.setCookie(customLangKey, lang);
                return Promise.resolve({ changed: true, isChime: true });
            }
            return Promise.resolve({ changed: true, isChime: false });
        },
        // SET_CURRENCY
        setCurrency(currency) {
            this.$patch(state => {
                state.currency.name = currency.name;
                state.currency.abbr = currency.abbr;
                state.currency.symbol = currency.symbol;
            });
        },
        selectCurrency(currency) {
            this.setCurrency(currency);
            localCurrency.set.call(this, currency);
            window.location.reload();
        },
        //  according to cookie initialization currentLanguage
        initCurrentLang() {
            if (!this.translatorType) {
                return;
            }
            let lang = getCurrentLanguage.call(this, this.page);
            this.setCurrentLanguage(lang);
        },
        initCurrency() {
            if (!this.showCurrency) {
                return;
            }
            const { siteCurrencyList, openMultilingual } = this.page;
            let abbr = this.getCookie(localCurrencyKey);
            if (!abbr) {
                if (siteCurrencyList.length <= 1 || !openMultilingual) {
                    //  set to dollar
                    return localCurrency.reset.call(this);
                } else {
                    //  If the base currency is not in siteCurrencyList in ， USD is selected by default
                    const baseCurrencyTarget = siteCurrencyList.find(
                        item => item.abbr === this.baseCurrency
                    );
                    if (!baseCurrencyTarget) {
                        return localCurrency.reset.call(this);
                    } else {
                        this.setCurrency(baseCurrencyTarget);
                        return localCurrency.set.call(this, baseCurrencyTarget);
                    }
                }
            }
            for (let i = 0; i < siteCurrencyList.length; i++) {
                if (siteCurrencyList[i].abbr == abbr) {
                    return this.setCurrency(siteCurrencyList[i]);
                }
            }
            localCurrency.reset.call(this);
            if (!util.isSSRServer){
                window.location.reload();
            }
        },
        getAgentInfo(agentId = 0, lpAgentFooter = '') {
            agentId = agentId || 0;

            if (!this.agentsPs) {
                this.agentsPs = {};
            }
            // 这里做了一个并发处理, 同时调用只发一个请求
            if (!this.agentsPs[agentId]) {
                this.agentsPs[agentId] = this.axiosIns
                    .post('/api-graphql', {
                        query: `{agent(id:${agentId}${lpAgentFooter}){firstName,lastName,image,headUrl:image,fullName,phone,externalUrl,email,position,license,agentSocialNetworks{id,url,socialNetwork},disclaimerList{fileName, queryLink},address,officeAddress}}`
                    })
                    .then(res => {
                        const agent = res.data?.agent || {};
                        return (this.agents[agentId] = {
                            ...agent,
                            agentSocialNetworks: sortAgentSocialNetworks(
                                agent.agentSocialNetworks
                            )
                        });
                    });
            }
            return this.agentsPs[agentId];
        },
        getCompanyInfo() {
            if (!this.companyPs) {
                this.companyPs = this.axiosIns
                    .post('/api-graphql', { query: `{company{name,address}}` })
                    .then(({ data = {} }) => {
                        this.defaultCompanyName = data.company?.name || '';
                        return (this.companyInfo = data.company || {});
                    });
            }
            return this.companyPs;
        },
        getMlsForClosely() {
            let agentUserId = this.page?.agentCreator?.agentUser?.id ?? -1;
            if (!this.mlsForCloselyPs) {
                this.mlsForCloselyPs = this.axiosIns
                    .get(
                        `/open-api/mls/trans/closely/has-auth?agentId=${agentUserId}`
                    )
                    .then(res => {
                        return (this.mlsForClosely = res.data?.hasUserAppAuth);
                    });
            }
            return this.mlsForCloselyPs;
        },
        // UPDATE_SOCIAL_TAG_TYPE
        updateSocialTagType(socialTagType) {
            this.socialTagType = socialTagType;
        },
        updateCloselyDownloadLink(agentId) {
            if (!agentId) {
                return;
            }
            this.axiosIns
                .get(
                    `/open-api/closely-open-api/download/website?agentId=${agentId}`
                )
                .then(res => {
                    this.$patch(state => {
                        state.page.closelyDownloadLink = res.data;
                    });
                })
                .catch(e => {
                    console.error(e);
                });
        },
        setCurrentUniqId(url, id) {
            if (!this.customRequestParams[url]) {
                this.customRequestParams[url] = {};
            }
            this.customRequestParams[url].id = id;
        },
        setCustomParams(url, id, value) {
            // use: this.customRequestParams[url][id]
            if (!this.customRequestParams[url]) {
                this.customRequestParams[url] = {};
            }
            this.customRequestParams[url][id] = value;
        },
        getCustomParams(url) {
            return (
                this.customRequestParams[url]?.[
                    this.customRequestParams[url].id
                ] || {}
            );
        },
        async getPermissionFromAdmin(linkId, whiteNameList, fnName) {
            if (IS_DEBUG) return true;
            if (!linkId || !whiteNameList || !fnName) return false;
            let id = `${linkId}_${whiteNameList}`;
            if (JSON.stringify(this.adminPermission) === '{}') {
                // _ap_: adminPermission
                this.adminPermission = JSON.parse(
                    util.getCookie('_ap_' + __BUILD_ID__) || '{}'
                );
            }
            if (!this.adminPermission[id]) {
                return this.axiosIns
                    .post(
                        `/open-api/site-auth-api/auth-function-white/checks`,
                        {
                            linkId,
                            whiteNameList: [whiteNameList]
                        }
                    )
                    .then(res => {
                        this.adminPermission[id] = {};
                        let list = res.data?.[whiteNameList];
                        list.forEach(v => {
                            this.adminPermission[id][v.functionName] = true;
                        });
                        util.setCookie(
                            '_ap_' + __BUILD_ID__,
                            JSON.stringify(this.adminPermission),
                            1
                        );
                        return this.adminPermission[id]?.[fnName] ?? false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.adminPermission[id] = null;
                        return false;
                    });
            } else {
                return this.adminPermission[id]?.[fnName] ?? false;
            }
        },
        fetchHasLender() {
            if (this.hasLender !== null) return this.hasLender;
            return this.axiosIns(
                `/api-site/lender/getActiveLendersByAgentId?agentId=${this.page.ownerId}`
            )
                .then(res => {
                    this.hasLender = !!res?.data?.data?.length;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getSubdomainAgents(){
            return this.axiosIns.get('/api-site/getAgentList').then(res => {
                const data = res?.data?.length
                    ? res.data.map(agent => {
                          agent.disclaimerList = agent.disclaimerList.map(
                              ({ fileName = '', queryLink = '' }) => ({
                                  url: queryLink,
                                  name: fileName
                              })
                          );
                          return agent;
                      })
                    : [];
                this.subdomainAgents = data;
            })
        },
    }
};
