import Queue from './queue';
import Event from './event';

function winScrollTop(val) {
    if (val === undefined) {
        return window.pageYOffset;
    } else {
        window.scrollTo(0, val);
    }
}

const global = {
    timeout(handler, isRemove) {
        return callQueue(initTimeoutQueue(), handler, isRemove);
    },
    scroll(handler, isRemove) {
        return callQueue(initScrollQueue(), handler, isRemove);
    },
    resize(handler, isRemove) {
        return callQueue(initResizeQueue(), handler, isRemove);
    },
    observer(handler, isRemove) {
        return callQueue(initObserver(), handler, isRemove);
    },
    winScrollTop: winScrollTop
};

export default global;

let timeoutQueue, scrollQueue, resizeQueue, observerQueue;

function initTimeoutQueue(delay) {
    if (!timeoutQueue) {
        delay = delay || 226;
        let timeDelay, timeid;

        const fire = function () {
            timeoutQueue.fire();
        };

        const start = function (list) {
            if (list.length) {
                var _delay = Number.MAX_VALUE;
                for (var i = 0, len = list.length; i < len; i++) {
                    _delay = Math.min(_delay, list[i].delay);
                }
                if (!timeid) {
                    timeid = setTimeout(fire, (timeDelay = _delay));
                } else {
                    if (timeDelay > _delay) {
                        clearTimeout(timeid);
                        timeid = setTimeout(fire, (timeDelay = _delay));
                    }
                }
            }
        };
        timeoutQueue = new Queue({
            once: true,
            arguments: function () {
                return { before: {} };
            },
            before(handler, data = {}) {
                let time = data.time;
                if (!time) {
                    time = data.time = new Date().getTime();
                }
                if (time - handler.time >= handler.delay) {
                    handler.time = time;
                    return true;
                }
                return false;
            },
            after(queue) {
                timeid = false;
                start(queue);
            },
            beforeAdd(handler, config = {}) {
                let _delay = parseInt(config.delay, 10);
                Object.assign(handler, {
                    onfinish: config.onfinish,
                    delay: isNaN(_delay) ? delay : _delay,
                    time: new Date().getTime()
                });
            },
            afterAdd(id, queue) {
                start(queue);
            },
            afterClear() {
                if (timeid) {
                    clearTimeout(timeid);
                    timeid = false;
                }
            }
        });
    }
    return timeoutQueue;
}

function initResizeQueue() {
    if (!resizeQueue) {
        resizeQueue = new Queue({ trigger: true });
        Event.on(window, 'resize', resizeQueue);
        // if (window.screen.width > 600) { //PC, PAD need this event 
        // }
    }
    return resizeQueue;
}

function initScrollQueue() {
    if (!scrollQueue) {
        scrollQueue = new Queue({
            trigger: true,
            checkAdd: function (handler) {
                if (!isNaN(handler)) {
                    winScrollTop(handler);
                    return handler;
                } else if (handler === 'fetch') {
                    return winScrollTop();
                }
            },
            arguments: function () {
                return { data: winScrollTop() };
            }
        });
        Event.on(window, 'scroll', scrollQueue);
    }
    return scrollQueue;
}

function initObserver() {
    if (!observerQueue) {
        observerQueue = new Queue({});
        let Observer =
            window.MutationObserver ||
            window.WebKitMutationObserver ||
            window.MozMutationObserver;
        let mo = new Observer(() => {
            global.timeout({
                handler: observerQueue.fire,
                context: observerQueue,
                unique: true
            });
        });
        mo.observe(document.body, {
            subtree: true,
            childList: true
        });
    }
    return observerQueue;
}

function callQueue(queue, handler, remove) {
    if (handler === 'trigger') {
        queue.fire();
    } else if (handler === false) {
        queue.clear();
    } else if (remove === true) {
        queue.remove(handler);
    } else {
        return queue.add(handler);
    }
}
