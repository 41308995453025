export default {
    state: () => ({
        windowSize: {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }),
    actions: {
        // UPDATE_WINDOW_SIZE
        updateWindowSize() {
            this.windowSize = {
                width: window.innerWidth,
                height: window.innerHeight
            };
        }
    }
}