<template>
    <div class="md-goto-top">
        <div
            v-show="show"
            class="btn-top"
            :style="{ bottom: marginBottom }"
            @click="scrollToTop"
        >
            <span class="iconfont icon-arrow-down"></span>
        </div>
    </div>
</template>
<script>
import global from '@/common/global';
import { colorBlock } from '@/modules/defaultSpacing';
export default {
    data: function () {
        return {
            show: false,
            ...colorBlock,
            "noObserve": true,
            "sys_editable": false,
            "sys_deletable": 8,
            "sys_draggable": false,
            "topSpacing": null,
            "btmSpacing": 60,
            "mobileTopSpacing": null,
            "mobileBtmSpacing": 20
        };
    },
    computed: {
        isMobileSize(){
            return this.platform.isMobile;
        },
        marginBottom() {
            return (
                (this.isMobileSize ? this.mobileBtmSpacing : this.btmSpacing) +
                'px'
            );
        }
    },
    mounted() {
        global.scroll(this.onScroll);
    },
    methods: {
        onScroll(scrollY) {
            this.show = scrollY > window.innerHeight;
        },
        scrollToTop() {
            global.scroll(0);
        }
    },
    unmounted() {
        global.scroll(this.onScroll, true);
    }
};
</script>
<style lang="scss">
@import '~@/theme/media.scss';
@import '~@/theme/mixin.scss';

.md-goto-top {
    .btn-top {
        position: fixed;
        z-index: var(--level-sublow, 10);
        // bottom: 18px;
        left: 18px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        cursor: pointer;
        @include color-theme('background-color');
        @include media-800 {
            left: auto;
            right: 18px;
        }
        .icon-arrow-down {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
            color: white;
            font-weight: bold;
            font-size: 18px;
        }
    }
}
</style>
