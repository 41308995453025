export default {
    install: function (app, platform) {
          let platformData = platform || window.siteAppVm?.platformData;
          app.config.globalProperties.platformData = platformData;
          Object.assign( app.config.globalProperties , platformData);
            // app.mixin({
            //     // 初始化一些程序需要的数据, 这些数据在客户端和node端的获取方式存在差异, 例如: hostname, path, ua, axiosIns,等
            //     beforeCreate() {
            //         if (this.$root === this && platform) {
            //             this.platformData = platform;
            //             // 挂在root的
            //             Object.assign(this, this.platformData);
            //         }
            //         else {
            //             // 把数据挂在子组件的this上
            //             Object.assign(
            //                 this,
            //                 (this.$root.platformData || window.siteAppVm?.platformData)
            //             );
            //         }
            //     }
            // });
    }
};
