import util from './util';
export default class Observer {
    constructor() {
        this.listeners = {};
        this.params = {};
    }
    // 用当前参数执行订阅函数
    dispatch(eventName, params) {
        params = util.deepClone(params);
        const listeners = this.listeners[eventName] || [];
        if (!this.params[eventName]) {
            this.params[eventName] = [];
        }
        this.params[eventName].push(params);
        listeners.forEach(listener => {
            listener(params);
        });
    }
    // 订阅
    subject(eventName, fn) {
        if (typeof fn !== 'function') {
            throw new TypeError('fn should be a function');
        }
        if (!this.listeners[eventName]) {
            this.listeners[eventName] = [];
        }
        this.listeners[eventName].push(fn);
        return () => {
            const index = this.listeners[eventName].indexOf(fn);
            this.listeners[eventName].splice(index, 1);
        };
    }
    // 将最后一次记录的参数传入执行，并订阅
    last(eventName, fn) {
        const params = this.params[eventName] || [];
        if (params.length) {
            fn(params[params.length - 1]);
        }
        return this.subject(eventName, fn);
    }
    // 所有记录过的参数，遍历执行当前函数，并订阅
    all(eventName, fn) {
        const params = this.params[eventName] || [];
        params.forEach(data => fn(data));
        return this.subject(eventName, fn);
    }
}
