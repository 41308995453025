import { getStore } from '@/store';

export function getFromDetailPageParam(param = {}) {
    let pageStore = getStore('page');
    let {
        page: { pageKey },
        moduleData
    } = pageStore;
    if (pageKey === 'agent-detail') {
        let agentBannerModule = moduleData.find(
            ({ name }) => name === 'md-agent-banner'
        );
        param.fromDetailPageAgentId =
            agentBannerModule?.data?.agentDetail?.id ?? '';
    }
    return param;
}
