import googleTranslatePlugin from './doGTranslate';

const PAGE_KEY = {
    'chime-blog-detail': true,
    'blog-detail': true,
    blog: true
};

export default (language, globalData) => {
    var pageKey = globalData.pageKey;
    if (!PAGE_KEY[pageKey] || language === 'en') {
        return;
    }
    googleTranslatePlugin({
        value: language,
        retry: 50,
        cb: _ => {
            document.body.translate = false;
        }
    });
    return true;
};
