export default {
    state: () => ({
        curBuildingInfo: {},
        curBuildingHouseInfo: {},
        activeBuildingTab: 'sales',
        curListingDetail: {}, // normal  page to use 
        whiteTeam: false // building  whitelist 
    }),
    getters: {
        isNormalPage(state) {
            //  Determine whether the currently displayed normal Page ，  i.e. no tripartite building information 
            return JSON.stringify(state.curBuildingInfo) == '{}';
        },
        listingDetailInfo(state) {
            return state.curListingDetail || {};
        },
        buildingInfo(state) {
            return state.curBuildingInfo || {};
        },
        buildingHouseInfo(state) {
            return state.curBuildingHouseInfo || {};
        },
        basic(state) {
            if (!this.isNormalPage) {
                let { location, label, buildingName } =
                    state.curBuildingInfo || {};
                if (!location) {
                    return {};
                }
                let [lat, lng] = location.split(',');
                return {
                    location: {
                        lat: +lat,
                        lng: +lng
                    },
                    address: label,
                    name: buildingName || label
                };
            }
            let { address, latitude, longitude } = state.curListingDetail || {};
            if (isNaN(latitude) || isNaN(longitude)) {
                return {};
            }
            return {
                location: {
                    lat: +latitude,
                    lng: +longitude
                },
                address: address,
                name: address
            };
        },
        subwayList(state) {
            return state.curBuildingInfo?.nearestSubway || [];
        }
    },
    actions: {
        // UPDATE_BUILDING_TAB
        updateBuildingTab(activeBuildingTab) {
            this.activeBuildingTab = activeBuildingTab || 'sales';
        },
        // UPDATE_BUILDING_INFO
        updateBuildingInfo(curBuildingInfo) {
            this.curBuildingInfo = curBuildingInfo || {};
        },
        // UPDATE_BUILDING_HOUSE_INFO
        updateBuildingHouseInfo(curBuildingHouseInfo) {
            this.curBuildingHouseInfo = curBuildingHouseInfo || {};
        },
        // SET_LISTING_DETAIL
        setListingDetail(curListingDetail) {
            this.curListingDetail = curListingDetail || {};
        },
        // SET_WHITE_TEAM
        setWhiteTeam(whiteTeam) {
            this.whiteTeam = whiteTeam || false;
        }
    }
};
