// 做缓存，用于后面做前端排序&过滤
const searchByGraphglAgents = async function (params) {
    let {
        pageNum = 1,
        pageSize = 20,
        showAllAgents,
        agentIds,
        autoSort,
        address,
        name,
        customFieldKeys = [],
        includeCalendarLink,
        _t
    } = params;
    agentIds = !showAllAgents && agentIds ? `(ids: "${agentIds}")` : '';
    let res = await this.axiosIns.post('/api-graphql', {
        query: `{agents${agentIds}{id,fullName,position,license,phone,email,externalUrl,longIntroduction,detailUrl,image,address,${
            customFieldKeys.length > 0 ? 'customFields,' : ''
        }${
            includeCalendarLink ? 'calendarLink,' : ''
        }agentSocialNetworks{id,socialNetwork,url}}}`
    });
    let dataList = res.data.agents || [];
    // address
    if (address) {
        dataList = dataList.filter(
            v => v.address.toLowerCase().indexOf(address.toLowerCase()) > -1
        );
    }
    // name
    if (name) {
        dataList = dataList.filter(
            v => v.fullName.toLowerCase().indexOf(name.toLowerCase()) > -1
        );
    }
    // 字母排序
    if (autoSort) {
        dataList = dataList.sort((a, b) => {
            let x = a['fullName'].toLowerCase();
            let y = b['fullName'].toLowerCase();
            return x > y ? 1 : x < y ? -1 : 0;
        });
    }
    // customFields
    customFieldKeys.forEach(customKey => {
        let customInput = params[customKey];
        // console.log('customFields filter: ', customKey, customInput);
        if (customInput && !['All', 'all'].includes(customInput)) {
            let customInputs = customInput.split(',');
            dataList = dataList.filter(({ customFields }) => {
                if (!customFields) return false;
                customFields = JSON.parse(customFields);
                return customFields.some(field => {
                    let { label: fieldLabel, value: fieldValue } = field;
                    if (fieldLabel !== customKey) return false;
                    if (Array.isArray(fieldValue)) {
                        // type=multi-select => fieldValue=['test1','test2'] customInput='test1,test2'
                        return fieldValue.some(v => customInputs.includes(v));
                    } else {
                        // type=single-select / type=input => fieldValue='1' customInput='1'
                        return fieldValue.indexOf(customInput) > -1;
                    }
                });
            });
        }
    });
    // 分页
    let total = dataList.length;
    let pages = Math.ceil(total / pageSize);
    return Promise.resolve({
        data: {
            dataList: dataList.slice(
                (pageNum - 1) * pageSize,
                pageNum * pageSize
            ),
            total,
            pages,
            pageNum,
            pageSize
        }
    });
};

// 后端接口搜索排序&过滤
const searchByCondition = function (params) {
    let { autoSort, address, name, pageNum, pageSize } = params;
    let reqParams = { address, name, pageNum, pageSize };
    autoSort && (reqParams.sort = 'FULL_NAME_ASC');
    return this.axiosIns.get('/api-site/agent/search/condition', {
        params: reqParams
    });
};

const handleAgentList = (response, { useExternalUrl }) => {
    let { dataList } = response.data;
    response.data.dataList = dataList.filter(a => {
        if (!a.image) {
            a.image =
                'https://cdn.chime.me/image/fs/sitebuild/2019916/23/original_96b7f1a8-9f97-424d-852a-ae5214ba95e9.png';
        }
        //  Priority use agent  of External Url
        if (useExternalUrl && a.externalUrl) {
            a.detailUrl = a.externalUrl;
        }
        if (!a.detailUrl) {
            a.detailUrl = '/agent/detail/' + a.id;
        }

        a.agentSocialNetworks = sortAgentSocialNetworks(a.agentSocialNetworks);

        return true;
    });
    return response;
};

export const sortAgentSocialNetworks = list => {
    if (!Array.isArray(list)) {
        return list;
    }

    const sortOrders = [
        'facebook',
        'linkedin',
        'twitter',
        'zillow',
        'yelp',
        'instagram',
        'youtube',
        'snapchat',
        'pinterest',
        'angelist',
        'google+',
        'whatsapp',
        'redfin'
    ];

    return list.sort((socNetA, socNetB) => {
        if (socNetA?.socialNetwork && socNetB?.socialNetwork) {
            const socNetA_name = socNetA.socialNetwork.toLowerCase();
            const socNetB_name = socNetB.socialNetwork.toLowerCase();
            const socNetA_order = sortOrders.indexOf(socNetA_name);
            const socNetB_order = sortOrders.indexOf(socNetB_name);

            //Only 'A' is defined in the sortOrders
            if (socNetA_order && socNetB_order < 0) {
                return 1;
            }

            //Only 'B' is defined in the sortOrders
            if (socNetB_order && socNetA_order < 0) {
                return -1;
            }

            //both are undefined, sort by social network name instead
            if (!socNetA_order && !socNetB_order) {
                if (socNetA_name < socNetB_name) {
                    return -1;
                }
                if (socNetA_name > socNetB_name) {
                    return 1;
                }
                return 0;
            }

            //both are defined
            return socNetA_order - socNetB_order;
        }
        return 0;
    });
};

export const searchAgents = async function (params) {
    let {
        showAllAgents,
        agentIds,
        customFieldKeys,
        useExternalUrl,
        useSwiperApiParams,
        pageSize
    } = params;
    params.pageSize = useSwiperApiParams ? 100 : pageSize; // 轮播图虚拟swiper未应用前，最多展示100条
    let response = null;
    // customFieldKeys: md-agent-search
    // agentIds: Designated agents
    if (customFieldKeys || (!showAllAgents && agentIds)) {
        response = await searchByGraphglAgents.call(this, params);
    } else {
        response = await searchByCondition.call(this, params);
    }
    return Promise.resolve(handleAgentList(response, { useExternalUrl }));
};

export const formatReviews = function(reviews){
    return reviews.map(item=>{
        if(item.extraContent){
            let info = JSON.parse(item.extraContent);
            let infoKeys = Object.keys(info);
            if(infoKeys.length){
                item.rmaTrackPixelUrl = info[infoKeys[0]];
            }
        }
        return item;
    })
}