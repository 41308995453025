import {getCurrentInstance, reactive } from "vue";
import util from "@/common/util"
const weakMap = new WeakMap();
export default (data) => {
    const vm = getCurrentInstance();
    let chimeSetupState = weakMap.get(vm);
    if (data){
        const state = reactive( util.deepClone(data) );
        if (chimeSetupState){
            Object.assign(chimeSetupState, state);
        }
        else {
            chimeSetupState = state
        }
    }
    else if (!chimeSetupState){
        chimeSetupState = reactive({});
    }
    weakMap.set(vm, chimeSetupState);
    return chimeSetupState;
}

