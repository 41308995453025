<template>
    <pop
        :showMask="true"
        :allowClose="true"
        class="new-register-phone-valid-pop"
        ref="phoneValidRef"
        :onClose="afterCloseCallback"
    >
        <div class="new-register-phone-valid-container">
            <PhoneValid
                v-if="firstStep"
                :title="title"
                :phoneNumber="phoneNumber"
                @nextStep="nextStep"
                @handleSkip="handleSkip"
            ></PhoneValid>
            <FinishPage
                v-if="secondStep"
                :title="title"
                :btnText="$t('continue')"
                @finish="finish"
            >
            </FinishPage>
        </div>
    </pop>
</template>

<script>
import { mapState as rootMapState } from 'pinia';
import { useUserStore } from '@/store';
import PhoneValid from './components/phoneValid.vue';
import FinishPage from './components/finishPage.vue';
import { adNewQuestions } from '@/components/adQuestionsPop/index.js';
export default {
    i18next: {
        ns: 'common'
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        leadType: {
            type: String,
            default: ''
        },
        currentStep: {
            type: [String, Number],
            default: 1
        },
        afterClose: {
            type: Function,
            default: null
        },
        isFromRegister: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            step: 1
        };
    },
    components: {
        PhoneValid,
        FinishPage
    },
    computed: {
        ...rootMapState(useUserStore, {
            user: store => store.user
        }),
        phoneNumber() {
            return this.user?.phoneNumber;
        },
        firstStep() {
            return (
                this.step == 1 &&
                this.phoneNumber &&
                !this.onlyDisplayFinishPage
            );
        },
        secondStep() {
            return this.step == 2 || this.onlyDisplayFinishPage;
        },
        onlyDisplayFinishPage() {
            return this.currentStep == 2 || !this.phoneNumber;
        }
    },
    watch: {
        currentStep(newVal) {
            if (newVal) {
                this.step = newVal;
            }
        }
    },
    mounted() {
        this.step = this.currentStep;
        !this.isFromRegister && adNewQuestions.init();
    },
    methods: {
        nextStep() {
            this.step = 2;
        },
        handleSkip() {
            if (this.isFromRegister) {
                this.nextStep();
            } else {
                this.close();
                localStorage.removeItem('__register__ad_questions');
                adNewQuestions.openAdQuestionPop();
            }
        },
        close() {
            this.$refs?.phoneValidRef.close?.();
        },
        afterCloseCallback() {
            localStorage.setItem('__register__ad_questions', Date.now());
            if (this.afterClose && typeof this.afterClose == 'function') {
                this.afterClose();
            }
        },
        finish() {
            this.close();
            if (this.leadType != 'Seller') {
                localStorage.removeItem('__register__ad_questions');
                adNewQuestions.openAdQuestionPop();
            }
        }
    }
};
</script>

<style lang="scss" scope>
@import '@/theme/mixin.scss';
.new-register-phone-valid-pop {
    .new-register-phone-valid-container {
        width: 400px;
        min-height: 600px;
        box-sizing: border-box;
        padding: 0 30px;
        margin: 0 auto;
        overflow: hidden;
        background-color: #ffffff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        @include media-600 {
            width: 100%;
            padding: 0 15px;
        }
    }
    .register-finish-page {
        min-height: 600px;
    }
    .pop {
        @include media-600 {
            width: calc(100% - 30px);
        }
        .icon-close {
            top: 15px;
            right: 20px;
        }
    }
}
</style>
