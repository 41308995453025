import axios from 'axios';
// import apiRobot from './api-robot';
import {
    paramsSerializer,
    paramsSerializerV2,
    utilTool,
    cmsEnv
} from '../../common/utils';

// create unique key value
function buildKey(url, params = {}) {
    params = {
        ...params
    }
    if (url.indexOf('?') !== -1) {
        let strParams = url.substr(url.indexOf('?') + 1);
        url = url.substr(0, url.indexOf('?'));
        strParams = decodeURIComponent(strParams).split('&');
        for (let i = 0, len = strParams.length; i < len; i++) {
            const nv = strParams[i].split('=');
            params[nv[0]] = nv[1];
        }
    }
    const sortedParams = Object.keys(params)
        .sort()
        .reduce((result, key) => {
            result[key] = params[key];
            return result;
        }, {});
    const key = url + `-${JSON.stringify(sortedParams)}`;
    return key;
}

//request data cache
const requestCache = (window.requestCache = {
    data: {},
    requestPromise: {},
    requestResolve: {},
    requestReject: {},
    deletePromise(key) {
        delete requestCache.requestPromise[key];
        delete requestCache.requestResolve[key];
        delete requestCache.requestReject[key];
    },
    set(key, data, isError = false) {
        if (!isError) {
            this.data[key] = utilTool.deepClone(data);
        }
        if (requestCache.requestResolve[key]) {
            requestCache.requestResolve[key](JSON.parse(JSON.stringify(data)));
            requestCache.deletePromise(key);
        }
    },
    get(key, isUrl = false) {
        if (isUrl) {
            const urlKey = buildKey(key);
            return this.data[urlKey];
        } else {
            return this.data[key];
        }
    },
    delete(key, isUrl = false) {
        if (isUrl) {
            const urlKey = buildKey(key);
            delete this.data[urlKey];
        } else {
            delete this.data[key];
        }
    },
    clear() {
        this.data = {};
    }
});

function formatGraphQlQuery(query) {
    let reg = /{|}/gm,
        fmt = [],
        rt,
        from = -1,
        count = 0;
    query = query.replace(/(\:[^(]+)?\(([^")]+|(\"[^"]*\"))+\)|\r?\n|,/gm, '');
    rt = reg.exec(query);
    while (rt) {
        count += rt[0] === '{' ? 1 : -1;
        if (count === 1) {
            from = rt.index + 1;
        } else if (from !== -1) {
            let str = query.substring(from, rt.index).trim();
            if (str) {
                fmt.push(str);
            }
            from = -1;
        }
        rt = reg.exec(query);
    }
    return fmt.join('-');
}
var cancelMap = {};

/**
 * config参数如下
 * noCache: 强制不使用缓存
 * cacheKey: 设置了这个值的请求, 会缓存数据
 * cancelKey: 设置了这个值的请求就不会走缓存, 在首次正常发送, 第二次发送请求的时候会把上一次的请求cancel掉
 */
const defaultsAdapter = axios.defaults.adapter,
    regGraphQL = /\/api-graphql/,
    regMutation = /mutation/;
axios.defaults.adapter = config => {
    let configData = config.data;
    if (typeof configData === 'string') {
        configData = JSON.parse(configData);
    }
    // if (regGraphQL.test(config.url)) {
    //     config.url = '/api-graphql/' + formatGraphQlQuery(configData.query);
    // }

    // 默认情况下, 会对get请求, graphql获取数据请求, 设置了cacheKey的请求做缓存处理
    if (
        !cmsEnv.inCms &&
        !config.noCache &&
        !config.cancelKey &&
        (config.method === 'get' ||
            config.cacheKey ||
            (regGraphQL.test(config.url) &&
                !regMutation.test(configData.query)))
    ) {
        let requestId =
            typeof config.cacheKey === 'string'
                ? config.cacheKey
                : buildKey(config.url, config.params || configData);
        config.requestId = requestId;
        const data = requestCache.get(requestId);
        if (data) {
            return Promise.resolve(JSON.parse(JSON.stringify(data)));
        } else if (requestCache.requestPromise[requestId]) {
            return requestCache.requestPromise[requestId];
        } else {
            requestCache.requestPromise[requestId] = new Promise(
                (resolve, reject) => {
                    requestCache.requestResolve[requestId] = resolve;
                    requestCache.requestReject[requestId] = reject;
                }
            );
        }
    }
    return defaultsAdapter(config);
};
axios.defaults.timeout = 20000;

axios.interceptors.request.use(
    config => {
        let { url, cancelKey, data={}, method } = config;
        if (url.startsWith('/') && window.LFP) {
            let headers = window.LFP.init(null, {
                url,
                data,
                contentType: config.headers['Content-Type'] || 'application/x-www-form-urlencoded; charset=UTF-8',
                type: method,
            });
            Object.assign(config.headers, headers);
        }

        let globalData = window.sitePrepareData();
        if (!globalData.widget && globalData.shortCode) {
            const prefix = '/chime-widget';
            config.baseUrl = prefix;
        }


        if (cancelKey) {
            if (cancelMap[cancelKey]) {
                cancelMap[cancelKey].cancel();
            }
            cancelMap[cancelKey] = axios.CancelToken.source();
            config.cancelToken = cancelMap[cancelKey].token;
        }

        // mls Listing review interface
        let isSkipCheckPage = [
            'sold-compliance-check',
            'active-compliance-check',
            'listing-for-cms'
        ].includes(globalData.pageKey);
        if (/^\//.test(url) && (isSkipCheckPage || cmsEnv.inCms)) {
            //cdn document , resource , Shouldn't be wearing this head
            config.headers['Including-Inactive-Mls'] = true;
        }
        if (
            (/\/search\/realTimeListings/.test(url) ||
                /\/search\/neighborAvg/.test(url)) &&
            Array.isArray(config.params && config.params.avgTypes)
        ) {
            config.paramsSerializer = paramsSerializerV2;
        } else {
            config.paramsSerializer = paramsSerializer;
        }
        if (/^\//.test(url)) {
            let { siteId, dataId, isLandingPage, pageId } = globalData;
            if (siteId) {
                config.headers.CURRENTSITEID = siteId;
            }
            if (dataId) {
                config.headers.CURRENTDATAID = dataId;
            }
            //AM-281068
            if (isLandingPage && pageId) {
                config.headers.CURRENTPAGEID = pageId;
            }
            config.headers['site-search-listings'] = true;
        }

        return config;
    },
    error => {
        console.error(error);
    }
);
axios.interceptors.response.use(
    res => {
        let { requestId, data: resConfigData } = res?.config || {};
        if (typeof resConfigData === 'string') {
            resConfigData = JSON.parse(resConfigData);
        }
        if (requestId) {
            if (!requestCache.get(requestId)) {
                requestCache.set(requestId, res);
            } else {
                res = JSON.parse(JSON.stringify(res));
            }
        }
        if (res.data) {
            if (typeof res.data === 'object' && !res.data.__config__) {
                Object.defineProperty(res.data, '__config__', {
                    get() {
                        return res.config;
                    },
                    enumerable: false
                });
            }
            if (
                res.data.status &&
                typeof res.data.status == 'object' &&
                res.data.status.code !== 0
            ) {
                console.error('Response Error', res.config.url, 'error');
                return Promise.reject({
                    error: false,
                    errorInfo: res.data.status.msg || '',
                    code: res.data.status.code
                });
            }
            console.log(res.config.url, 'Success', 'info');
            return res.data;
        } else if (
            //  This logic fixes the problem that the image upload does not return a result, which leads to a mistaken failure , refer to  cms  interceptor
            /aws/.test(res.config.url) &&
            res.config.method === 'put' &&
            res.status === 200
        ) {
            if (typeof res.data === 'object') {
                res.data.__config__ = res.config;
            }
            return res.data;
        }

        console.error('Response Error', res.config.url, 'error');
        return Promise.reject({
            error: false,
            errorInfo: res
        });
    },
    error => {
        let config = error.config || {};
        const { requestId } = config;
        if (axios.isCancel(error)) {
            requestId && requestCache.deletePromise(requestId);
            console.error('Request Canceled', error.message || '', 'error');
            return Promise.reject({
                error: false,
                errorInfo: 'Request canceled: ' + error.message || ''
            });
        } else {
          
            if (requestId) {
                requestCache.requestReject[requestId]?.(error);
                requestCache.deletePromise(requestId);
            }
            console.error(
                'Response Error',
                `${config.url} [${config.method}]`,
                'error'
            );
            return Promise.reject({
                error: true,
                errorInfo: error
            });
        }
    }
);
// const instance = axios.create({});
window.siteAxios = axios;
export default axios;
