import { createApp, defineAsyncComponent } from 'vue';
import plugin from '@/plugins';
import util from '@/common/util';
import advRegister from '@/components/sign-log/advertising-register/register.js';
import { getStore } from '../../store';
import initCookieStatus, { setCookieEmptyOpen } from "./cookieStatus";

const COOKIE_AUTHORITY_KEY = 'COOKIE_AUTHORITY_QUERY';
const themeMap = {
    standard: defineAsyncComponent(() =>
        import(
            /* webpackChunkName: "common/cookie-authority/standard" */ './theme/standard.vue'
        )
    )
};


/**
 *  condition : 1.  not logged in  2.  not asked  3.  No AD
 */
export default function () {


    initCookieStatus({util});

    return new Promise((resolve)=>{
        if (util.isScreenshot){
            resolve()
            return
        }
        const pageStore = getStore('page');
        const isCmsListing = window.location.href.includes('/listing-for-cms');
        //  Exclude Ad Pages   and  cms
        const isAd = /x_ads_source/.test(location.search);
        const globalData = pageStore.page;
        if (isAd || util.inCms || isCmsListing) {
            setCookieEmptyOpen(util);
            resolve()
            return
        }
        if (
            globalData._disableCookieAuthority ||
            (advRegister.isShow(globalData) && globalData.isLandingPage)
        ) {
            setCookieEmptyOpen(util);
            resolve()
            return
        }
    
        let hasQerried = localStorage.getItem(COOKIE_AUTHORITY_KEY);
        const isSitePrivacy = location.href.includes(
            '/site/privacy-terms#privacy-policy'
        );
    
        if (hasQerried == '0' && !isSitePrivacy) {
            hasQerried = '';
            localStorage.removeItem(COOKIE_AUTHORITY_KEY);
        }
        getStore('user')
            .getUser({
                checkOtherIframe: true,
                fromGlobal: true
            })
            .then(isLogin => {
                const { template } = globalData || {};
                if (
                    !isLogin &&
                    (!hasQerried || (hasQerried == '0' && isSitePrivacy))
                ) {
                    let cookieAuthority = createApp(
                        themeMap[template] || themeMap['standard'],
                        {
                            hasQerried,
                            onDestroy: () => {
                                if (cookieAuthority) {
                                    cookieAuthority.unmount();
                                    cookieAuthority = null;
                                }
                                resolve()
                            }
                        }
                    );
                    plugin.baseInstall(cookieAuthority, true);
                    cookieAuthority.mountSelf();
                }else{
                    setCookieEmptyOpen(util);
                    resolve()
                }
            });
    })

}
