import util from './util';

function formatTimeStamp(date, year) {
    let [dateTime, timeStamp] = date.split(',');
    let t12s = [];
    if (dateTime && timeStamp) {
        let result = timeStamp.replace(/([^\-\s])+/g, function (match) {
            let timesPm = match.match(/([\s\S]+)pm/);
            let timesAm = match.match(/([\s\S]+)am/);
            let formatTime = '';
            if (timesAm && timesAm[1]) {
                let [hour, minute] = timesAm[1].split(':');
                t12s.push(hour+'AM');
                if (hour == 12) {
                    hour = 0;
                }
                let amTime = Number(hour) + ':' + minute;
                formatTime = [dateTime, year, amTime].join(' ');   
            }

            if (timesPm && timesPm[1]) {
                let [hour, minute] = timesPm[1].split(':');
                t12s.push(hour+'PM');
                if (hour == 12) {
                    hour = 0;
                }
                let pmTime = 12 + Number(hour) + ':' + minute;
                formatTime = [dateTime, year, pmTime].join(' ');
            }

            return formatTime;
        });

        result = result.split('-');

        return [{
            time: result[0],
            t12: t12s[0]
        }, {
            time: result[1],
            t12: t12s[1]
        }];
    } else {
        return [];
    }
}

function convert24to12(time) {
    let date = new Date(time);

    let hours = date.getHours();
    let minutes = date.getMinutes();

    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    // fill zero
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
}

function formatDescription(info, external) {
    let houseType = '';
    if (info.openHouseFlag == 1) {
        houseType = 'open house';
    } else if (external.isTour3d) {
        houseType = 'virtual tour';
    }

    // prettier-ignore
    return `Join us for an exciting ${houseType} event at ${external.streetCityName}.`;
}

function getEvent(info, external) {
    const streetCityName =
        info.address || [info.streetAddress, info.cityAddress].join();

    const price = info.soldPrice || info.price || 0;
    const url = external.url;

    const priceCurrency = external.currencyAbbr || 'USD';
    const isSold = Boolean(info.soldDateStr);
    const availability = isSold
        ? 'http://schema.org/SoldOut'
        : 'http://schema.org/InStock';
    const image =
        info.previewPicture ||
        (info.listingPictures && info.listingPictures.split('|')[0]);

    let createYear = new Date().getFullYear();
    if (info.createDate) {
        createYear = new Date(info.createDate).getFullYear();
    }
    let agentCreator = external.agentCreator || {};

    let startTime = '';
    let endTime = '';

    let eventName = '';
    let eventAttendanceMode = 'https://schema.org/OfflineEventAttendanceMode';
    let locationInfo = {
        '@type': 'Place',
        '@context': 'http://schema.org',
        name: streetCityName,
        address: {
            '@type': 'PostalAddress',
            '@context': 'http://schema.org',
            streetAddress: streetCityName,
            postalCode: info.zipCode,
            addressLocality: info.city,
            addressRegion: info.state
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: info.latitude,
            longitude: info.longitude
        }
    };

    if (info.openHouseFlag == 1) {

        if (info.openHouseSchedule) {
            let formatTime = formatTimeStamp(
                info.openHouseSchedule,
                createYear
            );
            startTime = formatTime[0];
            endTime = formatTime[1];
        } else if (info.openHouseSchedules) {
            let schedule = '';
            if (util.isArray(info.openHouseSchedules)) {
                schedule = info.openHouseSchedules[0];
            } else {
                schedule = info.openHouseSchedules.split('|')[0];
            }
            let formatTime = formatTimeStamp(schedule, createYear);
            startTime = formatTime[0];
            endTime = formatTime[1];
        }

        if (startTime && endTime) {
            eventName = 'Open House - ' + startTime.t12 + '-' + endTime.t12;
            startTime = startTime.time;
            endTime = endTime.time;
            if (new Date(startTime).toString() == 'Invalid Date') {
                console.log('time invalid');
                console.log(startTime, endTime, info);
                return null;
            }
        } else {
            if (info.openHouseSchedulesStart) {
                startTime = info.openHouseSchedulesStart.replace(/\-/g, '/');
            }

            if (info.openHouseSchedulesEnd) {
                endTime = info.openHouseSchedulesEnd.replace(/\-/g, '/');
            }

            eventName = 'Open House - '+convert24to12(startTime)+'-'+convert24to12(endTime);
        }
    } else if (external.isTour3d) {
        eventAttendanceMode = 'https://schema.org/OnlineEventAttendanceMode';
        eventName = '3D Tour Available ' + streetCityName;
        locationInfo = {
            '@type': 'VirtualLocation',
            url
        };
        startTime = new Date().toString();
        endTime = new Date().toString();
    }

    external = Object.assign(external, {
        streetCityName: streetCityName,
        price: price
    });

    var res = {
        '@type': 'Event',
        '@context': 'https://schema.org',
        name: eventName,
        startDate: new Date(startTime),
        endDate: new Date(endTime),
        url,
        description: formatDescription(info, external),
        image,
        eventAttendanceMode: eventAttendanceMode,
        eventStatus: 'https://schema.org/EventScheduled',
        offers: {
            '@type': 'Offer',
            price,
            priceCurrency,
            url,
            availability,
            validFrom: startTime
        },
        organizer: {
            '@type': 'Organization',
            name: info.agentOrganizationName,
            url
        },
        location: locationInfo,
        performer: {
            '@type': 'Person',
            name: info.agentName || agentCreator.fullName
        }
    };

    if (
        Number.isNaN(res.startDate.getTime()) ||
        Number.isNaN(res.endDate.getTime())
    ) {
        return null;
    } else {
        return res;
    }
}

function getSingleFamilyResidence(info, url, country) {
    const streetCityName = [info.streetAddress, info.cityAddress].join();
    return {
        '@type': 'SingleFamilyResidence',
        name: streetCityName,
        url,
        address: {
            '@type': 'PostalAddress',
            streetAddress: info.streetAddress,
            addressLocality: info.city,
            addressRegion: info.state,
            postalCode: info.zipCode,
            addressCountry: country
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: info.latitude,
            longitude: info.longitude
        },
        numberOfRooms: info.bedrooms
    };
}

/**
 *
 * @param {listingDetail} info
 * @param {option:{country}} param1
 * @returns schema.org info used by search engine
 */
function getListingDetailSchemaInfo(info, external) {
    let { location, country } = external;
    const url = location.origin + location.pathname;
    external.url = url;

    let chime3DTour = info.chime3DTourFlag == 'Yes' || info.tour3D;
    let schemaInfo = null;
    let eventInfo = null;
    if (chime3DTour || info.openHouseFlag == 1) {
        external.isTour3d = chime3DTour;
        eventInfo = getEvent(info, external);
    }

    if (eventInfo) {
        schemaInfo = Object.assign(
            {
                ...eventInfo
                // ...getSingleFamilyResidence(info, url, country)
            },
            // { '@type': ['Event', 'SingleFamilyResidence'] }
            { '@type': 'Event' }
        );
    } else {
        // schemaInfo = Object.assign(
        //     {
        //         ...getSingleFamilyResidence(info, url, country)
        //     },
        //     { '@type': 'SingleFamilyResidence' }
        // );
    }

    return schemaInfo ? [schemaInfo] : null;
}

function getHouseSchemaInfo(info, external) {
    let { country, url, location } = external;
    url = `${location.origin}${url}`;
    external.url = url;

    let chime3DTour = info.chime3DTourFlag == 'Yes' || info.tour3D;
    let schemaInfo = null;
    let eventInfo = null;
    if (chime3DTour || info.openHouseFlag == 1) {
        external.isTour3d = chime3DTour;
        eventInfo = getEvent(info, external);
    }

    if (eventInfo) {
        schemaInfo = [
            // getSingleFamilyResidence(info, url, country),
            eventInfo
        ];
    } else {
        // schemaInfo = [getSingleFamilyResidence(info, url, country)];
    }

    return schemaInfo;
}

function getVideoSchemeInfo({ embedUrl, thumbnailUrl, duration }) {
    var res = {
        embedUrl,
        thumbnailUrl:
            thumbnailUrl ||
            'https://static.chimeroi.com/servicetool-temp/202397/1/lofty-logo/Lofty_Logo.png',
        name: 'lofty video',
        description: 'lofty video',
        uploadDate: '2023-03-29 13:08:35',
        expires: '2023-04-03 23:59:59',
        '@type': 'VideoObject'
    };
    if (duration) {
        res.duration = `PT${duration}S`;
    }
    return res;
}

export { getListingDetailSchemaInfo, getHouseSchemaInfo, getVideoSchemeInfo };
