export const REG_EMAIL = () =>
    /^([a-z0-9]+\+)?[a-z0-9\.\'\-_]+@[a-z0-9\-_]+\.[a-z0-9\.\-]+$/i;

//  Obtain  script  labeled   Content 
export const GET_SCRIPT_CONTENT = () => /<script.*?>([\s\S]+?)<\/script>/gim;

export const escapeReg = /&(?:lt|gt|quot|amp|#39|#10|#9|nbsp);/;

export const escapeMap = {
    '&nbsp;': ' ',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&amp;': '&',
    '&#10;': '\n',
    '&#9;': '\t',
    '&#39;': "'"
};
