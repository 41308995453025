import util from "../util"
import {getCookieStatusSyncBoolean} from '@/components/cookieAuthority/cookieStatus.js';
export default async(globalData)=> {
    if(globalData?.track?.adwerxUrl && !util.isSSRServer){
        let status = await getCookieStatusSyncBoolean({
            util,
        });
        if(!status){
            return ;
        }
        if([window.location.pathname, globalData.pageKey].includes(globalData.track.pageUrlForAdwerx) || !globalData.track.pageUrlForAdwerx){
            util.addScript(globalData.track.adwerxUrl)
        }
    }
}