function getLanguageMonth(locale) {
    const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
    return Array.from({ length: 12 }, (_, mon) =>
        formatter.format(new Date(1970, mon, 1))
    );
}
function getLanguageSimpleMonth(locale) {
    const formatter = new Intl.DateTimeFormat(locale, { month: 'short' });
    return Array.from({ length: 12 }, (_, mon) =>
        formatter.format(new Date(1970, mon, 1))
    );
}
function getLanguageWeek(locale) {
    const base = new Date(1970, 0, 1).getTime();
    const formatter = new Intl.DateTimeFormat(locale, { weekday: 'short' });
    return Array.from(
        { length: 7 },
        (_, day) => formatter.format(new Date(base + 3600000 * 24 * (-4 + day))) // 1970.1.1  it's thursday 
    );
}

export default {
    weekNames(state){
        return getLanguageWeek(state.currentLanguage);
    },
    monthSimpleNames(state){
        return getLanguageSimpleMonth(state.currentLanguage);
    },
    monthNames(state){
        return getLanguageMonth(state.currentLanguage);
    }
}