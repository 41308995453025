import { getNsUrl } from '../globalization';
import { isSSRServer } from '@/config';

async function recordNs(app, lng, nsArr) {
    if (!app.lngNsDetail) {
        app.lngNsDetail = {
            lng,
            ns: {}
        };
    }
    for (let i = 0; i < nsArr.length; i++) {
        let ns = nsArr[i];
        if (!app.lngNsDetail.ns[ns]) {
            app.lngNsDetail.ns[ns] = await getNsUrl(lng, ns);
        }
    }
}

function addVm(globalizationIns, app) {
    let ns = this.$options.i18next?.ns;
        // translationResourcesLoaded = this.$options.i18next?.translationResourcesLoaded;
    if (!ns) {
        return;
    }
    let nsArray = Array.isArray(ns) ? ns : [ns];
    return globalizationIns.loadNs(nsArray).then(async () => {
        if (app) {
            await recordNs(app, globalizationIns.lng, nsArray);
        }
    });
    // }).finally(async _ => {
    //     await translationResourcesLoaded?.call(this);
    // });
}

function install(app, globalizationIns) {
    app.mixin({
        // i18next: {
        //     // If using an array ， Different namespaces will have the problem of overriding attributes with the same name
        //     ns: ''
        // },
        [isSSRServer ? 'serverPrefetch' : 'created']() {
            if (isSSRServer) {
                return addVm.call(this, globalizationIns, app);
            } else {
                return addVm.call(this, globalizationIns);
            }
        },
        // beforeDestory() {
        //     vueNsManager.removeVm(this);
        // },
        methods: {
            $t(key, ...args) {
                if (!key) {
                    return;
                }
                const nsKey = key.split(':');
                if (nsKey.length > 1) {
                    //key with namespace
                    return globalizationIns.$t(key, ...args);
                }

                if(!this.$options.i18next){
                    console.error(`Please provide $options i18next : ${this.$options.name || ''}   ${this.$options.__file || ''}  `)
                }
                return globalizationIns.$st(
                    this.$options.i18next.ns,
                    key,
                    ...args
                );
            },
            onLngNsLoaded(ns){
                return globalizationIns.onLngNsLoaded(ns);
            }
        }
    });
}

export default install;
