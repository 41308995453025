import utilTool from './utils/util-tool';
import types from './type';

export default function (config) {
    function indexOf(list, handler, context) {
        if (handler.handler) {
            context = handler.context || context;
            handler = handler.handler;
        }
        for (let i = 0, len = list.length; i < len; i++) {
            let opt = list[i];
            if (opt.id === handler) {
                return i;
            }
            if (opt.handler === handler) {
                if (!opt.context || opt.context === context) {
                    return i;
                }
            }
        }
        return -1;
    }

    function parseBool(b, db) {
        return (types.isNull(b) ? db : b) === true;
    }

    config = config || {};

    let queue = [],
        firing = false,
        fireIdx = null,
        fireLen = null,
        id = 1;

    return {
        add(handler) {
            let ck = utilTool.call(config.checkAdd, this, handler);
            if (!types.isNull(ck)) return ck;
            if (!handler) return;
            let trigger = false,
                options;
            if (types.isFunction(handler)) {
                trigger = parseBool(config.trigger);
                handler = {
                    id: ++id,
                    handler: handler,
                    once: parseBool(config.once),
                    single: false
                };
            } else {
                handler = (options = handler).handler;
                let args = options.args;
                if (!types.isArray(args)) args = args ? [args] : [];
                if (options.unique) {
                    this.remove(handler, options.context);
                }
                trigger = parseBool(options.trigger, config.trigger);
                handler = {
                    id: ++id,
                    handler: handler,
                    context: options.context,
                    args: args,
                    once: parseBool(options.once, config.once),
                    single: options.single === true
                };
            }
            if (utilTool.call(config.beforeAdd, this, handler, options) !== false) {
                if (trigger) {
                    if (
                        utilTool.call(
                            handler,
                            this,
                            (utilTool.call(config.arguments, this) || {}).data
                        ) === false
                    ) {
                        utilTool.call(handler.onfinish, handler.context);
                        return;
                    }
                }
                queue.push(handler);
                utilTool.call(config.afterAdd, this, id, queue);
                return id;
            }
        },
        remove(handler, context) {
            if (!handler) return;
            let idx = indexOf(queue, handler, context);
            if (idx !== -1) {
                queue.splice(idx, 1);
                if (firing) {
                    fireLen--;
                    if (idx <= fireIdx) fireIdx--;
                } else if (queue.length === 0) {
                    utilTool.call(config.afterClear, this);
                }
            }
        },
        clear() {
            queue.length = 0;
            utilTool.call(config.afterClear, this);
        },
        fire() {
            if (firing) return;
            firing = true;
            fireIdx = 0;
            fireLen = queue.length;
            let args = utilTool.call(config.arguments, this) || {},
                beforeArgs = args.before,
                data = args.data === undefined ? arguments[0] : args.data;
            while (fireIdx < fireLen) {
                var t = queue[fireIdx];
                if (utilTool.call(config.before, this, t, beforeArgs) !== false) {
                    if (utilTool.call(t, this, data) === false || t.once) {
                        utilTool.call(t.onfinish, t.context);
                        var idx = queue.indexOf(t);
                        if (idx !== -1) {
                            // here need to re- indexOf find location ， Otherwise in callback called in remove will cause delete error after 
                            queue.splice(idx, 1);
                            fireLen--;
                            fireIdx--;
                        }
                    }
                    if (t.single) break;
                }
                fireIdx++;
            }
            firing = false;
            utilTool.call(config.after, this, queue, data);
        }
    };
}
