<template>
    <div
        v-if="!isTitleEmpty"
        class="site-subtitle"
        :class="align"
        :style="style"
        v-html="formatFontSize(title, size)"
    ></div>
</template>

<script>
import util from '@/common/util';
export default {
    name: 'site-sub-title',
    props: {
        title: [String],
        size: {
            type: Number,
            default: '--mg-sub-title-size'
        },
        mbSize: {
            type: Number,
            default: '--mg-sub-title-size-mobile'
        },
        align: {
            validator: value =>
                ['left', 'right', 'center'].indexOf(value) !== -1,
            default: ''
        }
    },
    computed: {
        isTitleEmpty() {
            return util.isEmptyRichtext(this.title);
        },
        titleSize(){
            return this.platform.isMobile ? this.mbSize : this.size;
        },
        style(){
            return {
                'font-size': typeof this.titleSize === 'number' 
                    ? `${this.titleSize}px` 
                    : `var(${this.titleSize})`
            }
        }
    }
};
</script>

<style lang="scss">
@import '../../../theme/media';

.site-subtitle {
    font-family: var(--mg-sub-title-font);
    font-size: var(--mg-sub-title-size);
    color: var(--g-text-color);
    text-align: var(--mg-sub-title-align);
    line-height: var(--mg-sub-title-line-height);   
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    &.left{
        text-align: left;
    }
    &.center{
        text-align: center;
    }
    &.right{
        text-align: right;
    }
    @include media-600 {
        font-size: var(--mg-sub-title-size-mobile);
        line-height: var(--mg-sub-title-line-height-mobile);
    }
    h1,h2,h3,h4,h5,h6{
        margin: 0;
        padding: 0;
        line-height: inherit;
    }
}

</style>
