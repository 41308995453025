<template>
    <div class="register-finish-page">
        <div class="finish-page-content">
            <site-img
                class="finish-page-img"
                :src="finishPageImg"
                alt=""
                dir="height"
                style="height: 60px"
            ></site-img>
            <slot name="title">
                <div
                    class="finish-page-title"
                    v-if="finishedTitle"
                    v-html="finishedTitle"
                ></div>
                <div
                    class="finish-page-subTitle"
                    v-if="finishedSubTitle"
                    v-html="finishedSubTitle"
                ></div>
            </slot>
        </div>
        <site-button
            v-if="finishedBtnText"
            type="normal"
            :html="finishedBtnText"
            class="finish-page-button"
            @click="finish"
        ></site-button>
    </div>
</template>

<script>
export default {
    i18next: {
        ns: 'common'
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        btnText: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            finishPageImg:
                'https://cdn.chime.me/image/fs/sitebuild/2024823/6/original_caf6cc31-59b9-4a95-9806-ab39425ab833.png'
        };
    },
    computed: {
        finishedTitle() {
            return this.title || this.$t('finishPage.title');
        },
        finishedSubTitle() {
            return this.subTitle || this.$t('finishPage.subTitle');
        },
        finishedBtnText() {
            return this.btnText || this.$t('finishPage.btnText');
        }
    },
    methods: {
        finish() {
            this.$emit('finish');
        }
    }
};
</script>

<style lang="scss" scoped>
.register-finish-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .finish-page-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .finish-page-img {
            flex-shrink: 0;
            margin-bottom: 30px;
        }
        .finish-page-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            text-align: center;
            color: #191919;
        }
        .finish-page-subTitle {
            margin-top: 20px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #505050;
        }
    }
    .finish-page-button {
        width: 100%;
        margin: 30px 0;
    }
    .site-button {
        height: 40px;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
    }
}
</style>
