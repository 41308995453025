import axios from 'axios';
import { isSSRServer } from '@/config';
export const Preview = {
    origin: 'previewPicture',
    name: 'preview'
};

export const PreviewNum = {
    origin: 'picturesNum',
    name: 'picturesNum'
};

var set = function (target, attr, value) {
    if (target) {
        target[attr] = value;
    }
};

export function checkSoldHousePreview(result) {
    let listings = result?.listings || [],
        ids = [],
        houseMap = {};
    for (let i = 0, len = listings.length; i < len; i++) {
        let house = listings[i];
        if (
            house.soldDate &&
            // (!house[Preview.origin] || !house['listingPictures'])
            !house[Preview.origin]
        ) {
            ids.push(house.id);
            houseMap[house.id] = house;
        }
    }
    if (ids.length && !isSSRServer) {
       return axios.get(`/picture/sold/${ids.join(',')}`).then(({ data }) => {
            for (let id in data) {
                let previews = data[id] ? data[id].split('|') : [];
                if (
                    previews.length === 1 &&
                    previews[0].indexOf(DEFAULT_HOUSE_PREVIEW) !== -1
                ) {
                    previews.length = 0;
                }
                let house = houseMap[id];
                set(house, 'listingPictures', data[id] || '');
                if (!house[Preview.origin]) {
                    set(house, PreviewNum.origin, previews.length);
                    set(
                        house,
                        Preview.origin,
                        previews[0] || DEFAULT_HOUSE_PREVIEW
                    );
                }
            }
        });
    }
}

export const DEFAULT_HOUSE_PREVIEW =
    '//fs01.chime.me/image/fs/sitebuild/2021224/20/original_ddff9285-b8a4-4fd5-840f-0233eb9cc979.png';
