import popMgr from '@/components/global/popMgr';

const styleMap = {
    classic: () =>
        import(
            /* webpackChunkName: "common/questionnaire/classic"*/ './classic.vue'
        ),
    standard: () =>
        import(
            /* webpackChunkName: "common/questionnaire/standard"*/ './standard.vue'
        ),
    fashion: () =>
        import(
            /* webpackChunkName: "common/questionnaire/fashion"*/ './fashion.vue'
        ),
    broker: () =>
        import(
            /* webpackChunkName: "common/questionnaire/broker"*/ './broker.vue'
        )
};

const psMap = {};

const questionnaire = {
    prepare(style, globalData) {
        if (typeof style === 'undefined') {
            style = globalData.questionNaire.style || 'standard';
        }
        if (!psMap[style]) {
            psMap[style] = styleMap[style]();
        }
        return psMap[style];
    },
    open({ options = {}, type = 0, style, bind = false, globalData }) {
        style = style || globalData.questionNaire.style || 'standard';
        const vdata = {
            name: 'questionnaire',
            type,
            optionsFn: bind
                ? _ => options
                : _ => Object.assign({}, globalData.questionNaire, options)
        };
        // popData.vdata = vdata;
        this.prepare(style, globalData).then(pop => {
            popMgr.create(pop.default, vdata);
        });
    }
};

export default questionnaire;
