import Emitter from 'tiny-emitter';
import { isSSRServer } from '@/config';

export default (app) => {
    var eventBus;
    if (isSSRServer || !window.siteAppVm?.$EventBus){
        let emitter = new Emitter();
        eventBus = {
            $on: (...args) => emitter.on(...args),
            $once: (...args) => emitter.once(...args),
            $off: (...args) => emitter.off(...args),
            $emit: (...args) => {
                try{
                    // fixed  three code effect  AM-365079
                    return emitter.emit(...args);
                }catch(e){
                    console.error(e);
                }
            }
        }
    }
    else {
        eventBus = window.siteAppVm.$EventBus;
    }
    app.config.globalProperties.$EventBus = app.$EventBus = eventBus;
};
