import util from '../util';
import {getCookieStatusSyncBoolean} from '@/components/cookieAuthority/cookieStatus.js';
export default async function () {
    if (util.inCmsPageEditor || !util.isReallyUser) {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }

    try {
        var globalData = window.sitePrepareData();
        var trackData = globalData.track || {};
        var gtmId = trackData.gtm;
        var gadConversionId = trackData.gadConversionId;
        var gadGtmId = '';
        if (gadConversionId) {
            gadGtmId = gadConversionId.split('/')[0];
        }
        if (!gadGtmId) {
            return;
        }
        let status = await getCookieStatusSyncBoolean({
            util,
        });
        if(!status){
            return ;
        }
        // gadConversionId  Extracted from inside 
        if (gadGtmId !== gtmId) {
            var jsUrl =
                'https://www.googletagmanager.com/gtm.js?id=' + gadGtmId;// + '&l=siteDataLayer';
            if (gadGtmId.indexOf('AW-') > -1) {
                jsUrl =
                    'https://www.googletagmanager.com/gtag/js?id=' + gadGtmId;
            }
            util.addScript(jsUrl, true);
        }

        gtag('js', new Date());
        gtag('config', gadGtmId);
        window.siteAppVm.$EventBus.$on('siteTrack', function (data) {
            if (data.trackType === 'register') {
                gtag('event', 'conversion', {
                    send_to: gadConversionId
                });
            }
        });
    } catch (e) {
        console.error('ga-conversion error', e);
    }
}
