import popMgr from '@/components/global/popMgr';
const styleMap = {
    standard: () =>
        import(
            /* webpackChunkName: "common/questionnaire/standard"*/ './standard.vue'
        )
};

const psMap = {};

const vow = {
    prepare(style) {
        if (!psMap[style]) {
            let mappedStyle = styleMap[style] || styleMap.standard;
            psMap[style] = mappedStyle();
        }
        return psMap[style];
    },
    activate() {
        this.axiosIns
            .get('/api-site/vow-listings/is-activated?t=' + Date.now())
            .then(({ data }) => {
                if (!data.isVowListingActivated) {
                    this.open(
                        {
                            hasSentEmail: !!data.vowListingActiveMailNum
                        },
                        1
                    );
                }
            });
    },
    open(options = {}, type = 0, callback, style, globalData) {
        style = style || globalData.registration?.style || 'standard';
        const ps = [this.prepare(style)];
        // if (type === 0) {
        //     ps.push(axios.get('/api-site/vow-listings/get-contract'));
        // }
        Promise.all(ps).then(([pop, res]) => {
            let data = type === 0 && res ? res.data : {};
            const vdata = {
                name: 'vow',
                type,
                options: Object.assign(data, options),
                onVnodeBeforeUnmount: callback
            };
            popMgr.create(pop.default, vdata);
        });
    }
};

export default vow;
