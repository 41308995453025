<template>
    <div
        v-if="!isEmpty"
        class="site-p"
        :class="[align, fontFamily]"
        :style="style"
        v-html="formatFontSize(text, size)"
    ></div>
</template>

<script>
import util from '@/common/util';
export default {
    name: 'site-p',
    props: {
        text: [String],
        fontFamily: {
            type: [String],
            validator: value =>
                ['normal', 'light', 'bold'].indexOf(value) !== -1,
            default: 'normal'
        },
        size: {
            type: [Number],
            default: 16
        },
        mbSize: {
            type: [Number],
            default: 16
        },
        align: {
            type: [String],
            validator: value =>
                ['left', 'right', 'center'].indexOf(value) !== -1,
            default: ''
        }
    },
    computed: {
        isEmpty() {
            return util.isEmptyRichtext(this.text);
        },
        style() {
            return {
                'font-size': `${this.platform.isMobile ? this.mbSize : this.size}px`
            };
        }
    }
};
</script>

<style lang="scss">
@import '../../../theme/media';

.site-p {
    color: var(--g-text-color);
    line-height: 1.5;
    &.normal{
        font-family: var(--font-normal);
    }
    &.light{
        font-family: var(--font-light);
    }
    &.bold{
        font-family: var(--font-bold);
    }
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
}
</style>
