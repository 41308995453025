import Youtube from './_components/youtube.vue';
import Vimeo from './_components/vimeo.vue';
import Facebook from './_components/facebook.vue';
import defaultVideo from './_components/default.vue';
import common from './common.js';

export default {
    createVideoByUrl(url) {
        if (this.isYoutube(url)) {
            return Youtube;
        }

        if (this.isVimeo(url)) {
            return Vimeo;
        }

        if (this.isFacebook(url)) {
            return Facebook;
        }

        return defaultVideo;
    },
    isYoutube(url) {
        return common.getYoutubeId(url);
    },
    isVimeo(url) {
        return common.getVimeoId(url);
    },
    isFacebook(url) {
        let decodeUrl = decodeURIComponent(url);
        return common.getFacebookId(decodeUrl);
    }
};
