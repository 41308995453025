<template>
    <component :is="tag" data-from="lofty">
        <slot></slot>
    </component>
</template>
<script>
import util from '@/common/util';
import { mapState as rootMapState } from 'pinia';
import { usePageStore } from '@/store';
export default {
    name: 'SiteH1',
    inject: ['getMdData'],
    computed: {
        ...rootMapState(usePageStore, {
            globalData: store => store.page || {}
        }),
        tag({ globalData }) {
            let ret = 'h1';
            try {
                let { h1TagList = [] } = globalData;
                let hasUserSet = h1TagList.toString().indexOf('user') > -1;
                if (util.inCms) {
                    ret = 'h1';
                } else if (hasUserSet) {
                    ret = 'h2';
                } else if (h1TagList.length > 0) {
                    let moduleIndex = this.getMdData()?.index;
                    let [index, moduleName, from] = h1TagList[0][0].split(',');
                    ret = index == moduleIndex ? 'h1' : 'h2';
                }
            } catch (error) {
                console.log('🚀 ~ tag ~ error:', error);
                ret = 'h1';
            }
            return ret;
        }
    }
};
</script>
