<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'teleport',
    props: {
        /*  Which tab to move to ， best use id */
        to: {
            type: String,
            default: 'body'
        }
    },
    mounted() {
        document.querySelector(this.to).appendChild(this.$el);
    },
    beforeUnmount() {
        // this.$el && document.querySelector(this.to).removeChild(this.$el)
    }
};
</script>
