<template>
    <div class="comp-im-checkbox">
        <input
            class="label im-checkbox-ele"
            type="checkbox"
            v-model="modelVal"
        />
        <label class="label" :class="labelClass" @click="checked">
            <span class="checkicon iconfont icon-checked-light"></span>
        </label>
    </div>
</template>

<script>
import classNames from 'classnames';

export default {
    emits: ['update:value'],
    name: 'im-checkbox',
    props: {
        value: {
            type: Boolean,
            required: true
        },
        theme: {
            type: String,
            default: 'standard'
        }
    },
    methods: {
        checked() {
            this.$emit('update:value', !this.modelVal);
        }
    },
    computed: {
        labelClass() {
            return classNames(this.theme);
        },
        modelVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('update:value', val);
            }
        }
    }
};
</script>

<style lang="scss">
.comp-im-checkbox {
    position: relative;
    overflow: hidden;
    .label {
        cursor: pointer;
        width: 15px;
        height: 15px;
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #ebecf1;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        .checkicon {
            font-size: 0;
            color: var(--color-theme);
        }
    }
    .im-checkbox-ele {
        position: absolute;
        z-index: -100;
        left: -99999px;
        right: -99999px;
        &:checked + .label {
            .checkicon {
                font-size: 13px;
            }
        }
    }
}
</style>
