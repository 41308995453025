import popMgr from '@/components/global/popMgr';
import util from '@/common/util';
import { getStore } from '@/store';
import { isSSRServer } from '@/config';
import {
    ROLE_MAP,
    getBuyerRequestionList,
    getSellerRequestionList
} from './const.js';
import AdQuestionsPop from './index.vue';

export const adNewQuestions = {
    unAnswerIndex: 0,
    isFromNewAdRegister: false,
    showDescribe: false,
    getHasAdNewQuestionEnter() {
        if (isSSRServer) {
            return false;
        }
        return !!(
            !!getStore('user')?.user &&
            (getStore('user')?.user?.leadTypes?.includes(ROLE_MAP.Buyer) ||
                getStore('user')?.user?.leadTypes?.includes(ROLE_MAP.Seller)) &&
            util.getCookie('_ads_source') &&
            util.getCookie('old_reg_modal') == 'false' &&
            !window.localStorage.getItem('__ad_questions')
        );
    },
    showAdQuestionsPop() {
        return (
            this.getHasAdNewQuestionEnter() &&
            this.unAnswerIndex >= 0 &&
            this.isFromNewAdRegister
        );
    },
    init() {
        if (!getStore('user')?.user) {
            this.removeAdQuestionSession();
            return
        }
        if (!this.getHasAdNewQuestionEnter()) {
            return;
        }
        window.siteAxios
            .get('/api-site/questionnaire/leadBehaviorAction?_t=' + Date.now())
            .then(res => {
                const list = res?.data || [];
                const finishedBehavior = list.filter(
                    item => item.behavior != 'alreadyValidPhone'
                );
                this.initFinishedQuestions(finishedBehavior);

                const isFromNewAdRegister =
                    finishedBehavior.findIndex(
                        item => item.behavior == 'registerPopUpType'
                    ) >= 0;
                this.isFromNewAdRegister = isFromNewAdRegister;
            });
    },
    initFinishedQuestions(finishedBehavior = []) {
        let questionConfigs = [];
        let isBuyerLeadType = getStore('user')?.user?.leadTypes?.includes(
            ROLE_MAP.Buyer
        );
        const describeIndex = finishedBehavior.findIndex(
            item => item.behavior == 'questionDescribeMyself'
        );
        const questionSellingBuyNewIndex = finishedBehavior.findIndex(
            item => item.behavior == 'questionSellingBuyNew'
        );
        if (describeIndex >= 0) {
            isBuyerLeadType = true;
        } else if (questionSellingBuyNewIndex >= 0) {
            isBuyerLeadType = false;
        }

        if (isBuyerLeadType) {
            const describeIndex = finishedBehavior.findIndex(
                item => item.behavior == 'questionDescribeMyself'
            );
            if (describeIndex >= 0) {
                const isOwnerHouse =
                    finishedBehavior[describeIndex]?.extraData == 'owner';
                questionConfigs = getBuyerRequestionList(isOwnerHouse);
                this.showDescribe = false
            } else {
                this.unAnswerIndex = 0;
                this.showDescribe = true
                return;
            }
        } else {
            if (questionSellingBuyNewIndex >= 0) {
                const isBuyerHouse =
                    finishedBehavior[questionSellingBuyNewIndex]?.extraData ==
                    'buyer';
                questionConfigs = getSellerRequestionList(isBuyerHouse);
            } else {
                this.unAnswerIndex = 0;
                return;
            }
        }
        const unAnswerIndex = questionConfigs.findIndex(question => {
            return (
                finishedBehavior.findIndex(
                    item => item.behavior == question.behavior
                ) < 0
            );
        });
        this.unAnswerIndex = unAnswerIndex;
    },

    openAdQuestionPop(options = {}, callback) {
        if (!this.showAdQuestionsPop()) {
            return;
        }
        if (this.disabledScrollQuestion()) {
            return;
        }
        if (!options.describeToQuestion && !this.showDescribe) {
            return;
        }
        popMgr.create(AdQuestionsPop, { ...options });
        if (callback && typeof callback == 'function') {
            callback();
        }
    },
    // Solve the problem of triggering registration after scrolling the page.
    // The questionnaire will pop up after registration. If you close the questionnaire, the questionnaire will pop up again due to scrolling.
    disabledScrollQuestion() {
        if (isSSRServer) {
            return true;
        }
        const time_value = localStorage.getItem('__register__ad_questions');
        if (time_value && Date.now() - Number(time_value) < 3000) {
            return true;
        }
        return false;
    },
    removeAdQuestionSession() {
        if (isSSRServer) {
            return;
        }
        localStorage.removeItem('__ad_questions');
        localStorage.removeItem('__register__ad_questions');
    }
};
