<script>
import { h } from 'vue';
export default {
    name: 'schema.org',
    props: ['info'],
    render: function () {
        // TODO: script内部会被htmlencode, 待解决
        return h(
            'script',
            { 
              type: 'application/ld+json',
              "innerHTML": this.html
            },
        );
    },
    computed: {
        html({schemaInfo}){
            if(!schemaInfo){
                return '';
            }
            return `${JSON.stringify(schemaInfo)}`
        },
        schemaInfo() {
            if (!this.info) {
                return '';
            }
            if (Array.isArray(this.info)) {
                return this.info.map(obj => {
                    if (obj.description && obj.description.length > 5000) {
                        obj.description = obj.description.slice(0, 5000);
                    }
                    obj['@context'] = 'http://schema.org';
                    return obj;
                });
            }
            return Object.assign({}, this.info, {
                '@context': 'http://schema.org'
            });
        }
    }
};
</script>