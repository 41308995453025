export default [
    {
        name: 'af',
        label: 'Afrikaans',
        img: ''
    },
    {
        name: 'sq',
        label: 'Albanian',
        img: ''
    },
    {
        name: 'ar',
        label: 'Arabic',
        img: ''
    },
    {
        name: 'hy',
        label: 'Armenian',
        img: ''
    },
    {
        name: 'az',
        label: 'Azerbaijani',
        img: ''
    },
    {
        name: 'eu',
        label: 'Basque',
        img: ''
    },
    {
        name: 'be',
        label: 'Belarusian',
        img: ''
    },
    {
        name: 'bg',
        label: 'Bulgarian',
        img: ''
    },
    {
        name: 'ca',
        label: 'Catalan',
        img: ''
    },
    {
        name: 'zh-CN',
        label: 'Chinese',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/zh-CN.png'
    },
    {
        name: 'hr',
        label: 'Croatian',
        img: ''
    },
    {
        name: 'cs',
        label: 'Czech',
        img: ''
    },
    {
        name: 'da',
        label: 'Danish',
        img: ''
    },
    {
        name: 'nl',
        label: 'Dutch',
        img: ''
    },
    {
        name: 'en',
        label: 'English',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/en.png'
    },
    {
        name: 'et',
        label: 'Estonian',
        img: ''
    },
    {
        name: 'tl',
        label: 'Filipino',
        img: ''
    },
    {
        name: 'fi',
        label: 'Finnish',
        img: ''
    },
    {
        name: 'fr',
        label: 'French',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/fr.png'
    },
    {
        name: 'gl',
        label: 'Galician',
        img: ''
    },
    {
        name: 'ka',
        label: 'Georgian',
        img: ''
    },
    {
        name: 'de',
        label: 'German',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/de.png'
    },
    {
        name: 'el',
        label: 'Greek',
        img: ''
    },
    {
        name: 'ht',
        label: 'Haitian Creole',
        img: ''
    },
    {
        name: 'iw',
        label: 'Hebrew',
        img: ''
    },
    {
        name: 'hi',
        label: 'Hindi',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/hi.png'
    },
    {
        name: 'hu',
        label: 'Hungarian',
        img: ''
    },
    {
        name: 'is',
        label: 'Icelandic',
        img: ''
    },
    {
        name: 'id',
        label: 'Indonesian',
        img: ''
    },
    {
        name: 'ga',
        label: 'Irish',
        img: ''
    },
    {
        name: 'it',
        label: 'Italian',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/italian.png'
    },
    {
        name: 'ja',
        label: 'Japanese',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/ja.png'
    },
    {
        name: 'ko',
        label: 'Korean',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/ko.png'
    },
    {
        name: 'lv',
        label: 'Latvian',
        img: ''
    },
    {
        name: 'lt',
        label: 'Lithuanian',
        img: ''
    },
    {
        name: 'mk',
        label: 'Macedonian',
        img: ''
    },
    {
        name: 'ms',
        label: 'Malay',
        img: ''
    },
    {
        name: 'mt',
        label: 'Maltese',
        img: ''
    },
    {
        name: 'no',
        label: 'Norwegian',
        img: ''
    },
    {
        name: 'fa',
        label: 'Persian',
        img: ''
    },
    {
        name: 'pl',
        label: 'Polish',
        img: ''
    },
    {
        name: 'pt',
        label: 'Portuguese',
        img: ''
    },
    {
        name: 'ro',
        label: 'Romanian',
        img: ''
    },
    {
        name: 'ru',
        label: 'Russian',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/ru.png'
    },
    {
        name: 'sr',
        label: 'Serbian',
        img: ''
    },
    {
        name: 'sk',
        label: 'Slovak',
        img: ''
    },
    {
        name: 'sl',
        label: 'Slovenian',
        img: ''
    },
    {
        name: 'es',
        label: 'Spanish',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/es.png'
    },
    {
        name: 'sw',
        label: 'Swahili',
        img: ''
    },
    {
        name: 'sv',
        label: 'Swedish',
        img: ''
    },
    {
        name: 'th',
        label: 'Thai',
        img: 'https://cdn.chime.me/image/fs/sitebuild/site-cms/translate/th.png'
    },
    {
        name: 'tr',
        label: 'Turkish',
        img: ''
    },
    {
        name: 'uk',
        label: 'Ukrainian',
        img: ''
    },
    {
        name: 'ur',
        label: 'Urdu',
        img: ''
    },
    {
        name: 'vi',
        label: 'Vietnamese',
        img: ''
    },
    {
        name: 'cy',
        label: 'Welsh',
        img: ''
    },
    {
        name: 'yi',
        label: 'Yiddish',
        img: ''
    }
];
