import { getStore } from '@/store';

/**
 *  Is there any logic that does not require pop-up frames? 
 */
let strategies = {
    evaluationRegister(options) {
        if (getStore('user').user && !options.noAutoJumpWhenLogin) {
            // change url
            window.siteAppVm.$EventBus.$emit(
                'change-url',
                `/evaluation?address=${options.address || ''}`
            );
            return true;
        }
        return false;
    }
};

export default function stopPopup(style, options) {
    let strategy = strategies[style];
    if (!strategy) {
        return false;
    }
    return strategy(options);
}
