import { createApp } from 'vue';
import util from '@/common/util';
import plugin from '@/plugins';

//  List of all popups 
// var pops = [];
//  Current popup instance 
var currentPops = [];

// const PopupManager = {
//     zIndex: 2000,
//     nextZIndex: function () {
//         return PopupManager.zIndex++;
//     }
// }

function restorePop() {
    var cur = popMgr.getCurrent();
    if (cur && cur.needRestore /* && !cur._isDestroyed*/) {
        cur.display(true);
    }
}

var popMixin = {
    emits: ['displayChange', 'beforeUnmount'],
    props: ['_storeName'],
    data: function () {
        //  Multiple layers rootData The purpose is that the grandchild component needs to listen popShow the response to 
        return {
            rootData: {
                popShow: false
            }
        };
    },
    provide: function () {
        var _this = this;
        return {
            rootData: this.rootData,
            // pop Called when the component is closed 
            afterLeaveCb: function () {
                if (_this._destroyFlag) {
                    //  if set _destroyFlag,  just destroy the component 
                    _this.app.unmountSelf();
                    restorePop();
                }
            },
            display: this.display,
            destroy: this.destroy
        };
    },
    methods: {
        //  Open / closed control 
        display(popShow, needRestore, needScrollTop) {
            // needRestore Indicates when the upper window of the current window is closed ,  Whether the current window needs to be restored to the open state 
            this.needRestore = needRestore;
            this.rootData.popShow = popShow;
            this.$emit('displayChange', popShow);
            if (popShow) {
                this.needScrollTop = needScrollTop;
                if (needScrollTop) {
                    this.popTop = window.scrollY;
                    window.scrollTo(0, 0);
                    util.enableScroll(false);
                }
            } else if (this.needScrollTop) {
                util.enableScroll(true);
            }
            if (!popShow && this.popTop !== undefined) {
                window.scrollTo(0, this.popTop);
                this.popTop = 0;
            }
        },
        //  The popup window component is destroyed (showAnimation Indicates that the closing animation should be played first , re-destroy )
        destroy: function (showAnimation = true) {
            // if (!this._isDestroyed) {
            this.$emit('beforeUnmount', this);
            if (showAnimation) {
                this._destroyFlag = true;
                this.display(false);
            } else {
                this.app.unmountSelf();
                // document.body.removeChild(this.$el);
                // this.$destroy();
                restorePop();
            }
            var index = currentPops.indexOf(this);
            if (index !== -1) {
                currentPops.splice(index, 1);
            }

            if (popMgr.getVisibleLength() === 0) {
                util.enableScroll(true,true);
            }
        }
    }
};

const weakEvent = new WeakMap();

const popMgr = {
    // 用于监听创建之后的事件
    $once(instance, eventName, callback) {
        let map = weakEvent.get(instance);
        if (!map) {
            map = {};
        }
        if (!map[eventName]) {
            map[eventName] = [];
        }
        map[eventName].push(callback);
        weakEvent.set(instance, map);
    },
    $emit(instance, eventName, params) {
        let map = weakEvent.get(instance);
        if (!map) {
            return;
        }
        if (!map[eventName]) {
            return;
        }
        map[eventName].map(callback => {
            callback(params);
        });
    },
    currentPops: currentPops,
    /**
     *  Inject the popup mixin
     */
    setMixin(popVue, popMixin) {
        if (popVue.mixins) {
            if (popVue.mixins.indexOf(popMixin) === -1) {
                popVue.mixins.push(popMixin);
            }
        } else {
            popVue.mixins = [popMixin];
        }
    },
    /**
     *  Manually create a popup instance ( and pop Use with components )
     * @param popVue  popup configuration ,  usually pass-by-reference vue Popup configuration file ,  Notice : This configuration must reference the pop components 
     * @param args  The parameters required for the initialization of the bullet box instance ,  can pass in propsData( Instead of calling manually before init The way )
     * @param action  The strategy when popping up this box ,  default closeOtherPop | hideOtherPop | normal  one of the ,  Respectively 1. Close other pop-up windows when pop-up  2. Hide other popups ( Restore when this window is closed ) 3. Only pop up a new box on other popup boxes 
     * @param needScrollTop  Do you need to scroll to the top ,  close and restore scrollY,  default " yes "
     * @return  Return popup instance 
     */
    create: function (
        popVue,
        args = {},
        action = 'closeOtherPop',
        needScrollTop = true,
        autoOpen = true
    ) {
        if (action === 'closeOtherPop') {
            this.closeAllPopWin(true);
        } else if (action === 'hideOtherPop') {
            var cur = this.getCurrent();
            if (cur && cur.rootData.popShow) {
                // hide
                cur.display(false, true);
            }
        }
        if (!util.isObject(args)) {
            throw TypeError(
                'The seconde paramtter "args" should be a object type'
            );
        }
        this.setMixin(popVue, popMixin);
        var ins;
        let proxyArg = {
            ...args,
            onVnodeMounted() {
                args?.onVnodeMounted?.();
                popMgr.$emit(ins, 'onVnodeMounted');
            },
            onVnodeBeforeUnmount() {
                args?.onVnodeBeforeUnmount?.();
                popMgr.$emit(ins, 'onVnodeBeforeUnmount');
            }
        };
        var app = createApp(popVue, proxyArg);
        plugin.baseInstall(app, true);
        ins = app.mountSelf();
        ins.app = app;
        ins.action = action;

        //  automatically open 
        if (autoOpen) {
            ins.display(true, false, needScrollTop);
        }
        currentPops.push(ins);
        return ins;
    },
    /**
     *  Get the current popup window 
     */
    getCurrent: function () {
        if (currentPops.length) {
            return currentPops[currentPops.length - 1];
        }
    },
    /**
     *  Get the number of visible popup boxes 
     */
    getVisibleLength: function () {
        return currentPops.filter(ins => ins.rootData.popShow).length;
    },
    /**
     *  Close all open popups 
     */
    closeAllPopWin: function (exceptHiddenPop) {
        for (var i = 0; i < currentPops.length; i++) {
            if (!exceptHiddenPop || currentPops[i].rootData.popShow) {
                currentPops[i].destroy();
                currentPops.splice(i, 1);
                i--;
            }
        }
    }
};

if (!util.isSSRServer){
    window.popMgr = popMgr;
}
export default popMgr;
