<template>
    <div class="m-calendar">
        <MPicker
            ref="picker"
            :show-toolbar="!hideToolbar"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange"
        />
    </div>
</template>

<script>
import MPicker from '../m-picker/picker';
const YEAR_COUNT = 100;
function getYears(date) {
    const nowYear = new Date(date).getFullYear();
    const years = [];
    let count = YEAR_COUNT;
    while (count >= 0) {
        years.push(nowYear - count + YEAR_COUNT / 2);
        count--;
    }
    return years;
}
function getMonths() {
    const months = [];
    let count = 12;
    while (count > 0) {
        months.push(13 - count);
        count--;
    }
    return months;
}
function getDays(day) {
    const days = [];
    let count = day;
    while (count > 0) {
        days.push(day + 1 - count);
        count--;
    }
    return days;
}
function calcDays(year, month) {
    const bigs = [1, 3, 5, 7, 8, 10, 12],
        smalls = [4, 6, 9, 11];
    if (bigs.indexOf(month) > -1) {
        return getDays(31);
    } else if (smalls.indexOf(month) > -1) {
        return getDays(30);
    } else if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        return getDays(29);
    } else {
        return getDays(28);
    }
}
export default {
    name: 'im-m-calendar',
    components: { MPicker },
    data: function() {
        return {};
    },
    emits: ['update:value', 'input', 'confirm', 'cancel', 'change'],
    props: {
        value: {
            type: Number,
            default: Date.now()
        },
        hideToolbar: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onConfirm(val) {
            const date = this.$refs.picker.getValues().join('/');
            const timestamp = new Date(date).getTime();
            this.$emit('input', timestamp);
            this.$emit('confirm', timestamp);
        },
        onCancel() {
            this.$emit('cancel');
        },
        onChange(picker, values, index) {
            const [year, month] = values;
            if (index !== 2) {
                picker.setColumnValues(2, calcDays(year, month));
            }
            const date = this.$refs.picker.getValues().join('/');
            const timestamp = new Date(date).getTime();
            this.$emit('change', timestamp);
        }
    },
    computed: {
        columns() {
            return [
                { values: getYears(this.value), defaultIndex: YEAR_COUNT / 2 },
                {
                    values: getMonths(),
                    defaultIndex: new Date(this.value).getMonth()
                },
                {
                    values: calcDays(
                        new Date().getFullYear(),
                        new Date().getMonth() + 1
                    ),
                    defaultIndex: new Date(this.value).getDate() - 1
                }
            ];
        }
    }
};
</script>

<style lang="scss">
.m-calendar {
    background: #fff;
}
</style>
