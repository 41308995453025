import { createApp, defineAsyncComponent } from 'vue';
import plugin from '@/plugins';
import util from '@/common/util';


const createTermOfUse = ()=>{
    let termOfUse = createApp(
        defineAsyncComponent(()=>import(/* webpackChunkName: "components/term-of-use"*/ "./comm.vue")),
        {
            onDestroy: () => {
                if (termOfUse) {
                    termOfUse.unmount();
                    termOfUse = null;
                }
            }
        }
    );
    plugin.baseInstall(termOfUse, true);
    termOfUse.mountSelf();
}

let load = false;
export default (globalData)=>{
    if(util.isSSRServer || globalData.pageKey == 'privacy-terms' ){
        return ;
    }
    window.siteAppVm.$EventBus.$on('userLogin', user=>{
        console.log("==user===========",user)
        if(load || !user || user.tou){
            return ;
        }
        load = true;
        createTermOfUse();
    });
}