/**
 * 这是同构应用的打包状态, __SSR_STATUS的值在不同场景下的webpack.config.js里面定义, 有下面3个值
 * 0: 表示纯客户端打包
 * 1: 表示ssr服务端打包, 打包出来的代码在node服务端运行, 目的就是通过renderString生成html
 * 2: 表示ssr客户端打包, 打包出来的代码在浏览器拿到node服务端生成的html之后, 在客户端加载, 这个阶段也叫(客户端激活)"注水", 英文是"hydrate"
 */
const SsrStatus = __SSR_STATUS;
export const isClient = SsrStatus === 0;
export const isSSRServer = SsrStatus === 1;
export const isSSRClient = SsrStatus === 2;
// 主要用于获取数据, 通常这些数据存储在pinia中
export const PrefetchMethod = isClient ? 'created' : 'serverPrefetch';
// 主要用于初始化数据, 通常这些数据挂在this上
export const CreatedSyncMethod = isSSRServer ? 'serverPrefetch' : 'created';
