import {createApp} from "vue"
import plugin from '@/plugins';
import util from "@/common/util"
import installClosely from './intallClosely.vue';
import {useChatStore,usePageStore} from "@/store"
const createDownloadPlugin = function() {
    const chatStore = useChatStore()
    const pageStore = usePageStore()
    const showPlugin = pageStore?.page?.['closely-app']?.['openCloselyLink'] ?? true
    return new Promise((resolve)=>{
        let userIsClose = localStorage.getItem('close-closely-download');
        if (!userIsClose && util.isMobile && !pageStore.isLandingPage && showPlugin){
            let app = createApp(installClosely, {
                onDestroy:()=>{
                    destroyDownloadPlugin()
                    resolve()
                }
            });
            chatStore.updateChatPosition({mBottom:"75px", mChatWindowBottom:"60px"})
            plugin.baseInstall(app);
            app.mountSelf();
            window.installCloselyPlugin = app
        }else{
            resolve()
        }
    })

}
const destroyDownloadPlugin = function(){
    const chatStore = useChatStore()
    window.installCloselyPlugin?.unmountSelf?.()
    window.installCloselyPlugin = null;
    localStorage.setItem('close-closely-download', 1);
    chatStore.resetChatPosition();
}
export{
    createDownloadPlugin,
    destroyDownloadPlugin
}