<template>
    <div
        class="copyright md-evaluation-banner"
        v-if="chimeHtml && isShow"
        v-html="chimeHtml"
    ></div>
    <div
        v-else
        v-show="isShowCopyright && (showCopyright || displayCopyright) && isShow"
        class="copyright md-evaluation-banner"
    >
        <template v-if="gfFlag != '1'">
            <p
                class="chime"
                v-html="
                    $t('common:copyright.content', {
                        chimeText: lnChimeText,
                        chimeLink,
                        year
                    })
                "
            ></p>
            <p class="service">
                <a
                    href="/site/privacy-terms#terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t('common:copyright.termsOfService') }}</a
                >
            </p>
            <p class="policy">
                <a
                    href="/site/privacy-terms#privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t('common:copyright.privacyPolicy') }}</a
                >
            </p>
        </template>
        <p
            v-else
            class="chime"
            v-html="$t('common:copyright.geographicFarmingLlc', { year })"
        ></p>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { OFFICE_DOMAIN } from "lofty_region"
import { usePageStore } from '@/store';
const ALLOW_PAGES_SHOW = ['buy-guide', 'sell-guide'];

export default {
    i18next: {
        ns: 'common'
    },
    data: function() {
        return {
            year: new Date().getFullYear(),
            isShow: true,
            displayCopyright: false // The module itself is downloaded from md-evaluation The components inside are extracted from ， Here, in order to be compatible with the previous reference to the component and cooperate with the outermost module json This form of assignment ， so add the attribute 
        };
    },
    props: {
        showCopyright: {
            type: Boolean,
            default: false
        },
        chimeText: {
            type: String,
            default: ''
        },
        chimeLink: {
            type: String,
            default: () => OFFICE_DOMAIN
        },
        chimeHtml: String
    },
    computed: {
        ...mapState(usePageStore, {
            page: store => store.page || {},
            isShowCopyright: store =>
                !(
                    store.page['remove-copyright']?.removeCopyright ??
                    false
                )
        }),
        gfFlag({ page }) {
            return page.gfFlag;
        },
        lnChimeText() {
            return this.chimeText || this.$t('common:copyright.madeInPhoenixAzBy');
        }
    },
    mounted() {
        if (
            ALLOW_PAGES_SHOW.includes(this.page.pageKey) ||
            (this.page.pageKey === 'schedule_meeting_link' && this.page.isLandingPage)
        ) {
            this.displayCopyright = true;
            let page = document.querySelector('.page-content');
            if (page) {
                page.classList.add('no-footer-has-copyright');
            }
        }
        this.$EventBus.$on('closeCopyRight', ()=>{
            this.isShow = false;
        })
        this.$EventBus.$on('openCopyRight', ()=>{
            this.isShow = true;
        })
    }
};
</script>

<style lang="scss">
@import '@/theme/media.scss';
.md-evaluation-banner.copyright {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: space-around;
    padding: 7px 0 5px;
    background-color: rgba(25, 25, 25, 0.7);
    color: #fff;
    font-family: var(--font-medium);
    font-weight: normal;
    z-index: 3;
    .chime {
        .link {
            text-decoration: underline;
        }
        .reserved {
            display: block;
        }
    }
    .chime,
    .service,
    .policy {
        padding: 0 15px;
    }
    @include media-800 {
        height: 100px;
        background-color: rgba(0, 0, 0, 0.3);
        flex-direction: column;
        text-align: left;
        justify-content: center;
    }
}
.no-footer-has-copyright {
    padding-bottom: 85px;
}
</style>