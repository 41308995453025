<template>
    <div class="im-select-option" @click.stop="handleClick">
        <template v-if="$slots.default">
            <slot></slot>
        </template>
        <div class="item" :class="itemClass" v-else>
            <span class="label">{{ currentLabel }}</span>
            <div class="icon">
                <template v-if="!select.multiple">
                    <span
                        v-show="selected"
                        class="iconfont icon-checked-light"
                    ></span>
                </template>
                <template v-else>
                    <ImCheckbox :theme="theme" :value="selected"></ImCheckbox>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import classNames from 'classnames';
import Checkbox from '../checkbox';
import util from '@/common/util';
export default {
    name: 'im-option',
    inject: {
        theme: 'theme',
        select: 'select'
    },
    created() {
        this.select.insertOption(this);
        // this.select.cachedOptions[this.value] = this;
        // if (this.selected) this.select.setLabels();
    },
    beforeUnmount() {
        this.select.removeOption(this);
    },
    props: {
        label: [String, Number],
        value: {
            type: [String, Number, Object],
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isObject() {
            return (
                Object.prototype.toString.call(this.value).toLowerCase() ===
                '[object object]'
            );
        },
        itemClass() {
            return classNames(this.theme, {
                selected: this.selected,
                disabled: this.disabled
            });
        },
        currentLabel() {
            return this.label || (this.isObject ? '' : this.value);
        },
        selected: {
            get() {
                if (!this.select.multiple) {
                    return this.isEqual(
                        this.value,
                        this.select.value && this.select.value[0]
                    );
                } else {
                    return this.contains(this.select.value, this.value);
                }
            }
            // set() {
            //     this.dispatch('im-select', 'optionClick', this);
            // }
        }
    },
    components: { ImCheckbox: Checkbox },
    methods: {
        // option click
        handleClick() {
            if (!this.disabled) {
                // this.dispatch('im-select', 'optionClick', this);
                this.select.handleOptionClick(this);
            }
        },

        isEqual(a, b) {
            if (!this.isObject) {
                return a === b;
            } else {
                const valueKey = this.select.valueKey;
                return (
                    // util
                    util.getValueByPath(a, valueKey) ===
                    util.getValueByPath(b, valueKey)
                );
            }
        },

        contains(arr = [], val) {
            if (!this.isObject) {
                return arr && arr.indexOf(val) > -1;
            } else {
                const valueKey = this.select.valueKey;
                return arr.some(item => {
                    return (
                        util.getValueByPath(item, valueKey) ===
                        util.getValueByPath(val, valueKey)
                    );
                });
            }
        }
    }
};
</script>

<style lang="scss">
.im-select-option {
    cursor: pointer;
    .item {
        /* padding: 0 10px 0 20px; */
        padding: 0;
        height: 40px;
        line-height: 40px;
        display: flex;
        .label {
            flex: 1 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .icon {
            /* display: none; */
            /* padding: 0 12px; */
            display: flex;
            align-items: center;
            justify-content: center;
            /* .iconfont {
                font-size: 12px;
            } */
        }
        &.selected{
            color: var(--color-theme);
        }
        &.standard.disabled,
        &.template4.disabled,
        &.template1.disabled {
            color: #e4e7ed;
        }
    }
}
</style>
