<template>
    <div class="register-phone-valid-container">
        <slot name="title">
            <h2
                class="title"
                v-if="phoneValidTitle"
                v-html="phoneValidTitle"
            ></h2>
            <h3
                class="subTitle"
                v-if="phoneValidSubTitle"
                v-html="phoneValidSubTitle"
            ></h3>
        </slot>
        <div class="phone-valid-img">
            <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    opacity="0.03"
                    x="30"
                    width="50"
                    height="80"
                    rx="6"
                    fill="black"
                />
                <rect
                    opacity="0.05"
                    x="35"
                    y="10"
                    width="40"
                    height="60"
                    rx="2"
                    fill="black"
                />
                <path
                    id="svgImg"
                    d="M17 16C7.61116 16 0 23.6112 0 33C0 42.3888 7.61116 50 17 50H44L50.7506 55.4005C52.0601 56.4481 54 55.5158 54 53.8387V33C54 23.6112 46.3888 16 37 16H17Z"
                    fill="#3B5CDE"
                />
                <rect
                    opacity="0.6"
                    x="11"
                    y="30"
                    width="6"
                    height="6"
                    rx="3"
                    fill="white"
                />
                <rect
                    opacity="0.8"
                    x="24"
                    y="30"
                    width="6"
                    height="6"
                    rx="3"
                    fill="white"
                />
                <rect x="37" y="30" width="6" height="6" rx="3" fill="white" />
            </svg>
        </div>
        <div class="phone-valid-content">
            <div class="intro">
                <span class="intro-text">{{ $t('phoneValid.sendText') }}</span
                ><span class="register-phone-number" v-if="phoneNumber">{{
                    formatPhone
                }}</span>
            </div>
            <div class="verification-code-wrap">
                <div class="verification-code-title">
                    {{ $t('phoneValid.enterCodeText') }}
                </div>

                <div class="verification-code">
                    <input
                        :class="[
                            'code',
                            { 'code-active': activeIndex === index }
                        ]"
                        v-for="(item, index) in codeList"
                        :key="index"
                        :ref="`codeInput${index}`"
                        maxlength="1"
                        type="number"
                        v-model.trim="codeList[index]"
                        @input="onInput($event, index)"
                        @focus="onFocus(index)"
                    />
                </div>
            </div>
            <div class="send-to-phone">
                <span class="send-text"
                    >{{ $t('phoneValid.receiveCode')
                    }}<span
                        class="send-btn"
                        :disabled="disableResend"
                        @click="getVerificationCode"
                        >{{ $t('phoneValid.resend') }}</span
                    ></span
                >
                <span class="countdown" v-if="disableResend"
                    >({{ countdown }}s)</span
                >
            </div>

            <div class="register-phone-button-wrap">
                <site-button
                    type="normal"
                    html="Continue"
                    class="button-next"
                    :disabled="!hasFinishFillCode"
                    @click="validPhone"
                ></site-button>
            </div>
            <div class="later-text" @click="handleSkip">
                {{ $t('phoneValid.laterText') }}
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/common/util.js';
export default {
    i18next: {
        ns: 'common'
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        phoneNumber: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            codeList: Array(6),
            activeIndex: 0,
            countdown: 0
        };
    },
    computed: {
        formatPhone() {
            const phone = this.phoneNumber.replace(
                /(\d{2})(\d{1})(\d{3})(\d{2})(\d{2})/g,
                '$1x xxx xx$5'
            );
            return '+1 ' + phone;
        },
        phoneValidTitle() {
            return this.title || this.$t('phoneValid.title');
        },
        phoneValidSubTitle() {
            return this.subTitle || this.$t('phoneValid.subTitle');
        },
        verificationCode() {
            return this.codeList.join('');
        },
        hasFinishFillCode() {
            return this.verificationCode?.length === 6;
        },
        disableResend() {
            return this.countdown > 0;
        }
    },
    mounted() {
        this.getVerificationCode({ resent: false });
    },
    methods: {
        getVerificationCode({ resent = true }) {
            if (this.disableResend) {
                return;
            }
            this.axiosIns
                .post('/api-site/register/phoneValid/sendCode', {
                    phoneNumber: this.phoneNumber
                })
                .then(_ => {
                    if (resent) {
                        util.toast(this.$t('phoneValid.resendToast'));
                    }
                    this.startCountdown();
                })
                .catch(err => {
                    if (err.errorInfo) {
                        util.toast(err.errorInfo);
                    }
                });
        },
        startCountdown() {
            this.countdown = 60;
            clearInterval(this._timer);
            this._timer = setInterval(() => {
                if (this.countdown < 1) {
                    clearInterval(this._timer);
                    return;
                }
                this.countdown = this.countdown - 1;
            }, 1000);
        },
        onInput(e, index) {
            if (e.inputType === 'deleteContentBackward') {
                if (index > 0) {
                    this.activeIndex = index - 1;
                    const prevInput =
                        this.$refs[`codeInput${this.activeIndex}`];
                    prevInput?.[0]?.focus();
                    return;
                }
            }
            this.codeList[index] = e.data?.replace(/[^0-9]/g, '').slice(0, 1);
            this.activeIndex = index + 1;
            if (e.data && this.activeIndex < 6 && !this.hasFinishFillCode) {
                const nextInput = this.$refs[`codeInput${this.activeIndex}`];
                nextInput?.[0]?.focus();
            }
        },
        onFocus(index) {
            this.activeIndex = index;
        },
        validPhone() {
            if (!this.hasFinishFillCode) {
                return;
            }
            this.axiosIns
                .post('/api-site/register/phoneValid/valid', {
                    phoneNumber: this.phoneNumber,
                    code: this.verificationCode
                })
                .then(res => {
                    const hasValid = !!res?.data;
                    if (hasValid) {
                        this.nextStep();
                    } else {
                        util.toast(this.$t('phoneValid.invalidCode'));
                    }
                })
                .catch(_ => {
                    util.toast(this.$t('phoneValid.invalidCode'));
                });
        },
        nextStep() {
            this.$emit('nextStep');
        },
        handleSkip() {
            this.$emit('handleSkip');
        }
    }
};
</script>

<style lang="scss" scoped>
.register-phone-valid-container {
    h2.title {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        color: #191919;
        margin-top: 40px;
    }
    h3.subTitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #505050;
        margin: 15px auto 30px;
    }
    .phone-valid-img {
        text-align: center;
        margin-bottom: 30px;
        #svgImg {
            fill: var(--color-theme);
        }
    }
    .phone-valid-content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .intro {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            .intro-text {
                font-size: 12px;
                line-height: 20px;
                color: #505050;
            }
            .register-phone-number {
                font-size: 14px;
                line-height: 20px;
                color: #191919;
            }
        }
        .verification-code-wrap {
            .verification-code-title {
                text-align: center;
                font-size: 12px;
                line-height: 20px;
                color: #505050;
                margin-bottom: 10px;
            }
            .verification-code {
                display: flex;
                justify-content: space-between;
                .code {
                    width: 48px;
                    height: 48px;
                    border: 1px solid #dadada;
                    outline: none;
                    box-shadow: none;
                    border-radius: 5px;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 30px;
                    color: #191919;
                    text-align: center;
                }
            }
        }
        .send-to-phone {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            line-height: 20px;
            color: #505050;
            user-select: none;
            .send-btn {
                font-weight: 600;
                color: var(--color-theme);
                padding: 0 3px;
                text-decoration: underline;
                cursor: pointer;
                &[disabled='true'] {
                    opacity: 0.4;
                    cursor: no-drop;
                }
            }
            .countdown {
                width: 32px;
                color: var(--color-theme);
            }
        }
        .register-phone-button-wrap {
            display: flex;
            gap: 20px;
            .button-back,
            .button-next {
                flex: 1;
                height: 40px;
                padding: 10px 20px;
                font-size: 16px;
                border-radius: 5px;
                &:hover {
                    &::after {
                        display: none;
                    }
                }
                &[disabled] {
                    opacity: 0.4;
                    cursor: no-drop;
                }
            }
        }
        .later-text {
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: var(--color-theme);
            margin-bottom: 30px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
