<template>
    <pop
        :showMask="true"
        :allowClose="true"
        :outClose="!isMobile"
        class="register-questions-pop"
        ref="questionsRef"
        :onClose="adRegisterClose"
    >
        <div class="questions-container">
            <div class="questions-bg">
                <Describe v-if="firstStep" @change="changeDescribe"></Describe>
                <FinishPage
                    v-if="secondStep || fourStep"
                    @handleClick="handleClick"
                ></FinishPage>
                <Questions
                    v-if="thirdStep"
                    :isBuyerRole="isBuyerRole"
                    :unAnswerIndex="unAnswerIndex"
                    :questionConfigs="questionConfigs"
                    :finishedBehavior="finishedBehavior"
                    @finishedQuestions="finishedQuestions"
                    @addNote="addNote"
                    @changeHouseType="changeHouseType"
                ></Questions>
            </div>
            <div class="questions-bg2"></div>
            <div class="questions-bg3"></div>
        </div>
    </pop>
</template>

<script>
import { mapState as rootMapState } from 'pinia';
import { useUserStore } from '@/store';
import Describe from './describe.vue';
import FinishPage from './finishPage.vue';
import Questions from './questions.vue';
import {
    ROLE_MAP,
    getBuyerRequestionList,
    getSellerRequestionList
} from './const.js';
export default {
    props: {
        describeToQuestion: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            step: null,
            houseType: '',
            buyerRequestionList: [],
            sellerRequestionList: [],
            finishedBehavior: [],
            unAnswerIndex: -1,
            oldLeadType: null
        };
    },
    components: {
        Describe,
        FinishPage,
        Questions
    },
    computed: {
        ...rootMapState(useUserStore, {
            user: store => store.user
        }),
        leadTypes() {
            return this.user?.leadTypes || [];
        },
        isMobile() {
            return this.platform.isMobile;
        },
        firstStep() {
            return this.step == 1;
        },
        secondStep() {
            return this.step == 2;
        },
        thirdStep() {
            return this.step == 3;
        },
        fourStep() {
            return this.step == 4;
        },
        isBuyerRole() {
            if (this.oldLeadType) {
                return this.oldLeadType === 'Buyer';
            }
            return this.leadTypes?.includes(ROLE_MAP.Buyer);
        },
        isOwnerHouse() {
            return this.houseType == 'owner';
        },
        isBuyerHouse() {
            return this.houseType == 'buyer';
        },
        questionConfigs() {
            return this.isBuyerRole
                ? this.buyerRequestionList
                : this.sellerRequestionList;
        }
    },
    mounted() {
        if (this.describeToQuestion) {
            localStorage.setItem('__ad_questions', '1');
        }
        this.getFinishQuestions();
    },
    methods: {
        init() {
            const describeIndex = this.finishedBehavior.findIndex(
                item => item.behavior == 'questionDescribeMyself'
            );
            const questionSellingBuyNewIndex = this.finishedBehavior.findIndex(
                item => item.behavior == 'questionSellingBuyNew'
            );
            if (describeIndex >= 0) {
                this.oldLeadType = 'Buyer';
            } else if (questionSellingBuyNewIndex >= 0) {
                this.oldLeadType = 'Seller';
            } else {
                this.oldLeadType = null;
            }
            if (this.isBuyerRole) {
                if (describeIndex >= 0) {
                    this.step = 3;
                    this.houseType =
                        this.finishedBehavior[describeIndex]?.extraData;
                    this.buyerRequestionList = getBuyerRequestionList(
                        this.isOwnerHouse
                    );
                } else {
                    this.step = 1;
                    if (!this.describeToQuestion) {
                        return;
                    }
                }
            } else {
                if (questionSellingBuyNewIndex >= 0) {
                    this.houseType =
                        this.finishedBehavior[
                            questionSellingBuyNewIndex
                        ]?.extraData;
                }
                this.step = 3;
                this.sellerRequestionList = getSellerRequestionList(
                    this.isBuyerHouse
                );
            }
            if (describeIndex < 0 && questionSellingBuyNewIndex < 0) {
                this.unAnswerIndex = 0;
                return;
            }
            const unAnswerIndex = this.questionConfigs.findIndex(question => {
                return (
                    this.finishedBehavior.findIndex(
                        item => item.behavior == question.behavior
                    ) < 0
                );
            });
            this.unAnswerIndex = unAnswerIndex;
        },
        getFinishQuestions() {
            this.axiosIns
                .get(
                    '/api-site/questionnaire/leadBehaviorAction?_t=' +
                        Date.now()
                )
                .then(res => {
                    const list = res?.data || [];
                    this.finishedBehavior = list.filter(
                        item => item.behavior != 'alreadyValidPhone'
                    );
                    this.init();
                });
        },
        changeDescribe(item = {}) {
            this.houseType = item.key;
            const note =
                this.houseType === 'owner'
                    ? 'Lead has added additional personal info: Lead is a homeowner'
                    : 'Lead has added additional personal info: Lead is a renter';
            const params = {
                behavior: 'questionDescribeMyself',
                extraData: this.houseType,
                note
            };
            this.addNote(params);
            this.changeLeadType(item.type).then(_ => {
                this.step = 2;
            });
        },
        addNote(params = {}) {
            return this.axiosIns.post('/api-site/questionnaire/addNote', {
                ...params
            });
        },
        changeLeadType(leadTypeList) {
            return this.axiosIns.post('/api-site/questionnaire/sync/leadType', {
                leadTypeList
            });
        },
        handleClick() {
            if (
                (this.secondStep && !this.describeToQuestion) ||
                this.fourStep
            ) {
                this.close();
            } else {
                this.step = 3;
                this.buyerRequestionList = getBuyerRequestionList(
                    this.isOwnerHouse
                );
            }
        },
        finishedQuestions({ isFinished, goFinishedPage } = {}) {
            if (isFinished) {
                if (goFinishedPage) {
                    this.step = 4;
                } else {
                    this.close();
                }
            }
        },
        changeHouseType(type) {
            this.houseType = type;
            this.sellerRequestionList = getSellerRequestionList(
                this.isBuyerHouse
            );
        },
        adRegisterClose() {
            if (!this.describeToQuestion) {
                localStorage.setItem('__register__ad_questions', Date.now());
            }
        },
        close() {
            this.$refs?.questionsRef?.close?.();
            if (this.secondStep) {
                this.adRegisterClose();
            }
        }
    }
};
</script>

<style lang="scss" scope>
@import '@/theme/mixin.scss';
.register-questions-pop {
    .pop {
        .icon-close {
            z-index: 100;
        }
        @include media-600 {
            width: calc(100% - 30px);
        }
    }
}

.register-questions-pop .questions-container {
    position: relative;
    width: 400px;
    min-height: 560px;
    margin: 0 auto;
    @include media-600 {
        width: 100%;
    }
    .questions-bg {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 530px;
        padding: 40px 30px;
        box-sizing: border-box;
    }
    .questions-bg2 {
        position: absolute;
        z-index: 2;
        width: calc(100% - 40px);
        height: 525px;
        margin: 20px 20px 0;
    }
    .questions-bg3 {
        position: absolute;
        z-index: 1;
        width: calc(100% - 80px);
        height: 520px;
        margin: 40px 40px 0;
    }
    .questions-bg,
    .questions-bg2,
    .questions-bg3 {
        top: 0;
        left: 0;
        right: 0;
        border-radius: 24px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 5px 65px 0px rgba(0, 0, 0, 0.1) inset,
            0px 5px 15px 0px rgba(0, 0, 0, 0.1) inset,
            0px 5px 15px 0px rgba(0, 0, 0, 0.1) inset,
            0px 5px 15px 0px rgba(0, 0, 0, 0.03);
    }
    .register-finish-page {
        justify-content: center;
        .finish-page-content {
            flex: none;
        }
        .finish-page-button {
            margin-bottom: 0px;
        }
    }
}
</style>
