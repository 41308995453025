import util from '@/common/util';
import api from '../api';
import { getStore } from '@/store';

function buildSendParam() {
    let siteAppVm = window.siteAppVm;
    const module = getStore('page').findFirstSearchBarModule();
    let recentlySearch = '';
    if (module) {
        recentlySearch = module.recentlySearch;
    }
    let cityProperty = (recentlySearch &&
        recentlySearch.find(s => s.keywordType === 'city')) || {
        keyword: ''
    };
    let recentlyPropertyType =
        localStorage.getItem('recently-property-type') || '';

    const params = {
        isQueryCityNumLink: true
    };
    if (recentlyPropertyType)
        params['siteListingListPropertyType'] = recentlyPropertyType;
    if (cityProperty.keyword)
        params['siteListingListCity'] = cityProperty.keyword;
    const page = window.sitePrepareData().pageKey;
    if (page === 'listing-detail') {
        const detailInfo = siteAppVm.moduleData.find(
            m => m.name === 'md-detail-info'
        ) || { data: { listingDetail: { info: {} } } };
        Object.assign(params, {
            siteListingPageCity: detailInfo.data.listingDetail.info.city || '',
            siteListingPageAddress:
                detailInfo.data.listingDetail.info.streetAddress || '',
            siteListingPagePropertyType:
                detailInfo.data.listingDetail.info.propertyType || ''
        });
    }
    return params;
}

const sendSiteWelcome = function () {
    const params = buildSendParam();
    return api
        .sendSiteEvent({
            visitId: getVisitorId(),
            event: 'site_welcome',
            ...params
        })
        .then(res => {
            window.localStorage.setItem('set-site-welcome', '1');
            return res;
        });
};
const sendListingDetailPage = function () {
    let paths = location.pathname.split('/');
    const params = buildSendParam();
    return api
        .sendSiteEvent({
            listing_address: paths[paths.length - 1],
            visitId: getVisitorId(),
            event: 'site_listing_page',
            ...params
        })
        .then(res => {
            window.localStorage.setItem('set-listing-page', '1');
            return res;
        });
};
const sendSearchListingPageWelcome = function () {
    let scProperType = util.getQueryField('Property-Type') || 'homes';
    scProperType = scProperType.split(',').join(', ');
    const params = buildSendParam();
    let scLocation = '';
    let scSchoolDistrict = '';
    let key = util.getQueryField('key');
    var m = key.match(/(neighborhood|city|county|zipCode):([^,;]+)/);
    if (m) {
        scLocation = m[2];
    }
    m = key.match(/(schoolDistrict|hjSchoolDistrict):([^,;]+)/);
    if (m) {
        scSchoolDistrict = m[2];
    }

    return api
        .sendSiteEvent({
            visitId: getVisitorId(),
            event: 'site_list_welcome',
            scProperType,
            scLocation,
            scSchoolDistrict,
            ...params
        })
        .then(res => {
            window.localStorage.setItem('set-site-welcome', '1');
            return res;
        });
};

/**
 *  turned on AI After some session flow processing 
 */
const AIDialogFlow = function ({ agentId, onAIMockMsg }) {
    this.agentId = agentId;
    this.onAIMockMsg = onAIMockMsg;

    if (window.siteAppVm.user) {
        afterLogin.call(this);
    } else {
        window.siteAppVm.$EventBus.$on('siteTrack', e => {
            if (e.trackType === 'login' || e.trackType === 'register') {
                setTimeout(() => {
                    afterLogin.call(this);
                }, 1000);
            }
        });
    }
};

function afterLogin() {
    //  interface request 
    window.siteAxios.post(`/api/chatbot/AI/assistant/lead/link/visit`, {
        leadUserId: window.siteAppVm.user?.id || '',
        teamId: window.siteAppVm.user?.teamId || '',
        visitId: getVisitorId()
    });
    // 10 auto-trigger after seconds revisitorLackInquiry
    setTimeout(() => {
        revisitorLackInquiry.call(this);
    }, 10000);

    //  Avoid repeated execution resulting in multiple monitoring 
    if (afterLogin.ran) {
        console.log('afterLogin repeat');
        return;
    }
    afterLogin.ran = true;

    // Favorite AI process 
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'favor-click' && e.status === 1) {
            let listingUrl = '';
            if (e?.listingUrl) {
                listingUrl = e.listingUrl.startsWith('http')
                    ? e.listingUrl
                    : `${window.location.origin}${e.listingUrl}`;
            }
            let obj = {
                listingId: String(e.listingId),
                address: e.address,
                listingUrl
            };
            if (e.listingType) {
                obj.listingType = e.listingType;
            }
            siteSaveListing.call(this, obj);
        }
    });
    //  Details page Schedule a Tour of AI process 
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'schedule_a_tour') {
            let listingUrl = '';
            if (e?.listingUrl) {
                listingUrl = e.listingUrl.startsWith('http')
                    ? e.listingUrl
                    : `${window.location.origin}${e.listingUrl}`;
            }
            siteRequestShowing.call(this, {
                address: e.address,
                listingUrl,
                listingId: e.listingId,
                listingType: e.listingType
            });
        }
    });
    // Leave Message of AI process 
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'leave-message' && e.msg) {
            siteLeaveMessage.call(this, {
                leaveMessage: e.msg
            });
        }
    });
}

/**
 * site special behavior triggers on AI False news 
 * @param siteEvent  sent events ,  record after sending sessionStorage
 * @param conditionEvent  sending conditions (sessionStorage no in conditionEvent to send )
 */

function siteBehaviorFlow({ siteEvent, activityEnum }, conditionEvent = []) {
    // /api/chatbot/backToSite/task/generate  Add address Entry 
    return function (args) {
        psReady.then(() => {
            for (let i = 0; i < conditionEvent.length; i++) {
                if (window.sessionStorage.getItem(conditionEvent[i]) == '1') {
                    return;
                }
            }
            window.sessionStorage.setItem(siteEvent, '1');
            window.siteAxios.post(
                `/api/chatbot/backToSite/task/generate`,
                Object.assign(
                    {
                        userId: this.agentId,
                        leadUserId: window.siteAppVm.user?.id,
                        visitId: getVisitorId(),
                        siteEvent: siteEvent,
                        activityEnum: activityEnum
                    },
                    args
                )
            )
            .then(res => {
                if (res.data) {
                    this.onAIMockMsg && this.onAIMockMsg(res.data);
                }
            });
        });
    };
}

var siteSaveListing = siteBehaviorFlow({ siteEvent: 'site_save_listing' });
var siteLeaveMessage = siteBehaviorFlow({ siteEvent: 'site_leave_message' });
var siteRequestShowing = siteBehaviorFlow(
    { siteEvent: 'site_request_showing' },
    ['site_request_showing']
);
//  if not sent site_save_listing,site_leave_message,site_request_showing event is sent recurring_revisitor_lack_inquiry event 
var revisitorLackInquiry = siteBehaviorFlow(
    { siteEvent: 'recurring_revisitor_lack_inquiry' },
    [
        'recurring_revisitor_lack_inquiry',
        'site_save_listing',
        'site_leave_message',
        'site_request_showing'
    ]
);
var sharesPropertyInfo = siteBehaviorFlow({
    siteEvent: 'site_save_listing',
    activityEnum: 'shares_property_info'
});
var viewsSpecificHome = siteBehaviorFlow({
    siteEvent: 'site_save_listing',
    activityEnum: 'views_specific_home'
});
var saveSearch = siteBehaviorFlow({
    siteEvent: 'site_save_search',
    activityEnum: 'save_search'
});
var viewFiveProperties = siteBehaviorFlow({
    siteEvent: 'site_view_properties',
    activityEnum: 'view_five_properties'
});

// view_three_sold_properties
var viewThreeSoldProperties = siteBehaviorFlow({
    activityEnum: 'view_three_sold_properties'
});

// seller_home_evaluation
var sellerHomeEvaluation = siteBehaviorFlow({
    activityEnum: 'seller_home_evaluation'
});

export const aiInit = function () {
    //  After avoiding repeated execution ， Monitor multiple times 
    if (aiInit.isInited) {
        console.log('aiInit repeat');
        return;
    }
    aiInit.isInited = true;

    // shares_property_info of AI process 
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'shares_property_info') {
            let listingUrl = '';
            if (e?.listingUrl) {
                listingUrl = e.listingUrl.startsWith('http')
                    ? e.listingUrl
                    : `${window.location.origin}${e.listingUrl}`;
            }
            psReady.then(flow => {
                sharesPropertyInfo.call(flow, {
                    listingId: String(e.listingId),
                    address: e.address,
                    listingUrl,
                    listingType: e.listingType
                });
            });
        }
    });
    // views_specific_home of AI process 
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'views_specific_home') {
            let listingUrl = '';
            if (e?.listingUrl) {
                listingUrl = e.listingUrl.startsWith('http')
                    ? e.listingUrl
                    : `${window.location.origin}${e.listingUrl}`;
            }
            psReady.then(flow => {
                viewsSpecificHome.call(flow, {
                    listingId: String(e.listingId),
                    address: e.address,
                    listingUrl,
                    listingType: e.listingType
                });
            });
        }
    });
    // save_search of AI process 
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'save_search') {
            psReady.then(flow => {
                saveSearch.call(flow, {
                    listingId: String(e.listingId)
                });
            });
        }
    });

    // view_five_properties of AI process 
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'view_five_properties') {
            psReady.then(flow => {
                viewFiveProperties.call(flow, {
                    listingId: String(e.listingId),
                    listingType: e.listingType
                });
            });
        }
    });

    // view_three_sold_properties
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'view_three_sold_properties') {
            psReady.then(flow => {
                viewThreeSoldProperties.call(flow, {
                    listingId: String(e.listingId),
                    listingType: e.listingType
                });
            });
        }
    });

    // sellerHomeEvaluation
    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if (e.trackType === 'seller_home_evaluation') {
            psReady.then(flow => {
                sellerHomeEvaluation.call(flow, {
                    address: e.address || ''
                });
            });
        }
    });
};

//  guest or login lead Enter site hour ,  Under certain conditions, some AI False news 
AIDialogFlow.prototype.welcomeFlow = function () {
    var wait5;
    var ps;
    var isHistoryMsg;
    if (location.href.indexOf('/listing-detail/') > -1) {
        if (window.localStorage.getItem('set-listing-page') != '1') {
            wait5 = new Promise(res => setTimeout(res, 5000));
        } else {
            isHistoryMsg = true;
            wait5 = Promise.resolve();
        }
        ps = wait5.then(() => sendListingDetailPage());
    } else {
        if (window.localStorage.getItem('set-site-welcome') != '1') {
            wait5 = new Promise(res => setTimeout(res, 5000));
        } else {
            isHistoryMsg = true;
            wait5 = Promise.resolve();
        }
        if (/\/listing$|\/listing\?/.test(location.href)) {
            ps = wait5.then(() => sendSearchListingPageWelcome());
        } else {
            ps = wait5.then(() => sendSiteWelcome());
        }
    }
    if (ps) {
        ps.then(({ data }) => {
            this.onAIMockMsg(data, isHistoryMsg);
            //  Below is followup process 
            this.sendFollowup();
        });
    }
};

var timer;
//  start up followup process 
AIDialogFlow.prototype.sendFollowup = function () {
    var isHistoryMsg = window.localStorage.getItem('set-site-followup') == '1';
    //  wait first 10 Second ,  send set-site-followup information ,  wait 15 Second ,  send set-site-followup_final information ,  Any action to reply to the message will interrupt the process 
    const params = buildSendParam();
    timer = window.setTimeout(
        () => {
            window.localStorage.setItem('set-site-followup', '1');
            api.sendSiteEvent({
                visitId: getVisitorId(),
                event: 'site_followup',
                ...params
            }).then(res => {
                this.onAIMockMsg(res.data, isHistoryMsg);
                isHistoryMsg =
                    window.localStorage.getItem('set-site-followup_final') ==
                    '1';
                timer = window.setTimeout(
                    () => {
                        window.localStorage.setItem(
                            'set-site-followup_final',
                            '1'
                        );
                        api.sendSiteEvent({
                            visitId: getVisitorId(),
                            event: 'site_followup_final',
                            ...params
                        }).then(res => {
                            this.onAIMockMsg &&
                                this.onAIMockMsg(res.data, isHistoryMsg);
                        });
                    },
                    isHistoryMsg ? 0 : 15000
                );
            });
        },
        isHistoryMsg ? 0 : 10000
    );
};

//  termination followup process 
AIDialogFlow.prototype.abortFollowup = function () {
    window.clearTimeout(timer);
};

function getVisitorId() {
    return +util.getCookie('chatbox_visitor_id') || undefined;
}

var flow;
var readyCb;
var psReady = new Promise(function (res) {
    readyCb = res;
});
export default function ({ agentId, onAIMockMsg }) {
    if (flow) {
        flow.agentId = agentId;
        flow.onAIMockMsg = onAIMockMsg;
    } else {
        flow = new AIDialogFlow({ agentId, onAIMockMsg });
        readyCb(flow);
    }
    return flow;
}
