import { getQueryField } from '../../common/utils/url-params';
import { getLocation, getPlatform, cookieMethods } from '../../clientEnv.js';

/**
 * 要检查的点:
 * 1. location, window.location
 * 2. import axios from 'axios', axios.xxxx
 * 3. getCookie, setCookie, removeCookie
 */
export default function getClientPlatform(globalData) {
    // 客户端数据使用单例
    if (!getClientPlatform.result) {
        let queryObj = getQueryField();
        getClientPlatform.result = {
            pageLocation: getLocation(globalData),
            platform: getPlatform(queryObj),
            ...cookieMethods()
        };
    }
    return getClientPlatform.result;
}
