<template>
    <div class="im-option-group">
        <div class="title" @click.stop>{{ label }}</div>
        <div class="group">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'im-option-group',
    props: {
        label: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss">
.im-option-group {
    .title {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--color-text);
        font-family: var(--font-medium);
    }
    .group {
        padding-left: 10px;
    }
}
</style>
