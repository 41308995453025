import util from './common/util';
import vow from './components/vow';
import onAutoPopup, { getAgreementTitleContent } from './common/autopopup.js';
import { initOneStepLogin } from './common/google-login-one-step';
import { getStore } from './store';

const closeCallback = () => {
    let globalData = window.sitePrepareData();
    let { loginCallback } = globalData.scheduleAnchor;
    loginCallback &&
        loginCallback() &&
        (globalData.scheduleAnchor.loginCallback = null);
};

export default function handlePopup(globalData, moduleData) {
    let userStore = getStore('user');
    if (util.getQueryField('isPopup') == 1) {
        //  Not logged in strong bomb login box
        userStore
            .getUser({
                checkOtherIframe: true,
                fromGlobal: true
            })
            .then(isLogin => {
                if (!isLogin) {
                    let options = {
                        onClose: () => closeCallback(),
                        onsuccess: () => closeCallback(),
                        allowClose:
                            globalData.registration?.autopopup?.allowClose
                    }
                    if (util.getQueryField('optInAudit') == 1) {
                        options.step = 2
                    }
                    window.siteAppVm.openSignLog(options);
                }
            });
    } else if (window.sitePageAutoInit !== false) {
        // window.siteAppVm.isVow = false;
        let options = getAgreementTitleContent(
                globalData.mlsOrgs,
                globalData.pageKey,
                moduleData
            ),
            isHasPopupRule =
                (globalData.registration &&
                    globalData.registration.autopopup) ||
                util.getCookie('source');
        let isAdCreate = globalData.creatorId == -9;
        let isLandingPage = globalData.isLandingPage;
        // eslint-disable-next-line no-inner-declarations
        function popUpFunc() {
            userStore
                .getUser({
                    checkOtherIframe: true,
                    fromGlobal: true
                })
                .finally(() => {
                    onAutoPopup(
                        window.siteAppVm,
                        Object.assign(globalData.registration.autopopup, {
                            closeCallback
                        })
                    );
                    globalData.googleAppId &&
                        globalData.enableGSI !== false &&
                        initOneStepLogin(globalData);
                });
        }
        if (options && !(isAdCreate && isLandingPage)) {
            window.siteAppVm.$EventBus.$emit('vow-popup');
            vow.open(
                options,
                0,
                () => {
                    window.siteAppVm.$EventBus.$emit('vow-close');
                    if (isHasPopupRule) {
                        popUpFunc();
                    }
                },
                undefined,
                globalData
            );
        } else if (isHasPopupRule) {
            popUpFunc();
        }
    }
}
