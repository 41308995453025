import util from '@/common/util';

/**
 *  component has been placed in sign-log Down ， only available isShow Determine whether the advertisement registration pop-up window can be displayed 
 */
export default {
    isShow(globalData) {
        /*responsive form pop-up window only in landing-page ad pages only ，
         Entering this page will set cookie enableDynamicRegister=1，
         If you can still keep logging in after coming out of this page, the pop-up window is responsive 
        form only clear cookie or into a non-advertising landing-page can be cleared after responsive form Pop-ups */
        return (
            globalData.registration?.autopopup?.isDynamicRegister ||
            util.getCookie('enableDynamicRegister') == '1'
        );
    }
};
