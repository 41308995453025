import util from '@/common/util';
let isLoadTrans = false;
let UUID = Date.now();
const loadGTranslate = function (cb) {
    var ele =
        document && document.body.appendChild(document.createElement('div'));
    ele.id = 'google_translate_element2';
    var styleEle =
        document && document.body.appendChild(document.createElement('style'));
    styleEle.innerHTML = `
    /* for google translate */
    body {
        top: 0 !important;
        min-height: auto !important;
    }
    #goog-gt-tt {
        display: none !important;
    }
    .goog-te-banner-frame {
        display: none !important;
    }
    .goog-te-menu-value:hover {
        text-decoration: none !important;
    }
    #google_translate_element2 {
        display: none !important;
    }
    `;
    cb && cb();
    window.googleTranslateElementInit = function () {
        new window.google.translate.TranslateElement(
            {
                // pageLanguage: 'en',
                autoDisplay: false
            },
            'google_translate_element2'
        );
    };
    return util.addScript(
        'https://translate.google.com/translate_a/element.js?&cb=googleTranslateElementInit'
    );
};
const GTranslateFireEvent = function (a, b) {
    try {
        if (document.createEvent) {
            // let c = document.createEvent('HTMLEvents');
            // c.initEvent(b, true, true);
            a.dispatchEvent(new Event(b));
        } else {
            let c = document.createEventObject();
            a.fireEvent('on' + b, c);
        }
    } catch (e) {
        console.error(e);
    }
};
/**
 * google translation plugin ,https://gtranslate.io/
 * @param {String} value  which language to translate into 
 */
const doGTranslate = async function ({ value, retry = 50, cb, uuid }) {
    if (UUID !== uuid) {
        return;
    }
    if (retry < 1) {
        console.error('gTranslate max  error');
        return;
    }
    if (!isLoadTrans) {
        await loadGTranslate(cb);
    }
    isLoadTrans = true;
    var c;
    var d = document.getElementsByTagName('select');
    for (var i = 0; i < d.length; i++)
        if (d[i].className == 'goog-te-combo') c = d[i];
    if (
        document.getElementById('google_translate_element2') == null ||
        document.getElementById('google_translate_element2').innerHTML.length ==
        0 ||
        c.length == 0 ||
        c.innerHTML.length == 0
    ) {
        setTimeout(function () {
            doGTranslate({ value, retry: 50, cb, uuid });
        }, 500);
    } else {
        let opts = [...c.children];
        let hasLang;
        opts.map(item => {
            if (item.value === value) {
                hasLang = true;
            }
        });
        if (hasLang) {
            c.value = value;
            GTranslateFireEvent(c, 'change');
        } else {
            setTimeout(() => {
                doGTranslate({ value, retry: retry - 1, cb, uuid });
            }, 500);
        }
        // GTranslateFireEvent(c, 'change');
    }
};
export const country = {
    'zh-CN': 'CHINESE',
    en: 'ENGLISH',
    fr: 'FRENCH',
    ja: 'JAPANESE',
    es: 'SPANISH',
    ru: 'RUSSIAN'
};

export default arg => {
    UUID = Date.now();
    doGTranslate({
        ...arg,
        uuid: UUID
    });
};
