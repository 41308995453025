import popMgr from '@/components/global/popMgr';
import util from '@/common/util';
import { isSSRServer } from '@/config';
import { getStore } from '@/store';
import registerPhoneValidPop from './index.vue';
export const adNewPhoneValid = {
    hasPhoneAddOn: false,
    hasValidPhone: false,
    showNewRegister() {
        if (isSSRServer) {
            return false;
        }
        return !!(
            util.getCookie('_ads_source') &&
            util.getCookie('old_reg_modal') == 'false'
        );
    },
    // todo 20241015
    // Currently, it is written in several registration entrances. 
    // When all registration entrances can go through the new advertising process, this method will be called in the public registration method.
    addAdNewRegisterParams() {
        let obj = {};
        if (this.showPhoneValidPop()) {
            obj.usePhoneValid = true;
        }
        if (this.showNewRegister()) {
            obj.registerNewPopupBehavior = true;
        }
        return obj;
    },
    // Display the phone number verification page, otherwise display the completion page
    showPhoneValidPage() {
        return !!(this.showPhoneValidPop() && this.hasRegisterphoneNumber());
    },
    showPhoneValidPop() {
        return this.hasPhoneAddOn && !this.hasValidPhone;
    },
    hasRegisterphoneNumber() {
        return !!getStore('user')?.user?.phoneNumber;
    },
    init() {
        if (!this.showNewRegister()) {
            return;
        }
        this.getHasPhoneAddOn();
        if (!!getStore('user')?.user) {
            this.getValidPhone();
        }
    },
    getHasPhoneAddOn() {
        window.siteAxios
            .get('/api-site/register/phone-addon?_t=' + Date.now())
            .then(res => {
                this.hasPhoneAddOn = !!res?.data;
            });
    },
    getValidPhone() {
        return window.siteAxios
            .get('/api-site/questionnaire/leadBehaviorAction?_t=' + Date.now())
            .then(res => {
                const list = res?.data || [];
                const index = list.findIndex(
                    item => item.behavior == 'alreadyValidPhone'
                );
                this.hasValidPhone = index >= 0;
            });
    },
    // Display the phone number page or completion page
    openRegisterPhoneValidPop(options = {}) {
        if (!this.showNewRegister()) {
            return;
        }
        if (!getStore('user')?.user) {
            return;
        }
        popMgr.create(registerPhoneValidPop, {
            currentStep: this.showPhoneValidPage() ? 1 : 2,
            ...options
        });
    },

    // Display the phone number page and completion page
    async showRegisterPhoneAndCompletionPage() {
        if (!this.showNewRegister()) {
            return false;
        }
        if (!getStore('user')?.user) {
            return false;
        }
        try {
            await this.getValidPhone();
        } catch (e) {
            console.log(e);
        }
        if (!this.showPhoneValidPage()) {
            return false;
        }
        return true;
    }
};
