/**
 * 在pinia下找key为storeName的Store, 如果传了getUseStore, 会在找不到的情况下, 通过getUseStore(storeName)创建store, 并返回
 * @param {*} pinia 和app关联的pinia
 * @param {*} storeName store的唯一标识
 * @param {*} getUseStore 创建xxUseStore的一个方法
 */
export const findStore = function(pinia, storeName, getUseStore){
    if (pinia._s.has(storeName)){
        return pinia._s.get(storeName)
    }
    else {
        // 存在storeName不存在，但是子组件中有使用mapState的情况(md-map&map-here)
        let store = {}
        if (storeName && getUseStore){
            let useStore = getUseStore(storeName, pinia);
            store = useStore(pinia);
        }
        // if (!store){
        //     throw new Error("Can't find store!")
        // }
        return store;
    }
}


function findGetUseStore(storeName, pinia){
    let parent = this;
    while(parent){
        if(parent.$options._getUseStore){
            return  parent.$options._getUseStore(storeName, pinia);
        }
        parent = parent.$parent;
    }
    // console.log(`${storeName} not found`);
}

function findParentStoreName() {
    let parent = this;
    while(parent){
        if(parent._storeName) {
            return parent._storeName
        }
        parent = parent.$parent;
    }
}

export const findModuleStore = function() {
    return findStore(this.$pinia, this._storeName || findParentStoreName.call(this) , this.$options._getUseStore || findGetUseStore.bind(this));
}

export const mapState = function(keysOrMapper) {
    return Array.isArray(keysOrMapper)
        ? keysOrMapper.reduce((reduced, key) => {
            reduced[key] = function () {
                const store = findModuleStore.call(this);
                return store[key];
            };
            return reduced;
        }, {})
        : Object.keys(keysOrMapper).reduce((reduced, key) => {
            // @ts-expect-error
            reduced[key] = function () {
                // console.log(key, 'mapState helpers ...');
                const store = findModuleStore.call(this);
                const storeKey = keysOrMapper[key];
                // for some reason TS is unable to infer the type of storeKey to be a
                // function
                return typeof storeKey === 'function'
                    ? storeKey.call(this, store)
                    : store[storeKey];
            };
            return reduced;
        }, {});
}
export const mapGetters = mapState;
export const mapActions = function(keysOrMapper) {
    return Array.isArray(keysOrMapper)
        ? keysOrMapper.reduce((reduced, key) => {
            // @ts-expect-error
            reduced[key] = function (...args) {
                const store = findModuleStore.call(this);
                return store[key](...args);
            };
            return reduced;
        }, {})
        : Object.keys(keysOrMapper).reduce((reduced, key) => {
            // @ts-expect-error
            reduced[key] = function (...args) {
                // console.log(key, 'mapActions helpers ...');
                const store = findModuleStore.call(this);
                return store[keysOrMapper[key]](...args);
            };
            return reduced;
        }, {});
}
export const mapMutations = mapActions;

export const mergeStores = function(...configs){
    let { state, getters, actions } = configs.reduce((reduced, config) => {
        reduced.state = Object.assign(reduced.state, config.state());
        reduced.getters = Object.assign(reduced.getters, config.getters);
        reduced.actions = Object.assign(reduced.actions, config.actions);
        return reduced;
    }, {
        state: {},
        getters: {},
        actions: {}
    });
    return {
        state: () => state,
        getters,
        actions
    }
}