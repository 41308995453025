
function generateModuleName(moduleName, isCsrModule) {
    if (!this._moduleNameMap) {
        this._moduleNameMap = new Map();
    }
    let count = this._moduleNameMap.get(moduleName) || 0;
    count += 1;
    this._moduleNameMap.set(moduleName, count);
    return `${moduleName}${isCsrModule ? '-CSR' : ''}-${count}`;
}
function recordStore(storeName){
    if (!this._moduleNameMap) {
        this._moduleNameMap = new Map();
    }
    const regex = /^(.*)-(\d+)$/;
    const m = storeName.match(regex);
    if (m) {
        this._moduleNameMap.set(m[1], +m[2]);
        return true;
    }
    else{
        return false;
    }
}

// 找到根模块(模块自己的store需要在它的子组件里面使用)
function findRootModule() {
    if (!this.$parent || this.$parent === this.$root) {
        return;
    } else if (this.$parent.mdData?.isBlockRoot) {
        return this.$parent;
    } else {
        return findRootModule.call(this.$parent);
    }
}

export default {
    install: function(app) {
        app.mixin({
            beforeCreate() {
                if (this.mdData?.isBlockRoot) {
                    // this.isBlockRoot = true;
                    this.blockName = this.mdData.name;
                    this._getUseStore = this.$options._getUseStore;
                    if(this._getUseStore){
                        if (this.mdData.data.storeName && recordStore.call(this.$pinia, this.mdData.data.storeName)){
                            this._storeName = this.mdData.data.storeName;
                        }
                        else {
                            this.mdData.data.storeName = this._storeName = generateModuleName.call(this.$pinia, this.blockName, this.mdData.data._csr);
                        }
                    }
                    else{
                        this._storeName = undefined;
                    }
                    this.blockOb = this.mdData.blockOb;
                } else {
                    var root = findRootModule.call(this);
                    if (root) {
                        // this.isBlockRoot = false;
                        this.blockName = root.blockName;
                        this._getUseStore = root._getUseStore;
                        if(Reflect.has(root, '_storeName')) {
                            this._storeName = root._storeName;
                        }
                        this.blockOb = root.blockOb;
                    }
                }
            }
        });
    }
};
