
import {onMounted,onBeforeUnmount} from "vue"
export default  (eventName,callback)=>{
    onMounted(()=>{
        window.addEventListener(eventName,callback);
    });

    onBeforeUnmount(()=>{
        window.removeEventListener(eventName,callback);
    })
}