import {
    h,
    defineAsyncComponent,
    resolveComponent,
    defineComponent,
    withMemo
} from 'vue';
import util from '@/common/util';
import getModule from '../modules/index';

export default function({module, mCache, globalData, platform}, {style = {}} = {}) {
    if (isLayout(module)){
        return h(resolveComponent(module.name), {
            items: module.items,
            layoutCfg: module.layoutCfg,
            mCache, 
            globalData, 
            platform,
            style
        });
    }

    if(module.name === 'module-loading'){
        return h(resolveComponent('md-lazy'),{
            key: `module-loading-${module.id}`,
            style
        });
    }

    if(util.isClient || module.data._csr){
        if(!module.visible && !module.moduleLoad){
            return h(resolveComponent('md-lazy'), {
                onVisible: () => {
                    if (module.visible) {
                        return;
                    }
                    const loadingPs = (module.loadingPs = getModule(
                        module.name,
                        module.theme,
                        globalData,
                        platform.isMobile
                    )
                        .then((res) => {
                            if (
                                loadingPs === module.loadingPs
                            ) {
                                module.component = defineComponent(res);
                                module.loadingPs = null;
                                module.visible = true;
                                module.moduleLoad = true;
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            if (
                                loadingPs === module.loadingPs
                            ) {
                                module.visible = true;
                            }
                    }));
                },
                key: module.id,
                moduleName: module.name,
                noObserve: module.noObserve,
                hasLoadingPs: !!module.loadingPs,
                csr: module.data._csr,
                style
            });
        }else if(module.moduleLoad){
            return withMemo(
                [module.id, module.name, module.theme, module.componentName], 
                () => h(module.component, {
                        mdData: module,
                        id: module.id,
                        key: module.id,
                        style
                    },
                    module.content || ''
                ), 
                mCache, 
                module.id
            );
        }else{
            console.error('load failed', module);
        }
        return;
    }

    return withMemo([module.id, module.name, module.theme], ()=> h(
        defineAsyncComponent(() => {
            return getModule(
                module.name,
                module.theme,
                globalData,
                platform.isMobile
            );
        }),
        {
            mdData: module,
            id: module.id,
            key: module.id,
            style
        },
        module.content || ''
    ), mCache, module.id)
};

export const isLayout = function(module){
    return module.items;
}