import './mls-french.scss';
// fixed https://jira.chimeinc.com/browse/CHIME-26415
let while_id = [526, 726];
export default ({ globalData }) => {
    let mlsOrgs = globalData.mlsOrgs || [];
    for (let i = 0; i < mlsOrgs.length; i++) {
        let mls = mlsOrgs[i];
        if (while_id.includes(mls.id)) {
            document.body.classList.add('use_mls_french');
            return;
        }
    }
};
