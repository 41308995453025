/* <template>
    <img
        v-if="type === 'img'"
        :class="$attrs.class"
        :style="$attrs.style"
        :src="imgSrc"
        :alt="imgAlt"
        @load="imgLoad($event.target)"
        @error="imgError($event)"
    />
    <div
        v-else
        :style="style"
        :class="$attrs.class"
        :role="imgAlt && 'img'"
        :title="imgAlt"
        :aria-label="imgAlt"
    >
        <slot></slot>
    </div>
</template>
<script> */
import { h } from "vue";
import util from '../../common/util';
import { mapState } from 'pinia';
import { usePageStore } from '@/store';

const regImageSizeOriginal =
    /\/\/(cdn|image|img).chime.me\/.+\/(?:(?:w|h)\d+_)??original_/;
const regImageReplaceOriginal = /\/(?:(?:w|h)\d+_)?original_/;
const regImageGif = /\.gif$/;
export default {
    emits: ['update', 'load', 'error'],
    props: {
        type: {
            type: String,
            default: 'img'
        },
        src: {
            validator: function (value) {
                return (
                    typeof value === 'string' ||
                    typeof value === 'object' ||
                    !value
                );
            },
            required: true,
            default: ''
        },
        dir: {
            type: String,
            default: 'width',
            validator(value) {
                if (value) {
                    return ['width', 'height'].indexOf(value) !== -1;
                } else {
                    return true;
                }
            }
        },
        size: {
            type: Number,
            default: 0
        },
        ssrInitSize: {
            type: Number,
            default: 0
        },
        persent: {
            type: Number,
            default: 0
        },
        rate: {
            type: Number,
            default: 2
        },
        alt: {
            type: String,
            default: ''
        },
        lazy: {
            type: Boolean,
            default: undefined
        },
        backRepeat: {
            type: String,
            default: 'no-repeat'
        },
        backPos: {
            type: String,
            default: 'center'
        },
        backSize: {
            type: String,
            default: 'cover'
        },
        errorImg: {
            type: String,
            default: ''
        },
        bgColor: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            imgSrc: null,
            visible: false,
            errorLoad: false
        };
    },
    computed: {
        style({imgSrc, backSize, backPos, bgColor, backRepeat}){
            let res = {
                backgroundSize: backSize,
                backgroundPosition: backPos,
                backgroundRepeat: backRepeat
            }
            if (imgSrc){
                res.backgroundImage = `url(${imgSrc})`;
                res.backgroundColor = '';
            }
            else if (bgColor){
                res.backgroundColor = bgColor;
            }
            return res;
        },
        originSrc({ src }) {
            if (typeof src === 'string') {
                return src;
            } else if (typeof src === 'object') {
                return src?.imgUrl || '';
            } else {
                return '';
            }
        },
        imgAlt() {
            return (this.src && this.src.alt) || this.alt || '';
        },
        nolazy() {
            if (this.platform.isScreenshot) {
                return true;
            }
            if (this.lazy != undefined) {
                return !this.lazy;
            } else {
                return this.noImgLazy;
            }
        },
        ...mapState(usePageStore, {
            useWebp: function(store){
                return store.page?.site?.webp && !this.platform.isSafari;
            }
        })
    },
    watch: {
        originSrc() {
            if (this.visible) {
                this.updateSrc();
            }
        },
        dir() {
            if (this.visible) {
                this.updateSrc();
            }
        },
        size() {
            if (this.visible) {
                this.updateSrc();
            }
        },
        lazy(value) {
            if (!value && !this.visible) {
                this.visible = true;
                this.updateSrc();
            }
        }
    },
    inject: {
        noImgLazy: { default: false }
    },
    mounted() {
        if (this.nolazy) {
            this.visible = true;
            this.updateSrc();
        } else {
            util.getObserver(this.$el, () => {
                if (!this.visible) {
                    this.visible = true;
                    this.updateSrc();
                }
            });
        }
    },
    created() {
        if (!util.isClient) {
            this.updateSrc(true);
        }
    },
    methods: {
        transfromImgSrc(src,create) {
            if (!regImageGif.test(src) && regImageSizeOriginal.test(src)) {
                if (this.dir) {
                    src = src.replace(
                        regImageReplaceOriginal,
                        '/' + this.getImgSize(create) + 'original_'
                    );
                }
                if (!/\.webp$/i.test(src)) {
                    //  Mandatory on mobile webp
                    if (this.platform.isMobile) {
                        src = this.convertToWebp(src);
                    } else if (this.useWebp){
                        src = this.convertToWebp(src);
                    }
                }
            }
            return src;
        },
        convertToWebp(src){
            if(src.includes('?')){
                return src.replace(/\.([^.]+)\?/, '-$1.webp?');
            }else{
                return src.replace(/\.([^.]+)$/, '-$1.webp')
            }
        },
       async updateSrc(create) {
            if(!create && util.isSSRClient){
                // 防止注水失败
                await this.$nextTick();
            }

            let src = this.originSrc;
            src = this.transfromImgSrc(src,create);
            if (src !== this.imgSrc) {
                if (this.type === 'img' || create) {
                    this.imgSrc = src;
                } else {
                    if (!create) {
                        this.bgImgLoad(src);
                    }
                }
                this.$emit('update');
            }
        },
        getRecommendWidth(){
            if (this.dir === 'width' && this.persent){
                if (this.platform.isMobile){
                    return 300 * this.persent;
                }
                else{
                    return 1200 * this.persent;
                }
            }
            else{
                return 300;
            }
        },
        getImgSize(create) {
            const prefix = this.dir === 'width' ? 'w' : 'h';
            let size = this.size;
            if (!size) {
                if (create) {
                    if (util.isSSRServer && this.ssrInitSize){
                        size = this.ssrInitSize;
                    }
                    else{
                        size = this.getRecommendWidth();
                    }
                }
                else{
                    let ele = this.$el;
                    //  try to fetch img-box inside img-content,  The size of this element is more accurate ( sometimes img Will be inexplicably inaccurate in size )
                    if (
                        this.$el &&
                        this.$el.parentElement &&
                        this.$el.parentElement.classList &&
                        this.$el.parentElement.classList.contains('img-content')
                    ) {
                        ele = ele.parentElement;
                    }
                    while (ele && !(size = this.calcEleSize(ele))) {
                        ele = ele.parentElement;
                        if (!ele) return '';
                    }
                }
            }

            const stepSize = this.stepSize(
                size * this.rate
                // Math.ceil(rate * Math.max(10, size))
            );
            return stepSize && `${prefix}${stepSize}_`;
        },
        stepSize(size) {
            //6 stalls  [200, 600, 900, 1200, 2000, 2800,  original image ]
            if (size <= 1200){
                return (parseInt(size / 20) + (size % 20 === 0 ? 0 : 1)) * 20 || ''
            }
            // if (size <= 200) {
            //     return 200;
            // }
            // if (size <= 600) {
            //     return 600;
            // }
            // if (size <= 900) {
            //     return 900;
            // }
            // if (size <= 1200) {
            //     return 1200;
            // }
            // if (size <= 2000) {
            //     return 2000;
            // }
            // if (size <= 2800) {
            //     return 2800;
            // }
            return '';
        },
        calcEleSize(ele) {
            const offsetKey =
                this.dir === 'width' ? 'offsetWidth' : 'offsetHeight';
            const offsetSize = ele[offsetKey] < 5 ? 0 : ele[offsetKey];
            const maxKey = this.dir === 'width' ? 'maxWidth' : 'maxHeight';
            const maxSize = parseInt(util.getStyle(ele)[maxKey], 10) || 0;
            let size = offsetSize || maxSize;
            if (ele.nodeName === 'IMG') {
                size = Math.max(offsetSize, maxSize);
            }
            return size;
        },
        // formatImageSize(wh) {
        //     let rate = wh < 600 ? 50 : wh < 1000 ? 10 : 20;
        //     return rate * Math.ceil(wh / rate);
        // },
        bgImgLoad(src) {
            const img = new Image();
            img.onload = () => {
                this.imgSrc = src;
                this.imgLoad(img);
            };
            img.onerror = e => {
                this.imgSrc = src;
                this.imgError(e);
            };
            img.src = src;
            if (img.complete) {
                this.imgSrc = src;
                this.imgLoad(img);
            }
        },
        imgLoad(event) {
            let target = event?.target || {};
            this.errorLoad = false;
            this.$emit('load', {
                src: this.imgSrc,
                height: target.naturalHeight,
                width: target.naturalWidth
            });
        },
        imgError(e) {
            if (this.errorImg) {
                this.imgSrc = this.transfromImgSrc(this.errorImg);
            }
            if(this.imgSrc===null && this.src){
                console.log('imgSrc is null...')
                return ;
            }
            this.$emit('update');
            this.$emit('error', e);
            this.errorLoad = true;
        }
    },
    render(){
        let imgEl={
            tag : 'img',
            config : {
                class : this.$attrs?.class,
                style : this.$attrs?.style,
                src : this.imgSrc,
                alt : this.imgAlt,
                onLoad : this.imgLoad,
                onError : this.imgError
            }
        }

        let divEl={
            tag : 'div',
            config : {
                class : this.$attrs?.class,
                style : this.style,
                role : this.imgAlt ? 'img' : '',
                title : this.imgAlt,
                'aria-label' : this.imgAlt
            }
        }

        if(this.type==='img'){
            return h(imgEl.tag,{...imgEl.config});
        }else{
            return h(
                divEl.tag,
                {...divEl.config},
                this.$slots?.default ? this.$slots.default() : null
            );
        }
    }
};
// </script>
/* <style lang="scss"></style> */
