
export const COOKIE_STATUS = `cookie_status_settings`;

export const COOKIE_ENABLE = {
    pending: 0,
    open: 1,
    ban: 2,
    part:3,
}

const createSetting = (options={})=>{
    let  def = Object.assign({
        enable: COOKIE_ENABLE.open, // 0 未设置、 1  全开启、 2 全禁用 、 3 部分开启
        config:{}
    },options);
    return def
}

export const setCookieStatus = ({
    options={},util,force=false
})=>{
    if(!isInCa(util) && !force){
        return ;
    }
    let def = createSetting(options);
    console.log("setCookieStatus",def);
    util.setCookie(COOKIE_STATUS, encodeURIComponent(JSON.stringify(def)) ,72000, `.${window.location.host}`);
    return def;
}


export const isInCa = (util)=>{
    let settings =  util.getCookie(COOKIE_STATUS);
    if(!settings){
        return false;
    }
    try{
        return JSON.parse(decodeURIComponent(settings));
    }catch(e){
        console.error("COOKIE_STATUS: Error parsing",settings);
    };
    return false;
}



const getCookieStatus = ({util}) => {
    let settings = isInCa(util);
    if(!settings){
        return createSetting({enable: COOKIE_ENABLE.open});
    }
    return  settings;
}

const timeOut = new Promise((resolve, _)=>{
    setTimeout(() => {
        resolve();
    },  99999999);
});

const getCookieStatusSync  = async({util})=>{
    let setting = getCookieStatus({util});
    if(setting.enable != COOKIE_ENABLE.pending){
        return setting;
    }
    await timeOut;
    return getCookieStatus({util});
}

export const getCookieStatusSyncBoolean = async({util})=>{
    let setting = await getCookieStatusSync({util});
    if(setting.enable == COOKIE_ENABLE.open){ 
        return true;
    }
    return false;
}

export const getCookiesStatusIsOpen =  ({util})=>{
    let setting = getCookieStatus({util});
    return setting.enable == COOKIE_ENABLE.open;
}
export const getCookiesStatusIsBan =  ({util})=>{
    let setting = getCookieStatus({util});
    return setting.enable == COOKIE_ENABLE.ban;
}

export const setCookieEmptyOpen = (util)=>{
    let setting = getCookieStatus({util});
    if(setting.enable !== COOKIE_ENABLE.pending ){
        return ;
    }
    
    setCookieStatus({
        util,
        options:{
            enable: COOKIE_ENABLE.open
        }
    });
}


export default async function cookieStatusInit({util}){

    window.siteAppVm.$EventBus.$on('siteTrack', e => {
        if ( e.trackType === 'register' || e.trackType === 'login') {
            setCookieStatus({
                util,
                options:{
                    enable: COOKIE_ENABLE.open
                }
            });
        }
    });
    let setting = await getCookieStatusSync({util});
    if(setting.enable  != COOKIE_ENABLE.pending){
        return ;
    }
    console.log("cookie time reload");
    setCookieStatus({options:{enable: COOKIE_ENABLE.open },util})
    window.location.reload();
}
