import { isSSRServer } from '@/config';
import { getQueryField } from './url-params';

let inCmsPageEditor = false;
try {
    inCmsPageEditor = !isSSRServer && (window.sitePageAutoInit === false || Boolean(window.top.cmsAppVm));
} catch (e) {
    inCmsPageEditor = false;
}

// //  and  cms  Homologous loading 
// let inCmsOrigin = false;
// try {
//     inCmsOrigin = !isSSRServer && Boolean(window.top.cmsAppVm);
// } catch (e) {
//     inCmsOrigin = false;
// }

let inCmsSetting = false;
try {
    inCmsSetting = window?.top?.location?.pathname === '/cmsnew/setting';
} catch (e) {}

let inCmsUrl = '';
try {
    inCmsUrl = window.top.cmsAppVm && window.top.location.href;
} catch (e) {}

// cms  Click on eye preview mode 
let inCmsPreview = false;
try {
    inCmsPreview = window.parent !== window && !!getQueryField('site-cms-id');
} catch (e) {}

export default {
    inCms: inCmsPageEditor,
    inCmsPageEditor,
    inCmsSetting: inCmsSetting,
    inCmsUrl: inCmsUrl,
    inCmsPreview,
}