<template>
   <slot v-if="show"></slot>
</template>

<script>
import util from '@/common/util';
export default {
    name: 'only-client',
    data() {
        return {
            show: util.isClient // when is ssr，is false
        };
    },
    mounted() {
        this.show = true;
    }
};
</script>