import api from './api';
import util from '@/common/util';
import plugin from '@/plugins';

import aiDialogFlow, { aiInit } from './chime/aiDialogFlow';
import advRegister from '@/components/sign-log/advertising-register/register.js';
import { ref, computed } from 'vue';
import global from '@/common/global';
import { getStore } from '@/store';


const hideChat = ref(false);


export const showChat = computed(() => {
    const chatStore = getStore('chat');
    const pageStore = getStore('page');
    return (
        util.inCmsSetting ||
        !(
            chatStore.isHideChat ||
            (!pageStore.page.isLandingPage && hideChat.value)
        )
    );
});

const onscroll = () => {
    if(util.isMobile){
        // mobile default display 
        return false;
    }
    // Do not show chat up to 200 feet from the bottom
    var notFromBottom200 =
        document.documentElement.clientHeight + window.scrollY + 200 <
        document.body.clientHeight;
    if (notFromBottom200) {
        hideChat.value = false;
    } else {
        hideChat.value = true;
    }
};
if (!util.isSSRServer && !util.isMobile) {
    global.scroll(onscroll);
}

var facebook_cms_inst = {
    $el: null,
    $mount() {
        if (this.$el) {
            return;
        }
        let img = new Image();
        img.src =
            '//cdn.chime.me/image/fs/sitebuild/site-cms/chat-facebook-icon.png';
        img.style.width = '60px';
        img.style.height = '60px';
        img.style.position = 'fixed';
        img.style.right = '12px';
        img.style.bottom = '24px';
        img.style.top = 'auto';
        img.style.zIndex = 2147483644;
        img.style.borderRadius = '50%';
        img.style.overflow = 'hidden';
        this.$el = img;
    },
    $destroy() {}
};
const facebook_cms = {
    createIns() {
        return Promise.resolve(facebook_cms_inst);
    }
};

const getChatYunTwilio = ()=>import('./chime');

const chatFactory = {
    chime: getChatYunTwilio,
    facebook: ()=>import('./facebook'),
    chime_cms: getChatYunTwilio,
    facebook_cms
};
var chatPs;
const chat = {
    checkUserAction: function () {
        if (!chatPs) {
            if (window.sitePageAutoInit === false) {
                chatPs = Promise.resolve();
            } else {
                chatPs = util.getUserActionPs();
            }
        }
        return chatPs;
    },

    checkInitChat: function (globalData) {
        return !(
            globalData.isBot ||
            !util.isReallyUser ||
            (advRegister.isShow(globalData) &&
                globalData.pageKey === 'listing' &&
                globalData.isLandingPage) ||
            globalData.pageKey === 'password-reset' ||
            globalData.pageKey === 'password-forget' ||
            util.isScreenshot
        );
    },
    async aiConnect(options = {}) {
        let pageStore = getStore('page'),
            userStore = getStore('user');
        let globalData = pageStore.page;

        if (!this.checkInitChat(globalData)) {
            return;
        }

        let { chatAndCaptureForm, logOutFlag = false } = options;
        //  Reinitialize on logout chat
        if (logOutFlag) {
            util.removeCookie('chatbox_visitor_session_id');
            util.removeCookie('chatbox_visitor_id');
        }
        let config = chatAndCaptureForm || globalData.chatAndCaptureForm || {};
        let showLauncher = config.showLauncher;
        let { CHAT_AGENT_ID, agentCreator, siteLevel, ownerId, siteId } =
            globalData;
        if (!showLauncher) {
            chat.destroyChat();
            return;
        }

        let params = {
            leadUserId: (userStore.user && userStore.user.id) || '',
            sessionId: util.getCookie('chatbox_visitor_session_id') || '',
            agentId: CHAT_AGENT_ID || agentCreator?.agentId || ''
        };
        // company site
        if (siteLevel === 1) {
            params.userId = ownerId;
            params.siteId = siteId;
        }
        // office site
        else if (siteLevel === 2) {
            params.officeId = agentCreator?.agentUser?.officeId || '';
            params.siteId = siteId;
        } else {
            params.userId = CHAT_AGENT_ID;
        }
        aiDialogFlow({ agentId: CHAT_AGENT_ID });
        aiInit();

        //  Get it through the interface first AI Related Information
        let res = await api.getVisitorToken(params);
        config = {
            chatAgentId: CHAT_AGENT_ID,
            chatType: 'chime',
            ...config,
            visitorEnable: res.visitorEnable,
            visitLead: res.visitLead,
            aiHeadurl: res.headurl,
            aiHeadname: res.aiHeadname
        };

        //  maintain sessionId,  this sessionId changed retrigger aiflow
        if (config.visitLead && config.visitLead.id) {
            var oldVistorId = util.getCookie('chatbox_visitor_session_id');
            //  if visitorId changed ,  empty aiFlow relevant local data
            if (oldVistorId !== config.visitLead.sessionId) {
                window.localStorage.removeItem('set-site-welcome');
                window.localStorage.removeItem('set-listing-page');
                window.localStorage.removeItem('set-site-followup');
                window.localStorage.removeItem('set-site-followup_final');
            }
            util.setCookie(
                'chatbox_visitor_session_id',
                config.visitLead.sessionId,
                30
            );
            util.setCookie('chatbox_visitor_id', config.visitLead.id, 30);
        }

        return config;
    },
    //  create chat example ( When called repeatedly, the previous instance will be destroyed ,  always only one chat example : Right now chat.curChatIns)
    init: async function (options={}) {
        let config =  await chat.aiConnect(options);
        if(!config){
            return ;
        }
        let pageStore = getStore('page');
        
        let showLauncher = config.showLauncher;
        let { CHAT_AGENT_ID , siteType } = pageStore.page;
        // util.inCmsPageEditor &&  is a template site :  mandatory  leaveMode
        if (util.inCmsPageEditor && siteType === 99) {
            config.messenger = false;
            config.visitorEnable = false;
            config.leaveMode = true;
        }
        //  if enabled AI,  Mandatory use chime chat
        if (config.visitorEnable) {
            if (!showLauncher) {
                chat.destroyChat();
                return;
            }
            config.chatType = 'chime';
        }
        //  If configured  facebook  use  facebook
        else if (config.messenger) {
            config.chatType = 'facebook';
        }

      

        //  Save the latest configuration
        chat.config = config;
        //  according to chatType,  get the corresponding chat the code

        var chatFact =
            chatFactory[config.chatType + (util.inCmsPageEditor ? '_cms' : '')];
        if (!chatFact) {
            return;
        }
        if (util.isFunction(chatFact)) {
            chatFact = await chatFact();
        }

        if (!chatFact) {
            return;
        }
        if(chatFact.default ){
            chatFact = chatFact.default
        }
        let instance = await chatFact.createIns(config);
        if (!instance || !instance.use) {
            return;
        }

        chat.destroyChat();
        chat.curChatIns = instance;
        chat.onCreated();
        plugin.baseInstall(instance, true);
        instance.mountSelf();
     
    },
    displayShow(){
        hideChat.value = onscroll();
    },
    //  destroy chat example
    destroyChat: function () {
        if (chat.curChatIns) {
            chat.curChatIns.$el && chat.curChatIns.$el.remove();
            chat.curChatIns.unmount();
        }
    },
    onCreated: function () {
        if (chat.onCreatedCallback) {
            chat.onCreatedCallback();
            chat.onCreatedCallback = undefined;
        }
    }
};

if (!util.isSSRServer) {
    window.chimeChat = chat;
}

export default chat;
